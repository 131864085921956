import React, {Fragment, memo, useEffect, useState} from "react";
import $ from "jquery";
import {Button, CircularProgress, Tooltip} from "@material-ui/core";
import { CustomDialog } from "~components";
import EditForm from "./EditForm";
import {BorderColorRounded} from "@material-ui/icons";
import {isAdmin, myBranchId} from "~/app/helpers/user";
import {useTransferStock} from "~store"

const my_branch_id = myBranchId()
const EditDialog = ({ id = "" }) => {

  const [state, actions] = useTransferStock()
  const [isReady, setIsReady] = useState(false)
  const [status, setStatus] = useState("preparing")
  const [saveDisabled, setSaveDisabled] = useState(true)

  useEffect(() => {
    if(isReady) {
      let disabled = false
      if(status === "completed") {
        disabled = true
      } else {
        if(!isAdmin()) {
          disabled = (state.data.from_branch_id === my_branch_id && !['preparing', 'on-the-way'].includes(status))
            || (state.data.to_branch_id === my_branch_id && status === "preparing")

          if(state.data.to_branch_id === my_branch_id) {
            disabled = status === "preparing"
            if(status === "received") {
              let notAssigned = state.data.items.filter(item => !item.to_variant_id)
              disabled = notAssigned.length > 0
            }
          }
        }
      }

      setSaveDisabled(disabled)
    }
  }, [
    status,
    state.data,
    isReady
  ])

  const handleSave = async () => {
    if(status === "completed") {
      return
    }

    let res = res = await actions.save(status);

    if(res) {
      setTimeout(function() {
        setStatus(res.status)
        actions.setState({ success: false })
      }, 7000)
    }

    $("#edit-transfer-stocks-dialog").animate({ scrollTop: 0 }, "smooth");
  }

  return(
    <CustomDialog
      maxWidth={"sm"}
      dialogContentId={"edit-transfer-stocks-dialog"}
      title={state.data.id || id ? "Edit Details" : "Create New"}
      triggerButton={!id
        ? <Button variant={"contained"} color={"primary"} className={"ml-2"}>
            Create
          </Button>
        : (<Tooltip title={"View Details"}>
          <Button size={"small"} color={"primary"} className={"min-w-auto p-1"}>
            <BorderColorRounded />
          </Button>
        </Tooltip>)
      }
      actionButtons={
        <>
          {!saveDisabled && (
            <Button variant={"contained"} size={"small"} color={"primary"}
              onClick={handleSave}
              disabled={state.isSaving}
            >
              {state.isSaving ? (
                <Fragment>
                  <CircularProgress size={16} className={"mr-2"} />
                  Saving ...
                </Fragment>
              ) : <span>Save</span>}
            </Button>
          )}
        </>
      }
      PaperProps={{
        className: "h-100",
      }}
      handleClose={() => actions.resetData()}
    >
      <EditForm
        id={id}
        status={status}
        setStatus={setStatus}
        isReady={isReady}
        setIsReady={setIsReady}
      />
    </CustomDialog>
  )
}

export default memo(EditDialog)
