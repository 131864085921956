import React from "react";
import {ExportToExcel} from "~components";
import ReorderColumns from "./ReorderColumns";
import FilterColumns from "./FilterColumns";
import {useReportExpenses} from "~store"

const BySupplierFilters = () => {
  const [state,] = useReportExpenses()
  return(
    <div className={"w-100 d-flex justify-content-between"}>
      <div className={"d-flex flex-row"}>
        <div style={{marginRight: '1px'}}>
          <FilterColumns />
        </div>
        <div style={{marginRight: '1px'}}>
          <ReorderColumns />
        </div>
        <div>
          <ExportToExcel
            data={state.by_supplier_export_data}
            fileName={`Expenses By Supplier [${state.by_supplier_date_start}-${state.by_supplier_date_end}]`}
          />
        </div>
      </div>
    </div>
  )
}
export default BySupplierFilters
