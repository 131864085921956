import React, { memo } from "react";
import {NavLink} from "react-router-dom";
import {BorderColorRounded, VisibilityRounded} from '@material-ui/icons';
import {Button, Tooltip} from "@material-ui/core";
import {green} from "@material-ui/core/colors";
import { CustomDialog } from "~components";
import moment from "moment";
import {useAuth} from "~/store";

const DetailDialog = ({ row }) => {
  const [, authActions] = useAuth()
  return(
    <CustomDialog
      title={"Supplier Details"}
      maxWidth={"sm"}
      triggerButton={
        <Tooltip arrow title={"View Details"}>
          <Button size={"small"} color={"secondary"} className={"min-w-auto p-1"}>
            <VisibilityRounded style={{color: green[600]}} />
          </Button>
        </Tooltip>
      }
      actionButtons={!authActions.hasPermission(['edit_suppliers']) ? <></> :
        <NavLink to={`/suppliers/edit/${row.id}`}>
          <Button variant={"contained"} size={"small"} color={"primary"}>
            <BorderColorRounded fontSize={"small"} className={"mr-2"} />
            Update
          </Button>
        </NavLink>
      }
    >
      <Content row={row} />
    </CustomDialog>
  )
}

const Content = ({ row }) => {
  const data = {
    created_at: "Date Registered",
    name: "Name",
    vat_type: "VAT Type",
    tin: "TIN",
    email: "Email",
    contact_no: "Contact No.",
    contact_person: "Contact Person",
    address: "Address",
    notes: "Notes",
  }
  return(
     <div className={"w-100 px-2"}>
       {Object.keys(data).map(field => {
         return (
           <div className={"w-100 d-flex align-items-center py-1"} key={field}>
             <div className={"w-50 text-dark-50 pr-5 max-w-200px"}>
               {data[field]}:
             </div>
             <div className={"flex-grow-1 font-size-lg"}>
               {field === "created_at"
                  ? <>{row.created_at ? moment(row.created_at).format("YYYY-MM-DD") : ""}</>
                  : <>{row[field]}</>
               }
             </div>
           </div>
         )
       })}
     </div>
  )
}

export default memo(DetailDialog)
