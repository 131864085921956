import React, {useEffect, useState} from "react";
import {CustomDialog} from "~/app/components";
import {Button, Tooltip, FormControl, FormGroup, FormControlLabel, Checkbox} from "@material-ui/core";
import {FilterListRounded} from "@material-ui/icons";
import {useReportExpenses} from "~store";

const FilterColumns = () => {

  const [reportState, reportActions] = useReportExpenses()
  const [state, setState] = useState(JSON.parse(JSON.stringify(reportState.by_supplier_columns)))
  const [triggerClose, setTriggerClose] = useState(false)

  useEffect(() => {
    setState(reportState.by_supplier_columns)
  }, [reportState.by_supplier_columns])

  const handleApply = () => {
    reportActions.setState({ by_supplier_columns : state })
    setTriggerClose(true)
  }

  return(
    <CustomDialog
      title={"Filter Columns"}
      maxWidth={"xs"}
      triggerCloseButton={triggerClose}
      triggerButton={
        <Tooltip title={"Filter Columns"}>
          <Button variant={"outlined"} color={"primary"} size={"small"} className={"min-w-20px"}>
            <FilterListRounded />
          </Button>
        </Tooltip>
      }
      actionButtons={
        <Button variant={"contained"} color={"primary"} size={"small"} onClick={handleApply}>
          Apply Changes
        </Button>
      }
    >
      <Content
        state={state}
        setState={setState}
        setTriggerClose={setTriggerClose}
      />
    </CustomDialog>
  )
}

const Content = ({ state, setState, setTriggerClose }) => {

  const [reportState, ] = useReportExpenses()
  const [all, setAll] = useState(reportState.by_supplier_all_columns.length === state.length)

  const toggleAll = () => {
    setState(all ? [] : JSON.parse(JSON.stringify(reportState.by_supplier_all_columns)))
    setAll(!all)
  }

  const handleChange = (event, col) => {
    let newState = JSON.parse(JSON.stringify(state))

    if(!event.target.checked) {
      newState = newState.filter(item => item.id !== col.id)
    } else {
      newState.push(col)
    }

    newState.sort((a, b) => a.order > b.order ? 1 : -1)
    setState(newState)
    setAll(reportState.by_supplier_all_columns.length === newState.length)
    setTriggerClose(false)
  }

  return(
    <FormControl component="fieldset" className={"w-100"}>
      <FormControlLabel className={"border-bottom pb-1 mb-3"}
        control={<Checkbox checked={all} onChange={toggleAll} />}
        label={all ? "Unselect All" : "Select All"}
      />
      <FormGroup>
        {reportState.by_supplier_all_columns.map((item, i) => (
          <FormControlLabel key={i} label={item.title} className={"mb-0"}
            control={<Checkbox
              checked={state.filter(col => col.id === item.id).length > 0}
              onChange={(event) => handleChange(event, item)}
            />}
          />
        ))}
      </FormGroup>
    </FormControl>
  )
}
export default FilterColumns
