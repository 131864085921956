import React from 'react';
import globalHook from 'use-global-hook';

const initialState = {
  loading: false,
  errors: null,
  listCount: 0,
  lastParams: "",
  all_customers: [],
  customers: [],
  customer: {
    id: "",
    name: "",
    email: "",
    phone_number: "",
    address: "",
    balance: 0,
    branch_id: '',
  },
  sale_payments: {},
  transactions: {},
  pdf_data: {},
};

const state = JSON.parse(JSON.stringify(initialState));

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  resetCustomer: (store) => {
    store.setState({
      loading: false,
      errors: null,
      customer: JSON.parse(JSON.stringify(initialState.customer))
    });
  },
  fetchAll: async (store, branch_id = "") => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/fetch-customers?branch_id=${branch_id}`
      });
      store.actions.setState({ all_customers: data.data });

      return data.data
    }
    catch (error) {
      console.log(error);
    }
  },
  fetchList: async (store, queryParams = null, filters = null) => {
    store.setState({ loading: true });

    try {
      let params = "";

      if(!queryParams) {
        params = store.state.lastParams;
      } else {
        params = `?page=${queryParams.pageNumber}
          &pageSize=${queryParams.pageSize}
          &sortField=${queryParams.sortField}
          &sortOrder=${queryParams.sortOrder}
          &searchText=${filters.searchText}
          &branch_id=${filters.branch_id !== "all" ? filters.branch_id : ""}`;
      }
      
      let { data } = await global.axios({
        method: "GET",
        url: `/api/customers${params}`
      });

      store.setState({
        loading: false,
        customers: data.data,
        listCount: data.meta.total,
      });
    }
    catch (error) {
      store.setState({ loading: false });
      console.log(error);
    }    
  },
  show: async (store, id) => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/customers/${id}`
      });
      store.actions.setState({ customer: data.data });
    }
    catch (error) {
      console.log(error);
    }
  },
  save: async (store, history) => {
    store.setState({
      loading: true,
      errors: null
    });

    try {
      let payload = store.state.customer;
      let id = payload.id
      let url = `/api/customers`;

      if(payload.id) {
        url += `/${payload.id}`;
        payload['_method'] = 'PUT';
      }

      delete payload.branch_name

      let { data } = await global.axios.post(url, payload);
      store.actions.setState({
        loading: false,
        // customer: data.data
      });

      if(!id) {
        history.push(`/customers/edit/${data.data.id}`);
      }

      return data.data;
    }
    catch ({ response }) {
      if(response.status === 422) {
        store.setState({
          loading: false,
          errors: response.data.errors
        });
        return false;
      }
    }
  },
  delete: async (store, id) => {
    try {
      await global.axios({
        method: "DELETE",
        url: `/api/customers/${id}`
      });

      store.actions.fetchList();

    }
    catch (error) {
      console.log(error);
    }
  },
  fetchSalePayments: async (store, customer_id, sale_id = "") => {
    if(!customer_id) {
      store.actions.setState({ sale_payments: {} })
      return
    }
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/customer-payments/${customer_id}?sale_id=${sale_id}`
      });

      store.actions.setState({ sale_payments: data });

      return data
    }
    catch (error) {
      console.log(error);
    }
  },
  fetchTransactions: async (store, params, key = "transactions") => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/customer-transactions${params}`
      });

      store.actions.setState({ [key]: data });

      return data
    }
    catch (error) {
      console.log(error);
    }
  },
  fetchAssignCustomers: async (store, user_id) => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/assigned-customers?user_id=${user_id}`
      });
      return data
    }
    catch (error) {
      console.log(error);
      return null
    }
  },
  assignToUser: async (store, user_id, customer_ids) => {
    try {
      let { data } = await global.axios.post(
        `/api/assigned-customers/${user_id}`,
        {
          customer_ids: customer_ids,
          _method: "PUT"
        }
      );
      return data;
    }
    catch ({ response }) {
      if(response.status === 422) {
        store.setState({
          loading: false,
          errors: response.data.errors
        });
        return false;
      }
    }
  }
};

const useCustomer = globalHook(React, state, actions);

export default useCustomer;
