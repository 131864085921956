import React from 'react';
import { headerSortingClasses, sortCaret } from "~helpers";
import {
  ActionsColumnFormatter
} from "~pages/Branches/components/column-formatters";
import globalHook from 'use-global-hook';

const columns = [
  {
    dataField: "id",
    text: "ID",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },  
  {
    dataField: "name",
    text: "Name",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "action",
    text: "Actions",
    formatter: ActionsColumnFormatter,
    classes: "text-right pr-0",
    headerClasses: "text-right pr-3 w-200px",
  }
];

const initialState = {
  columns: columns,
  defaultSorted: [{ dataField: "id", order: "asc" }],
  sizePerPageList: [
    { text: "10", value: 10 },
    { text: "20", value: 20 },
    { text: "50", value: 50 },
    { text: "100", value: 100 }
  ],
  queryParams: {
    sortOrder: "asc",
    sortField: "id",
    pageNumber: 1,
    pageSize: 10,
  },
  filter: {
    searchText: "",
  },
};

const state = initialState;

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  }
};

const useBranchList = globalHook(React, state, actions);

export default useBranchList;
