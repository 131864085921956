import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import {TextField} from "@material-ui/core";

const CustomSelect = ({
  label = "",
  fieldLabel = "",
  value = "",
  options = [], // [{label: label, value: value}, ...]
  optionLabelKey = "label",
  optionValueKey = "value",
  handleChange = false,
  preOptions = "",
  required = false,
  ...props
}) => {
  const [selected, setSelected] = useState(value);

  useEffect(() => {
    setSelected(value);
  }, [value])

  if(required) {
    fieldLabel = <span>{fieldLabel}<span className={`text-danger`}>*</span></span>
  }

  return(
    <>
      {label && (
        <label className="form-text text-muted">
          {label}
        </label>
      )}
      <TextField
        select
        fullWidth
        label={fieldLabel}
        variant="outlined"
        margin={"dense"}
        SelectProps={{ native: true }}
        value={selected}
        onChange={(event) => {
          setSelected(event.target.value);
          if(typeof handleChange === "function") {
            handleChange(event);
          }
        }}
        {...props}
      >
        {preOptions && preOptions}
        {options.map((option) => (
          <option key={option[optionValueKey]} value={option[optionValueKey]}>
            {option[optionLabelKey]}
          </option>
        ))}
      </TextField>
    </>
  )
}

CustomSelect.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  optionLabelKey: PropTypes.string,
  optionValueKey: PropTypes.string,
  handleChange: PropTypes.func
};

export default CustomSelect;
