import React from 'react';
import globalHook from 'use-global-hook';
import moment from "moment/moment";

const current_date = new Date();
let start_date = new Date(current_date.getFullYear(), current_date.getMonth(), 1)
let end_date = new Date()
start_date = moment(start_date).format("YYYY-MM-DD")
end_date = moment(end_date).format("YYYY-MM-DD")

const initDaily = {
  data: [],
  amount: 0,
  date: moment(new Date()).format("YYYY-MM-DD"),
  user: null,
  items: [],
  date_filter_by: {label: "Date", value: "date"},
  date_start: start_date,
  date_end: end_date,
}
const initMonthly = {
  data: [],
  amount: 0,
  month: current_date.getMonth() + 1,
  year: current_date.getFullYear()
}
const initYearly = {
  data: [],
  amount: 0,
  year: new Date().getFullYear()
}

const state = {
  isLoading: false,

  dateFilterByOptions: [
    {label: "Date", value: "date"},
    {label: "Date Range", value: "date_range"},
  ],

  expenses_daily: initDaily,
  expenses_monthly: initMonthly,
  expenses_yearly: initYearly,

  purchases_daily: initDaily,
  purchases_monthly: initMonthly,
  purchases_yearly: initYearly,

  sales_daily: initDaily,
  sales_monthly: initMonthly,
  sales_yearly: initYearly,

  tracking: {
    list_data: [],
    company: null,
    products: [],
    date_start: start_date,
    date_end: end_date,
  }
}

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  fetchExpenses: async (store, type) => {
    store.setState({isLoading: true})
    try {
      let url = `/api/fetch-expense-report?type=${type}
        &user_id=${store.state.expenses_daily.user ? store.state.expenses_daily.user.id : ""}
        &date_filter_by=${store.state.expenses_daily.date_filter_by.value}
        &date=${store.state.expenses_daily.date}
        &date_start=${store.state.expenses_daily.date_start}
        &date_end=${store.state.expenses_daily.date_end}
        &month=${store.state.expenses_monthly.month}
        &year=${store.state[`expenses_${type}`].year}`

      if(type === "daily" && store.state.expenses_daily.items.length) {
        let items = []
        for(let item of store.state.expenses_daily.items) {
          items.push(item.id)
        }
        url += `&items=${items}`
      }

      let { data } = await global.axios({
        method: "GET",
        url: url
      });

      store.actions.setState({
        [`expenses_${type}`]: {...store.state[`expenses_${type}`], ...data},
        isLoading: false
      })

      return data
    }
    catch (error) {
      console.log(error)
      store.setState({isLoading: false})
      return false
    }
  },
  fetchPurchases: async (store, type) => {
    store.setState({isLoading: true})
    try {
      let url = `/api/fetch-purchase-report?type=${type}
        &date=${store.state.purchases_daily.date}
        &month=${store.state.purchases_monthly.month}
        &year=${store.state[`purchases_${type}`].year}`

      let { data } = await global.axios({
        method: "GET",
        url: url
      });

      store.actions.setState({
        [`purchases_${type}`]: {...store.state[`purchases_${type}`], ...data},
        isLoading: false
      })

      return data
    }
    catch (error) {
      console.log(error)
      store.setState({isLoading: false})
      return false
    }
  },
  fetchSales: async (store, type) => {
    store.setState({isLoading: true})
    try {
      let url = `/api/fetch-sale-report?type=${type}
        &date=${store.state.sales_daily.date}
        &month=${store.state.sales_monthly.month}
        &year=${store.state[`sales_${type}`].year}`

      let { data } = await global.axios({
        method: "GET",
        url: url
      });

      store.actions.setState({
        [`sales_${type}`]: {...store.state[`sales_${type}`], ...data},
        isLoading: false
      })

      return data
    }
    catch (error) {
      console.log(error)
      store.setState({isLoading: false})
      return false
    }
  },
  fetchTracking: async (store) => {
    if(!store.state.tracking.company) return

    store.setState({isLoading: true})
    try {
      let url = `/api/fetch-tracked-sales?company_id=${store.state.tracking.company.id}
        &date_start=${store.state.tracking.date_start}
        &date_end=${store.state.tracking.date_end}`

      if(store.state.tracking.products.length) {
        let product_ids = []
        for(let item of store.state.tracking.products) {
          product_ids.push(item.id)
        }
        url += `&product_ids=${product_ids}`
      }

      let { data } = await global.axios({
        method: "GET",
        url: url
      });

      store.actions.setState({
        tracking: {
          ...store.state.tracking,
          list_data: data
        },
        isLoading: false
      })

      return data
    }
    catch (error) {
      console.log(error)
      store.setState({isLoading: false})
      return false
    }
  }
};

const useReport = globalHook(React, state, actions);

export default useReport;
