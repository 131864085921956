/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {Switch, Redirect, Route} from "react-router-dom";
import { ContentRoute } from "~layout";
import { useAuthRoute } from "~store";

export default function AuthPage() {
  const [state, ] = useAuthRoute()
  return (
    <Switch>
      {state.routes.map((route, i) => {
        if(route.hasOwnProperty('redirect')) {
          return (
            <Route key={i} exact
             path={route.path}
             render={() => (<Redirect to={route.redirect} />)}
            />
          )
        }
        return(
          <ContentRoute key={i}
            path={route.path}
            component={route.component}
            exact={route.exact}
            private={route.private}
          />
        )
      })}
    </Switch>
  );
}
