import React from "react";
import { ProgressBar } from "react-bootstrap";
import {SplashScreen} from "~components";

export function ModalProgressBar({variant = "success", show = false}) {
  return (
    <div className={"h-10px"}>
      {show && (
        <>
          <SplashScreen />
          <ProgressBar
            variant={variant}
            animated
            now={100}
            style={{ height: "3px", width: "100%" }}
          />
        </>
      )}
    </div>

  );
}
