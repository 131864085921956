import React, {useEffect, useState} from "react"
import CustomSelectField from "./CustomSelectField"

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
]

const DateMonthPicker = ({
  className= "",
  label = "MONTH",
  value= new Date().getMonth() + 1,
  handleChange
}) => {
  const [options, setOptions] = useState([])
  const [selected, setSelected] = useState(value)
  useEffect(() => {
    let temp = []
    for(let i in months) {
      temp.push({ value: parseInt(i)+1, label: months[i] })
    }
    setOptions(temp)
  }, [])
  const handleOnChange = (newValue) => {
    setSelected(newValue)
    handleChange(newValue)
  }
  return(
    <CustomSelectField
      className={className}
      label={label}
      options={options}
      value={selected}
      onChange={(event) => handleOnChange(event.target.value)}
    />
  )
}
export default DateMonthPicker
