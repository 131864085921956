import React from "react";
import {formatNumber} from "~/app/helpers/format_functions";

const AmountFormatter = (
  cellContent,
  row
) => {
  let amount = 0
  for(let item of row.items) {
    amount += (item.quantity * item.unit_price)
  }
  return (
    <span>
      {amount ? formatNumber(amount) : ""}
    </span>
  )
}

export default AmountFormatter;
