import React from 'react';
import globalHook from 'use-global-hook';
import moment from "moment";

const current_date = moment(new Date()).format("YYYY-MM-DD")

const initData = {
  id: '',
  customer_id: '',
  cheque_info_id: '',
  payment_type: 'cash',
  p_date: current_date,
  amount: '',
  bank_name: '',
  account_number: '',
  cheque_number: '',
  issued_date: current_date,
  payee: '',
  customer: null,
  branch_id: '',
  branch: null,
  payee_name: ''
}

const payment_types = [
  {value: "", label: ""},
  {value: "cash", label: "Cash"},
  {value: "cheque", label: "Cheque"},
];

const initialState = {
  loading: false,
  errors: null,
  listCount: 0,
  lastParams: "",
  listData: [],
  data: JSON.parse(JSON.stringify(initData)),
  payment_types: payment_types,
  bill_payments: []
};

const state = JSON.parse(JSON.stringify(initialState));

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  resetData: (store) => {
    store.setState({
      loading: false,
      errors: null,
      data: JSON.parse(JSON.stringify(initData)),
      bill_payments: []
    });
  },
  fetchList: async (store, queryParams = null, filters = null) => {
    store.setState({ loading: true });

    try {
      let params = "";

      if(!queryParams) {
        params = store.state.lastParams;
      } else {
        params = `?page=${queryParams.pageNumber}
          &pageSize=${queryParams.pageSize}
          &sortField=${queryParams.sortField}
          &sortOrder=${queryParams.sortOrder}
          &searchField=${filters.searchField}
          &searchText=${filters.searchText}
          &branch_id=${filters.branchId !== "all" ? filters.branchId : ""}
          &payment_type=${filters.paymentType !== "all" ? filters.paymentType : ""}`;
      }

      let { data } = await global.axios({
        method: "GET",
        url: `/api/payments${params}`
      });

      store.setState({
        loading: false,
        listData: data.data,
        listCount: data.meta.total,
        lastParams: params
      });
    }
    catch (error) {
      store.setState({ loading: false });
      console.log(error);
    }    
  },
  show: async (store, id) => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/payments/${id}`
      });
      store.actions.setState({ data: data.data });

      return data.data
    }
    catch (error) {
      console.log(error);
    }
  },
  save: async (store, history = null) => {
    store.setState({
      loading: true,
      errors: null
    });

    try {
      let url = `/api/payments`;
      let payload = JSON.parse(JSON.stringify(store.state.data))

      if(payload.id) {
        url += `/${payload.id}`;
        payload['_method'] = 'PUT';
      }

      let { data } = await global.axios.post(url, payload);

      store.setState({ loading: false });

      if(!store.state.data.id && history) {
        history.push(`/payments/edit/${data.data.id}`);
      }

      return data.data;
    }
    catch ({ response }) {
      if(response.status === 422) {

        store.setState({
          loading: false,
          errors: response.data.errors
        });

        return false;
      }
    }
  },
  delete: async (store, id) => {
    try {
      await global.axios({
        method: "DELETE",
        url: `/api/payments/${id}`
      });

      store.actions.fetchList();
    }
    catch (error) {
      console.log(error);
    }
  },
  payBill: async (store) => {
    try {
      let url = `/api/pay-bill`;

      let payload = store.state.data

      if(payload.id) {
        url += `/${payload.id}`;
        payload['_method'] = 'PUT';
      }

      let { data } = await global.axios.post(url, payload);

      store.setState({
        bill_payments: data.data,
        error: null
      })

      return data.data;
    }
    catch ({ response }) {
      if(response.status === 422) {
        store.setState({
          loading: false,
          isSaving: false,
          errors: response.data.errors
        });
        return false;
      }
    }
  },
  fetchBillPayments: async (store, billing_id) => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/pay-bill?billing_id=${billing_id}`
      });

      store.setState({ bill_payments: data.data })

      return data.data
    }
    catch (error) {
      console.log(error);
    }
  }
};

const usePayment = globalHook(React, state, actions);

export default usePayment;
