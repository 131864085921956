import React, { Fragment } from "react";
import PropTypes from 'prop-types';

const FormErrorMessage = ({ errors }) => {

  if(Object.keys(errors).length === 0) {
    return "";
  }

  return(
    <div className="alert alert-custom alert-light-danger fade show mb-5" role="alert">
      <div className="alert-icon align-items-unset">
        <i className="flaticon-warning" />
      </div>
      <div className="alert-text">
        <div className={"font-weight-bolder font-size-h6 pl-5"}>
          Error!
        </div>
        <ul className={"mt-1 mb-0"}>
          {Object.keys(errors).map((key) => {
            return(
              <Fragment key={key}>
                {errors[key].map((error, i) => {
                  return(
                    <li key={`${key}-${i}`}>
                      { error }
                    </li>
                  )
                })}
              </Fragment>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

FormErrorMessage.propTypes = {
  errors: PropTypes.object.isRequired,
};

export default FormErrorMessage;
