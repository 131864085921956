import React, {memo, useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import {BorderColorRounded, VisibilityRounded} from '@material-ui/icons';
import {Button, Tooltip} from "@material-ui/core";
import {green} from "@material-ui/core/colors";
import { CustomDialog } from "~components";
import {hasPermissions} from "~/app/helpers/user";
import {useAwardMemo} from "~store";

const DetailDialog = ({ row }) => {
  return(
    <CustomDialog
      maxWidth={"sm"}
      title={"Award Memo Details"}
      triggerButton={
        <Tooltip arrow title={"View Details"}>
          <Button size={"small"} color={"secondary"} className={"min-w-auto p-1"}>
            <VisibilityRounded style={{color: green[600]}} />
          </Button>
        </Tooltip>
      }
      actionButtons={!hasPermissions(['edit_award_memos']) ? <></> :
        <NavLink to={`/services/award-memos/edit/${row.id}`}>
          <Button variant={"contained"} size={"small"} color={"primary"}>
            <BorderColorRounded fontSize={"small"} className={"mr-2"} />
            Update
          </Button>
        </NavLink>
      }
    >
      <Content row={row} />
    </CustomDialog>
  )
}

const Content = ({ row }) => {
  const [state, ] = useAwardMemo();
  const [data, setData] = useState([])
  useEffect(() => {
    if(row.type === 'trips') {
      setData([
        {id: "branch_name", label: "Branch"},
        {id: "company_name", label: "Client"},
        {id: "m_date", label: "Date"},
        {id: "document_number", label: "P.O. Number"},
        {id: "type", label: "Type"},
        {id: "number_of_trips", label: "Number of Trips"},
      ])
    } else {
      setData([
        {id: "branch_name", label: "Branch"},
        {id: "company_name", label: "Client"},
        {id: "m_date", label: "Date"},
        {id: "document_number", label: "P.O. Number"},
        {id: "type", label: "Type"},
        {id: "date_from", label: "From Date"},
        {id: "date_to", label: "To Date"},
      ])
    }
  }, [row.type])
  return(
    <div className={"w-100 px-5 d-flex flex-center flex-column"}>
     <div style={{ width: "fit-content" }}>
       {data.map(item => {
         let value = row[item.id]
         if(item.id === "type") {
           let temp =  state.memo_types.filter(memo => memo.value === row[item.id])
           if(temp.length > 0) {
             value = temp[0].label
           }
         }
         return (
           <div key={item.id} className={"d-flex flex-row align-items-baseline py-1"}>
             <div className={"font-weight-bold text-dark-50 w-120px"}>{item.label}:</div>
             <div className={"font-weight-bold font-size-lg"}>{value}</div>
           </div>
         )
       })}
     </div>
    </div>
  )
}

export default memo(DetailDialog)
