import React from "react";

const colors = {
  paid: "success",
  partial: "primary",
  unpaid: "danger",
}

const PaymentStatusColumnFormatter = (
  cellContent,
  row
) => {
  return (
    <span className={`py-1 rounded font-weight-bold text-uppercase text-${colors[row.payment_status]}`}>
      {row.payment_status}
    </span>
  )
}

export default PaymentStatusColumnFormatter;
