import React from "react";
import {Autocomplete} from "@material-ui/lab";
import {TextField} from "@material-ui/core";
import {useReportProducts} from "~store";

const FilterProduct = () => {
  const [state, actions] = useReportProducts()
  return (
    <div className={"flex-grow-1"}>
      <Autocomplete
        options={state.tracking_products}
        disabled={state.tracking_products.length === 0}
        getOptionLabel={(option) => option.name}
        value={state.tracking_product}
        onChange={(event, newValue) => actions.setState({ tracking_product: newValue })}
        renderInput={(params) => (
          <TextField {...params} fullWidth margin={"dense"} variant="outlined" label="Product" />
        )}
      />
    </div>
  )
}
export default FilterProduct
