import React from "react";
import {formatNumber} from "~/app/helpers/format_functions";

const DateCreatedColumnFormatter = (
  cellContent,
  row
) => {
  return (
    <span>
      {formatNumber(row.stocks, 0)}
    </span>
  )
}

export default DateCreatedColumnFormatter;
