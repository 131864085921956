import React from 'react';
import globalHook from 'use-global-hook';

const initialState = {
  loading: false,
  items: [],
  total: 0,
};

const state = JSON.parse(JSON.stringify(initialState));

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  fetch: async (store, sale_id) => {
    store.setState({ isLoading: true })
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/sale-items?sale_id=${sale_id}`
      });

      let items = data.data
      let newItems = []
      let total = 0
      for(let item of items) {

        let price = parseFloat(item.price)
        let quantity = parseFloat(item.quantity)
        let discount = parseFloat(item.discount)

        let amount =  price * quantity

        if(discount && discount > 0) {

          let unit_discount = discount

          if(item.discount_type === "percent") {
            unit_discount = price * (discount / 100)
          }

          amount = quantity * (price - unit_discount)
        }

        newItems.push({
          ...item,
          amount: amount
        })

        total += amount
      }

      store.actions.setState({
        items: newItems,
        total: total,
        isLoading: false
      });
    }
    catch (error) {
      console.log(error);
      store.setState({ isLoading: false })
    }
  },
};

const useSaleItem = globalHook(React, state, actions);

export default useSaleItem;
