import React from 'react';
import { headerSortingClasses, sortCaret } from "~helpers";
import { 
  ActionsColumnFormatter,
  AmountColumnFormatter,
  DateCreatedColumnFormatter,
} from "~pages/Expenses/components/column-formatters";
import globalHook from 'use-global-hook';

const columns = [
  {
    dataField: "id",
    text: "ID",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    style: { width: "70px" },
  },
  {
    dataField: "date",
    text: "Date",
    formatter: DateCreatedColumnFormatter,
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "reference_no",
    text: "Reference No.",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "user_name",
    text: "Employee",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "amount",
    text: "Amount",
    formatter: AmountColumnFormatter,
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "company_name",
    text: "Supplier",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "branch_name",
    text: "Branch",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "action",
    text: "Actions",
    formatter: ActionsColumnFormatter,
    classes: "text-right pr-0",
    headerClasses: "text-right pr-3",
    style: { minWidth: "125px" },
  },
];

const initialState = {
  columns: columns,
  defaultSorted: [{ dataField: "created_at", order: "desc" }],
  sizePerPageList: [
    { text: "10", value: 10 },
    { text: "20", value: 20 },
    { text: "50", value: 50 },
    { text: "100", value: 100 }
  ],
  queryParams: {
    sortOrder: "desc",
    sortField: "date",
    pageNumber: 1,
    pageSize: 10,
  },
  filter: {
    searchField: "reference_no",
    searchText: "",
    branch_id: "all",
  },
  searchFields: [
    {value: "reference_no", label: "Reference No."},
    {value: "u.name", label: "Employee"},
    {value: "c.name", label: "Supplier"},
  ]
};

const state = initialState;

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  }
};

const useExpenseList = globalHook(React, state, actions);

export default useExpenseList;
