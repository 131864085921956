import React, { useEffect, Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "~app/Routes";
import { I18nProvider } from "~metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "~metronic/layout";
import { useAuth } from "~store";

const App = () => {
  const [, authActions] = useAuth();
  useEffect(() => {
    const init = async () => {
      await authActions.setUserDetails();
    }
    init();
  }, []);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter>
        <MaterialThemeProvider>
          <I18nProvider>
            <Routes />
          </I18nProvider>
        </MaterialThemeProvider>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
