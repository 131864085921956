import React from 'react';
import { headerSortingClasses, sortCaret } from "~helpers";
import {
  ActionsColumnFormatter,
  DateColumnFormatter,
  ServiceTypeColumnFormatter,
  ChargeColumnFormatter,
  StatusColumnFormatter
} from "~pages/Services/components/column-formatters";
import globalHook from 'use-global-hook';

const columns = [
  {
    dataField: "id",
    text: "ID",
    sort: true,
    sortCaret: sortCaret,
    headerClasses: "w-65px",
    headerSortingClasses,
  },
  {
    dataField: "s_date",
    text: "Date",
    formatter: DateColumnFormatter,
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "branch_name",
    text: "Branch",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "company_name",
    text: "Client",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "service_type",
    text: "Type",
    formatter: ServiceTypeColumnFormatter,
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    classes: "text-capitalize"
  },
  {
    dataField: "partner_name",
    text: "Partner",
    sort: false,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "plate_number",
    text: "Plate No.",
    sort: false,
    sortCaret: sortCaret,
    headerSortingClasses,
    classes: "text-capitalize"
  },
  {
    dataField: "driver_name",
    text: "Driver",
    sort: false,
    sortCaret: sortCaret,
    headerSortingClasses,
    classes: "text-capitalize"
  },
  {
    dataField: "charge",
    text: "Charge",
    formatter: ChargeColumnFormatter,
    sort: false,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "text-right",
    classes: "text-right"
  },
  {
    dataField: "billing_id",
    text: "Status",
    formatter: StatusColumnFormatter,
    sort: false,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "user_name",
    text: "Created By",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "action",
    text: "Actions",
    formatter: ActionsColumnFormatter,
    classes: "text-right pr-0",
    headerClasses: "max-w-130px text-right pr-3",
  },
];

const initialState = {
  columns: columns,
  defaultSorted: [{ dataField: "id", order: "desc" }],
  sizePerPageList: [
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "30", value: 30 },
    { text: "50", value: 50 },
    { text: "100", value: 100 }
  ],
  queryParams: {
    sortOrder: "desc",
    sortField: "id",
    pageNumber: 1,
    pageSize: 15,
  },
  filter: {
    searchField: "v.plate_number",
    searchText: "",
    branch_id: "all",
    status: ""
  },
  search_fields: [
    {value: "c.name", label: "Client"},
    {value: "u.name", label: "Created By"},
    {value: "d.name", label: "Driver"},
    {value: "p.name", label: "Partner"},
    {value: "v.plate_number", label: "Plate No."},
    {value: "service_type", label: "Service Type"},
  ],
  status_options: [
    {value: "", label: "All"},
    {value: "for_billing", label: "For Billing"},
    {value: "billed", label: "Billed"},
  ]
};

const state = initialState;

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  resetFilter: (store) => {
    store.setState({ filter: JSON.parse(JSON.stringify(initialState.filter)) });
  }
};

const useServiceList = globalHook(React, state, actions);

export default useServiceList;
