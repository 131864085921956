import React, {Fragment, memo, useEffect, useState} from "react";
import {BorderColorRounded} from '@material-ui/icons';
import {Button, CircularProgress, Tooltip} from "@material-ui/core";
import {
  CustomDialog,
  CustomTextField,
  FormErrorMessage,
  FormSuccessMessage,
  SplashScreen
} from "~app/components";
import { useBranch } from "~store";

const EditDialog = ({ id = "" }) => {

  const [state, actions] = useBranch()
  const [success, setSuccess] = useState(false)

  const handleSave = async () => {
    setSuccess(false);

    let res = await actions.save();
    if(res) {
      setSuccess(true);
      setTimeout(function() {
        setSuccess(false);
      }, 7000)
    }
  }

  const handleClose = () => {
    actions.resetData()
  }

  return(
    <CustomDialog
      title={(id || state.data.id) ? "Edit details" : "Create new branch"}
      maxWidth={"xs"}
      triggerButton={id ? (
        <Tooltip arrow title={"View Details"}>
          <Button size={"small"} color={"primary"} className={"min-w-auto p-1"}>
            <BorderColorRounded />
          </Button>
        </Tooltip>
      ) : (
        <Button variant={"contained"} color={"primary"}>
          Create
        </Button>
      )}
      actionButtons={
        <Button variant={"contained"} size={"small"} color={"primary"}
          onClick={handleSave}
          disabled={state.isSaving}
        >
          {state.isSaving
            ? <Fragment>
                <CircularProgress size={16} className={"mr-2"} />
                Saving ...
              </Fragment>
            : "Save"
          }
        </Button>
      }
      handleClose={handleClose}
    >
      <Content
        id={id}
        state={state}
        actions={actions}
        success={success}
      />
    </CustomDialog>
  )
}

const Content = ({ id, state, actions, success }) => {

  useEffect(() => {
    if(id) {
      actions.show(id)
    }
  }, [id])

  return(
   <div className={"w-100 position-relative"}>
     {state.loading && <SplashScreen marginTop={0} />}
     {(success || (state.errors && Object.keys(state.errors).length)) && (
       <div className="row mb-5">
         <div className="col col-sm-12">
           {success && <FormSuccessMessage message={"Branch successfully saved."} />}
           {state.errors && Object.keys(state.errors).length > 0 && <FormErrorMessage errors={state.errors} />}
         </div>
       </div>
     )}
     <div className="row">
       <div className="col col-sm-12">
         <CustomTextField
           label="Name"
           value={state.data.name}
           onChange={(event) => actions.setState({
             data: {
               ...state.data,
               name: event.target.value
             }
           })}
         />
       </div>
     </div>
   </div>
  )
}

export default memo(EditDialog)
