import React from "react";
import {formatNumber} from "~/app/helpers/format_functions";

const AmountColumnFormatter = (
  cellContent,
  row
) => {
  return (
    <span>
      {formatNumber(row.total)}
    </span>
  )
}

export default AmountColumnFormatter;
