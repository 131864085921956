import React from "react";
import moment from "moment/moment";

const Details = ({ row }) => {
  const data = {
    name: "Name",
    email: "Email",
    username: "Username",
  }
  return(
    <div className={"w-100 px-2"}>
      <div className={"w-100 d-flex align-items-center py-1"}>
        <div className={"w-50 text-dark-50 pr-5 max-w-200px"}>
          Role
        </div>
        <div className={"flex-grow-1 font-size-lg"}>
          {row.roles.length > 0 ? row.roles[0].name : row.role }
        </div>
      </div>
      {Object.keys(data).map(field => {
        return (
          <div className={"w-100 d-flex align-items-center py-1"} key={field}>
            <div className={"w-50 text-dark-50 pr-5 max-w-200px"}>
              {data[field]}:
            </div>
            <div className={"flex-grow-1 font-size-lg"}>
              {field === "created_at"
                ? <>{row.created_at ? moment(row.created_at).format("YYYY-MM-DD") : ""}</>
                : <>{row[field]}</>
              }
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default Details;
