import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Button, IconButton, Tooltip} from "@material-ui/core";
import {green} from "@material-ui/core/colors";
import * as FileSaver from 'file-saver'
import XLSX from'sheetjs-style'
import {GetAppRounded} from "@material-ui/icons";

const useStyles = makeStyles({
  root: {
    borderColor: green[500],
    color: green[500],
    paddingTop: '7px',
    paddingBottom: '7px',
    textTransform: "capitalize",
    width: "100%",
  },
});

const ExportToExcel = ({ data, fileName }) => {

  const classes = useStyles()
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
  const fileExtension = ".xlsx"

  const handleExport = async () => {
    if(data.length === 0) {
      alert("No data to download")
      return
    }

    const ws = XLSX.utils.json_to_sheet(data)
    const wb = { Sheets: {'data': ws}, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'})
    const blobData = new Blob([excelBuffer], {type: fileType})
    FileSaver.saveAs(blobData, `${fileName}${fileExtension}`)
  }

  return (
    <Tooltip title={"Export to Excel"}>
      <Button
        variant={"outlined"}
        color={"primary"}
        size={"small"}
        className={"min-w-20px"}
        onClick={handleExport}
      >
        <GetAppRounded />
      </Button>
    </Tooltip>
  )
}

export default ExportToExcel
