import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {CustomTextField, DeleteModal, ReadOnlyField} from "~components";
import {isAdmin, myBranchId} from "~/app/helpers/user";
import {useTransferStock, useProduct} from "~store";

const useStyles = makeStyles({
  table: {
    minWidth: "100%",

    '& th': {
      fontWeight: 600,
      borderBottom: "1px solid #6f6f6f",
      verticalAlign: "bottom"
    },
    '& td': {
      borderBottom: "1px solid #6f6f6f",
    },
    '& .MuiTextField-root': {
      marginTop: '0 !important',
      marginBottom: '0 !important'
    },
    '& .MuiTableCell-root': {
      padding: '8px 4px 8px 0'
    },
    '& .MuiOutlinedInput-inputMarginDense': {
      paddingTop: '9.5px',
      paddingBottom: '9.5px',
    },
  },
});

const my_branch_id = myBranchId()

export default function EditFormItems({ isFromReadOnly, isToReadOnly, toProductOptions, status }) {

  const classes = useStyles()
  const [state, actions] = useTransferStock()
  const [productState, ] = useProduct()

  const handleUpdateItems = (newItems) => {
    actions.setState({ data: {
      ...state.data,
      items: newItems
    }})
  }

  const handleSetQuantity = (index, quantity) => {
    if(!quantity || quantity < 1) return

    let newItems = JSON.parse(JSON.stringify(state.data.items))
    newItems = newItems.map((item, iKey) => {
      if(iKey !== index) return item
      return {
        ...item,
        quantity: quantity
      }
    })

    handleUpdateItems(newItems)
  }

  const handleChangeProduct = (index, value, to = false) => {
    let newItems = JSON.parse(JSON.stringify(state.data.items))

    if(!to) {
      let lastIndex = newItems.length - 1
      if(index === lastIndex && !newItems[lastIndex].product_id) {
        newItems.push(JSON.parse(JSON.stringify(state.initItem)))
      }
    }

    newItems = newItems.map((item, i) => {
      if(i === index) {
        if(to) {
          item['to_product_data'] = value
          item['to_variant_id'] = value ? value.variant_id : ""
        } else {
          item['product_data'] = value
          item['product_id'] = value ? value.id : ""
          item['from_variant_id'] = value ? value.variant_id : ""
        }
      }
      return item
    })

    handleUpdateItems(newItems)
  }

  const handleDeleteItem = (index) => {
    let newItems = JSON.parse(JSON.stringify(state.data.items))
    newItems = newItems.filter((item, i) => i !== index)
    handleUpdateItems(newItems)
  }

  return(
    <div className={`w-100 mt-10 mb-5`}>
      <TableContainer className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ITEMS <span className={"font-italic font-weight-normal"}>(Required)</span></TableCell>
              <TableCell className={'text-center w-85px'}>QUANTITY</TableCell>
              {!isFromReadOnly && state.data.items.length > 1 && <TableCell className={"w-30px p-0"} />}
              {(["received", "completed"].includes(status)
                  && (state.data.to_branch_id === my_branch_id || isAdmin())
                ) && <TableCell>PRODUCT</TableCell>
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {state.data.items.map((item, index) => {
              return(
                <TableRow key={index}>
                  <TableCell>
                    {isFromReadOnly
                      ? <ReadOnlyField value={item.product_data ? item.product_data.name : ""} />
                      : <Autocomplete
                          options={productState.product_options}
                          getOptionLabel={(option) => option.name}
                          value={item.product_data}
                          onChange={(event, newValue) => handleChangeProduct(index, newValue)}
                          renderInput={(params) =>
                            <TextField margin={"dense"} variant="outlined" label={"Product"} {...params} />
                          }
                        />
                    }
                  </TableCell>
                  <TableCell className={'text-center'}>
                    {isFromReadOnly
                      ? <ReadOnlyField value={item.quantity} />
                      : <CustomTextField type={"number"} value={item.quantity}
                         onChange={(event) => handleSetQuantity(index, event.target.value)}
                        />
                    }
                  </TableCell>
                  {!isFromReadOnly && state.data.items.length > 1 && (
                    <TableCell className={"text-right"}>
                      {index !== state.data.items.length - 1 && (
                        <DeleteModal
                          dataId={index}
                          processDelete={handleDeleteItem}
                          confirmMessage={<>Are you sure you want to delete this item?</>}
                        />
                      )}
                    </TableCell>
                  )}
                  {(["received", "completed"].includes(status)
                      && (state.data.to_branch_id === my_branch_id || isAdmin())
                    )
                    && (
                      <TableCell>
                        {isToReadOnly
                          ? <ReadOnlyField value={item.to_product_data.name} />
                          : <Autocomplete
                            options={toProductOptions}
                            getOptionLabel={(option) => option.name}
                            value={item.to_product_data}
                            onChange={(event, newValue) => handleChangeProduct(index, newValue, true)}
                            renderInput={(params) =>
                              <TextField margin={"dense"} variant="outlined" label={"Product"} {...params} />
                            }
                          />
                        }
                      </TableCell>
                    )}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
