import React from 'react';
import { headerSortingClasses, sortCaret } from "~helpers";
import { 
  ActionsColumnFormatter,
  DateCreatedColumnFormatter
} from "~pages/Companies/components/column-formatters";
import globalHook from 'use-global-hook';

const columns = [
  {
    dataField: "id",
    text: "ID",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    style: { width: "70px" },
  },  
  {
    dataField: "name",
    text: "Name",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "vat_type",
    text: "VAT Type",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "tin",
    text: "TIN",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "email",
    text: "Email",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "contact_no",
    text: "Contact Number",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "contact_person",
    text: "Contact Person",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "date",
    text: "Date Registered",
    formatter: DateCreatedColumnFormatter,
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "branch_name",
    text: "Branch",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "action",
    text: "Actions",
    formatter: ActionsColumnFormatter,
    classes: "text-right pr-0",
    headerClasses: "text-right pr-3",
    style: { minWidth: "125px" },
  }
];

const initialState = {
  columns: columns,
  defaultSorted: [{ dataField: "created_at", order: "desc" }],
  sizePerPageList: [
    { text: "10", value: 10 },
    { text: "20", value: 20 },
    { text: "50", value: 50 },
    { text: "100", value: 100 }
  ],
  queryParams: {
    sortOrder: "asc",
    sortField: "name",
    pageNumber: 1,
    pageSize: 10,
  },
  filter: {
    searchField: "companies.name",
    searchText: "",
    branch_id: "all",
  },
  searchFields: [
    {value: "companies.name", label: "Name"},
    {value: "email", label: "Email"},
    {value: "tin", label: "TIN"},
    {value: "contact_person", label: "Contact Person"},
  ]
};

const state = initialState;

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  resetFilter: (store) => {
    store.setState({
      filter: {
        searchField: "companies.name",
        searchText: "",
        branch_id: "all",
      }
    })
  }
};

const useCompanyList = globalHook(React, state, actions);

export default useCompanyList;
