import React from "react";
import {formatNumber} from "~/app/helpers/format_functions";

const colors = {
  paid: "success",
  partial: "primary",
  unpaid: "danger",
}

const BalanceColumnFormatter = (
  cellContent,
  row
) => {
  return (
    <span className={`py-1 rounded font-weight-bold text-uppercase text-${colors[row.payment_status]}`}>
      {formatNumber(row.balance)}
    </span>
  )
}

export default BalanceColumnFormatter;
