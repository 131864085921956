import React from "react";
import {formatNumber} from "~/app/helpers/format_functions";

const ChargeColumnFormatter = (
  cellContent,
  row
) => {
  return (
    <span>
      {row.data.hasOwnProperty('charge') ? formatNumber(row.data.charge) : ""}
    </span>
  )
}

export default ChargeColumnFormatter;
