import React, {useEffect, Fragment} from "react"
import {
  Checkbox, Chip,
  CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  TextField
} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {CheckBoxOutlineBlank, CheckBox} from '@material-ui/icons';
import {useReport, useCompany, useProduct} from "~store";
import {CustomDatePicker} from "~/app/components";
import {formatNumber} from "~/app/helpers/format_functions";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
  table: {
    minWidth: "100%",
    marginTop: "15px",

    '& th': {
      fontWeight: 600,
      border: "1px solid #b3b3b3",
      paddingLeft: "2px"
    },
    '& td': {
      verticalAlign: "baseline",
      border: "1px solid #b3b3b3",
    },
    '& .MuiTableCell-root': {
      padding: '4px 6px'
    },
  },
});

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const TrackingReport2 = () => {

  const classes = useStyles()
  const [state, actions] = useReport()
  const [companyState, companyActions] = useCompany()
  const [productState, productActions] = useProduct()

  useEffect(() => {
    companyActions.fetchAll()
  }, [])

  useEffect(() => {
    if(state.tracking.company) {
      productActions.fetchTrackedProducts(state.tracking.company.id)
      actions.fetchTracking();
    } else {
      actions.setState({ tracking: {
          ...state.tracking,
          list_data: [],
          products: []
        }})
    }
  }, [state.tracking.company])

  useEffect(() => {
    actions.fetchTracking();
  }, [state.tracking.products, state.tracking.date_start, state.tracking.date_end])

  const handleChange = (field, value) => {
    let newState =  {
      ...state.tracking,
      [field]: value
    };
    if(field === "company") {
      newState.products = []
    }
    actions.setState({ tracking: newState})
  }

  return (
    <div>
      <div className={"d-flex justify-content-between"}>
        <div className={"flex-grow-1 font-size-h4 font-weight-bolder"}>Product Tracking</div>
      </div>
      <div className={"d-flex justify-content-between align-items-center mt-5"}>
        <div className={"w-250px"}>
          <Autocomplete
            options={companyState.all_companies}
            getOptionLabel={(option) => option.name}
            value={state.tracking.company}
            onChange={(event, newValue) => handleChange("company", newValue)}
            renderInput={(params) => <TextField {...params} margin={"dense"} variant="outlined" label={"Supplier"} />}
          />
        </div>
        <div className={"pl-1"}>
          <Autocomplete
            multiple
            disableCloseOnSelect
            className={"my-0 py-0 min-w-250px"}
            options={productState.tracked_products}
            getOptionLabel={(option) => option.name}
            renderTags={(value, getTagProps) =>
              value.slice(0, 2).map((option, index) => (
                <Chip variant="outlined" label={option.name} {...getTagProps({ index })} className={"m-0 h-25px ml-1"} />
              )).concat(
                value.length > 2 ? <span className={"pl-2 font-size-xs font-weight-bolder"}>+ {value.length - 2} more</span> : []
              )
            }
            renderOption={(option, { selected }) => (
              <Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </Fragment>
            )}
            value={state.tracking.products}
            onChange={(event, newValue) => handleChange("products", newValue)}
            renderInput={(params) => <TextField {...params} margin={"dense"} variant="outlined" label={"Products"} />}
          />
        </div>
        <div className={"w-150px pl-1"}>
          <CustomDatePicker label={"Date Start"}
            date={state.tracking.date_start}
            handleChange={(newDate) => handleChange('date_start', newDate)}
          />
        </div>
        <div className={"w-150px pl-1"}>
          <CustomDatePicker label={"Date End"}
            date={state.tracking.date_end}
            handleChange={(newDate) => handleChange('date_end', newDate)}
          />
        </div>
        <div className={"flex-grow-1 d-flex justify-content-end pt-4 pr-10"}>
          {state.isLoading && (
            <div className={"d-flex font-weight-bolder text-dark-50 font-size-sm"}>
              <CircularProgress thickness={8} size={14} className={"mr-2"} />
              Fetching data ...
            </div>
          )}
        </div>
      </div>
      <div className={"mt-5"}>
        <TableContainer className={classes.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={"w-35px"} />
                <TableCell>DATE.</TableCell>
                <TableCell>CUSTOMER NAME</TableCell>
                <TableCell>PRODUCT</TableCell>
                <TableCell>QUANTITY</TableCell>
                <TableCell>UNIT</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.tracking.list_data.map((item, index) => {
                return(
                  <TableRow key={index}>
                    <TableCell className={"text-center"}>{index + 1}</TableCell>
                    <TableCell>{item.date}</TableCell>
                    <TableCell>{item.customer}</TableCell>
                    <TableCell> {item.product} </TableCell>
                    <TableCell>{formatNumber(item.quantity, 0)}</TableCell>
                    <TableCell>{item.unit}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}
export default TrackingReport2
