import React from 'react';
import globalHook from 'use-global-hook';
import {formatNumber} from "~/app/helpers/format_functions";

const initVariant = {
  name: "",
  price: "",
  stocks: "",
  variants: [{
    name: "",
    price: "",
    stocks: "",
  }],
}

const initialState = {
  isSaving: false,
  loading: false,
  errors: null,
  listCount: 0,
  lastParams: "",
  all_products: [],
  tracked_products: [],
  products: [],
  product: {
    id: "",
    name: "",
    unit: "",
    description: "",
    user_id: "",
    branch_id: "",
    no_variant: {
      price: "",
      stocks: "",
    },
    variants: [JSON.parse(JSON.stringify(initVariant))]
  },
  initVariant: JSON.parse(JSON.stringify(initVariant)),
  new_stocks: [{}],

  product_options: []
};

const state = JSON.parse(JSON.stringify(initialState));

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  resetProduct: (store) => {
    store.setState({
      loading: false,
      error: null,
      product: JSON.parse(JSON.stringify(initialState.product))
    });
  },
  fetchAll: async (store, exclude_skus = false) => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/fetch-products`
      });
      let all_products = appendPriceToProducts(data.data, exclude_skus)
      store.actions.setState({ all_products: all_products });

      return all_products;
    }
    catch (error) {
      console.log(error);
    }
  },
  fetchList: async (store, queryParams = null, filters = null) => {
    store.setState({ loading: true });

    try {
      let params = "";

      if(!queryParams) {
        params = store.state.lastParams;
      } else {
        params = `?page=${queryParams.hasOwnProperty('pageNumber') ? queryParams.pageNumber : ""}
          &pageSize=${queryParams.hasOwnProperty('pageSize') ? queryParams.pageSize : ""}
          &sortField=${queryParams.hasOwnProperty('sortField') ? queryParams.sortField : ""}
          &sortOrder=${queryParams.hasOwnProperty('sortOrder') ? queryParams.sortOrder : ""}
          &searchText=${filters.searchText}
          &branch_id=${filters.branch_id !== "all" ? filters.branch_id : ""}`;
      }
      
      let { data } = await global.axios({
        method: "GET",
        url: `/api/products${params}`
      });

      // let all_products = appendPriceToProducts(data.data)
      store.setState({
        loading: false,
        products: data.data,
        listCount: data.hasOwnProperty('meta') ? data.meta.total : data.data.length,
        lastParams: params
      });
    }
    catch (error) {
      store.setState({ loading: false });
      console.log(error);
    }    
  },
  show: async (store, id) => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/products/${id}`
      });

      let product = appendPriceToProduct(data.data)

      product.variants = setupVariants(product.variants)
      let no_variant = product.variants.filter(item => item.name === "no_variant");
      if(no_variant.length > 0) {
        product.no_variant = no_variant[0]
        product.variants = [JSON.parse(JSON.stringify(initVariant))]
      } else {
        product.no_variant = {
          price: "",
          stocks: ""
        }
      }
      product.variants = product.variants.filter(item => item.name !== "no_variant");
      let variants = JSON.parse(JSON.stringify(product.variants))
      variants = variants.map(item => {
        if(item.variants.length === 0) {
          item.variants = [JSON.parse(JSON.stringify(initVariant))]
        }
        return item
      })
      product.variants = variants

      store.actions.setState({ product: product });
    }
    catch (error) {
      console.log(error);
    }
  },
  save: async (store, history) => {
    store.setState({
      loading: true,
      errors: null
    });

    try {
      let payload = JSON.parse(JSON.stringify(store.state.product));
      let id = payload.id
      let url = `/api/products`;

      if(payload.id) {
        url += `/${payload.id}`;
        payload['_method'] = 'PUT';
      }

      let hasVariants = payload.variants.length > 0 && payload.variants[0].name
      if(!hasVariants) {
        payload.no_variant.name = payload.no_variant.hasOwnProperty('name') && payload.no_variant.name ? payload.no_variant.name : "no_variant"
        payload.variants = [payload.no_variant]
      }

      let variants = JSON.parse(JSON.stringify(payload.variants));
      if(variants.length > 0) {
        variants = variants.map(item => {
          if(item.hasOwnProperty('variants')) {
            item.variants = item.variants.filter(sItem => sItem.name !== "")
            if(item.variants.length === 0) {
              delete item.variants
            }
          }

          return item
        })
      }
      payload.variants = variants

      let { data } = await global.axios.post(url, payload);
      store.actions.setState({
        loading: false,
        product: {
          ...store.state.product,
          id: data.data.id
        }
      });

      window.location.href = `/products/edit/${data.data.id}`

      return data.data;
    }
    catch ({ response }) {
      let errors = null;
      if(response.status === 422) {
        errors = response.data.errors;
      }
      store.setState({
        loading: false,
        error: false,
        errors: errors
      });
      return false;
    }
  },
  delete: async (store, id) => {
    try {
      await global.axios({
        method: "DELETE",
        url: `/api/products/${id}`
      });

      store.actions.fetchList();

    }
    catch (error) {
      console.log(error);
    }
  },
  getValidStocks: (store) => {
    let valid_stocks = []
    let items = JSON.parse(JSON.stringify(store.state.new_stocks))

    for(let item of items) {
      if(item.id) {
        let temp_variants = []
        for(let variant of item.variants) {
          if(variant.variants.length > 0) {
            let options = variant.variants.filter(option => option.quantity !== "" && option.quantity !== 0)
            if(options.length > 0) {
              variant.variants = options
              temp_variants.push(variant)
            }
          } else {
            if(variant.quantity !== "" && variant.quantity !== 0)
              temp_variants.push(variant)
          }
        }
        if(temp_variants.length > 0) {
          item.variants = temp_variants
          valid_stocks.push(item)
        }
      }
    }
    return valid_stocks
  },
  addStocks: async (store, data) => {
    store.setState({isSaving: true})
    try {
      await global.axios.post(`/api/add-stocks`, {data: data});
      store.setState({isSaving: false})
      return true;
    }
    catch ({ response }) {
      store.setState({isSaving: false})
      return false;
    }
  },
  fetchTrackedProducts: async (store, company_id) => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/fetch-tracked-products?company_id=${company_id}`
      });
      store.actions.setState({ tracked_products: data.data });

      return true;
    }
    catch (error) {
      console.log(error);
      return false
    }
  },
  fetchProductOptions: async (store, branch_id = "", set = true) => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/product-options?branch_id=${branch_id}`
      });

      if(set) {
        store.actions.setState({ product_options: data });
      }

      return data;
    }
    catch (error) {
      console.log(error);
      return false
    }
  }
};

const setupVariants = (variants) => {
  let newVariants = []
  for(let item of variants) {
    if(item.skus.length > 0) {
      item['stocks'] = item.skus.length
      item['price'] = item.skus[0].price
    }
    if(item.variants.length > 0 && item.variants[0].name) {
      item.variants = setupVariants(item.variants)
    }
    newVariants.push(item)
  }
  return newVariants
}

const appendPriceToProducts = (products, exclude_skus = false) => {
  let newProducts = []
  for(let product of products) {
    product = appendPriceToProduct(product, exclude_skus)
    newProducts.push(product)
  }
  return newProducts
}

const appendPriceToProduct = (product, exclude_skus = false) => {
  let stocks = 0
  let unit_price = 0
  let from_price = 0
  let to_price = 0
  let variants = JSON.parse(JSON.stringify(product.variants))
  let newVariants = []
  for (let variant of variants) {
    if(variant.skus.length > 0) {
      unit_price = formatNumber(variant.skus[0].price)
      stocks += variant.skus.filter(sku => sku.sold_at === null).length
    }
    if(exclude_skus) delete variant.skus

    if(variant.variants.length > 0) {
      let newOptions = []
      for(let option of variant.variants) {
        if(option.skus.length > 0) { // 130, 140
          let price = option.skus[0].price
          from_price = from_price === 0 || price <= from_price ? price : from_price
          to_price = to_price === 0 || price >= to_price ? price : to_price

          stocks += option.skus.filter(sku => sku.sold_at === null).length
          newOptions.push(option)
        }
        if(exclude_skus) delete option.skus
      }
      variant.variants = newOptions
      unit_price = from_price === to_price ? formatNumber(from_price) : `${formatNumber(from_price)} - ${formatNumber(to_price)}`
    }
    newVariants.push(variant)
  }
  product.variants = newVariants
  product.unit_price = unit_price
  product.price = unit_price
  product.stocks = stocks
  return product
}

const useProduct = globalHook(React, state, actions);

export default useProduct;
