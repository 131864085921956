import React from 'react';
import globalHook from 'use-global-hook';

const initialState = {
  loading: false,
  errors: null,
  listCount: 0,
  lastParams: "",
  all_roles: [],
  roles: [],
  role: {
    id: "",
    name: "",
    guard_name: "web",
    permissions: []
  },
};

const state = JSON.parse(JSON.stringify(initialState));

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  resetRole: (store) => {
    store.setState({
      loading: true,
      errors: null,
      role: JSON.parse(JSON.stringify(initialState.role))
    });
  },
  fetchAll: async (store) => {
    store.setState({ loading: true });
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/fetch-roles`
      });
      store.actions.setState({
        all_roles: data.data,
        loading: false
      });
    }
    catch (error) {
      console.log(error);
      store.setState({ loading: false });
    }
  },
  fetchList: async (store, queryParams = null, filters = null) => {
    store.setState({ loading: true });

    try {
      let params = "";

      if(!queryParams) {
        params = store.state.lastParams;
      } else {
        params = `?page=${queryParams.hasOwnProperty('pageNumber') ? queryParams.pageNumber : ""}
          &pageSize=${queryParams.hasOwnProperty('pageSize') ? queryParams.pageSize : ""}
          &sortField=${queryParams.hasOwnProperty('sortField') ? queryParams.sortField : ""}
          &sortOrder=${queryParams.hasOwnProperty('sortOrder') ? queryParams.sortOrder : ""}
          &searchText=${queryParams.hasOwnProperty('searchText') ? filters.searchText : ""}`;
      }

      let { data } = await global.axios({
        method: "GET",
        url: `/api/roles${params}`
      });

      store.setState({
        loading: false,
        roles: data.data,
        listCount: data.meta.total,
        lastParams: params
      });
    }
    catch (error) {
      store.setState({ loading: false });
      console.log(error);
    }    
  },
  show: async (store, id) => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/roles/${id}`
      });
      store.actions.setState({ role: data.data });
    }
    catch (error) {
      console.log(error);
    }
  },
  save: async (store, history) => {
    store.setState({
      loading: true,
      errors: null
    });

    try {
      let payload = store.state.role;
      let url = `/api/roles`;

      if(payload.id) {
        url += `/${payload.id}`;
        payload['_method'] = 'PUT';
      }

      let { data } = await global.axios.post(url, payload);
      store.actions.setState({
        loading: false,
        role: data.data
      });

      if(!payload.id) {
        history.push(`/management/roles/edit/${data.data.id}`);
      }

      return data.data;
    }
    catch ({ response }) {
      if(response.status === 422) {
        store.setState({
          loading: false,
          errors: response.data.errors
        });
        return false;
      }
    }
  },
  delete: async (store, id) => {
    try {
      await global.axios({
        method: "DELETE",
        url: `/api/roles/${id}`
      });

      store.actions.fetchList();

    }
    catch (error) {
      console.log(error);
    }
  },
};

const useRole = globalHook(React, state, actions);

export default useRole;
