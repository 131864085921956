import React, {useEffect, useState} from "react"
import {makeStyles} from "@material-ui/core/styles";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import DailyFilters from "./daily-filters";
import {formatNumber} from "~/app/helpers/format_functions";
import {useReportExpenses} from "~store"

const useStyles = makeStyles({
  table: {
    minWidth: "100%",

    '& th': {
      fontWeight: 600,
      border: "1px solid #b3b3b3",
      paddingLeft: "2px"
    },
    '& td': {
      verticalAlign: "baseline",
      border: "1px solid #b3b3b3",
    },
    '& .MuiTableCell-root': {
      padding: '4px 6px'
    },
  },
});

const allColumns = [
  {id: "user_name", title: "Staff Name", order: 1, class: ""},
  {id: "name", title: "Item", order: 2, class: ""},
  {id: "quantity", title: "Quantity", order: 3, class: "", decimal: 0},
  {id: "amount", title: "Unit Amount", order: 4, class: "text-right", decimal: 2},
]

const DailyReport = () => {

  const classes = useStyles()
  const [state, actions] = useReportExpenses()
  const [exportData, setExportData] = useState([])
  const [users, setUsers] = useState([])
  const [items, setItems] = useState([])
  const [columns, setColumns] = useState(allColumns)

  useEffect(() => {
    fetchData("date")
  },[
    state.daily.date,
    state.daily.date_start,
    state.daily.date_end,
    state.daily.date_filter_by,
  ])

  useEffect(() => {
    fetchData()
  },[state.daily.user])

  useEffect(() => {
    fetchData("items")
  },[JSON.stringify(state.daily.items)])

  const fetchData = async (modified_field = null) => {
    let res = await actions.fetchExpenses("daily")

    if(res) {
      let data = processData(modified_field, res, users, items)
      setUsers(data.users)
      setItems(data.items)
      setExportData(data.exportData)
    }
  }

  return(
    <div className={"d-flex flex-center"}>
      <div className={"w-100"}>
        <DailyFilters
          exportData={exportData}
          items={items}
          users={users}
          allColumns={allColumns}
          columns={columns}
          setColumns={setColumns}
        />
        <TableContainer className={classes.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={columns.length + 1} className={"border-0"}>
                  {state.isLoading && (
                    <div className={"d-flex font-weight-bolder text-dark-50 font-size-sm"}>
                      <CircularProgress thickness={8} size={14} className={"mr-2"} />
                      Fetching data ...
                    </div>
                  )}
                </TableCell>
                <TableCell className={"text-right border-0 p-0 pb-5"}>
                  <div className={"font-size-xs font-weight-bolder text-dark-65 mb-1"}>
                    TOTAL AMOUNT
                  </div>
                  <div className={"font-size-h3 font-weight-boldest"}>
                    {formatNumber(state.daily.amount)}
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={"w-35px"} />
                {columns.map(col => (
                  <TableCell key={col.id} className={col.class}>
                    {col.title}
                  </TableCell>
                ))}
                <TableCell className={"text-right"}>Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.daily.data.map((item, index) => {
                return(
                  <TableRow key={index}>
                    <TableCell className={"text-center"}>{index + 1}</TableCell>
                    {columns.map(col => (
                      <TableCell key={col.id} className={col.class}>
                        {col.hasOwnProperty('decimal') ? formatNumber(item[col.id], col.decimal) : item[col.id]}
                      </TableCell>
                    ))}
                    <TableCell className={"text-right font-weight-bold"}>
                      {formatNumber(item.quantity * item.amount)}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {!state.isLoading && state.daily.data.length === 0 && (
          <div className={"text-center mt-10 font-weight-bold font-italic text-dark-50 font-size-sm"}>
            -- No data to display --
          </div>
        )}
      </div>
    </div>
  )
}

const processData = (modified_field, res, users, items) => {
  let temp = []
  let temp_users = modified_field === "date" ? [] : users
  let temp_items = modified_field === "items" ? items : []
  for(let item of res.data) {
    if(modified_field === "date") {
      if(temp_users.filter(user => user.id === item.expense.user.id).length === 0)
        temp_users.push(item.expense.user)
    }
    if(modified_field !== "items") {
      if(temp_items.filter(t_item => t_item.name === item.name).length === 0)
        temp_items.push({id: item.name, name: item.name})
    }
    temp.push({
      "STAFF NAME": item.expense.user.name,
      "ITEM": item.name,
      "QUANTITY": formatNumber(item.quantity, 0),
      "AMOUNT": formatNumber(item.amount),
      "TOTAL": formatNumber(item.quantity * item.amount)
    })
  }
  return {
    exportData: temp,
    users: temp_users,
    items: temp_items
  }
}

export default DailyReport
