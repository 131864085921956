import React from 'react';
import { headerSortingClasses, sortCaret } from "~helpers";
import { 
  ActionsColumnFormatter,
  AmountColumnFormatter
} from "~pages/Payments/components/column-formatters";
import globalHook from 'use-global-hook';

const columns = [
  {
    dataField: "id",
    text: "ID",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "w-65px"
  },
  {
    dataField: "p_date",
    text: "Date",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    classes: "text-center",
    headerClasses: "text-center"
  },
  {
    dataField: "payee_name",
    text: "Payee",
    sort: false,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "payment_type",
    text: "Type",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "issued_date",
    text: "Issued Date",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    classes: "text-center",
    headerClasses: "text-center"
  },
  {
    dataField: "cheque_payee",
    text: "Cheque Payee",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "bank_name",
    text: "Bank",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "account_number",
    text: "Account No.",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "cheque_number",
    text: "Cheque No.",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "amount",
    text: "Amount",
    formatter: AmountColumnFormatter,
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "branch_name",
    text: "Branch",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "action",
    text: "Actions",
    formatter: ActionsColumnFormatter,
    classes: "text-right pr-0",
    headerClasses: "text-right pr-3",
  },
];

const initialState = {
  columns: columns,
  defaultSorted: [{ dataField: "p_date", order: "desc" }],
  sizePerPageList: [
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "30", value: 30 },
    { text: "50", value: 50 },
    { text: "100", value: 100 }
  ],
  queryParams: {
    sortOrder: "desc",
    sortField: "p_date",
    pageNumber: 1,
    pageSize: 15,
  },
  filter: {
    searchField: "c.name",
    searchText: "",
    branchId: "all",
    paymentType: "all",
  },
  payment_types: [
    {value: "cash", label: "Cash"},
    {value: "cheque", label: "Cheque"},
  ],
  search_fields: [
    {value: "c.name", label: "Customer"},
    {value: "ci.payee", label: "Payee"},
    {value: "ci.bank_name", label: "Bank Name"},
    {value: "ci.account_number", label: "Account Number"},
    {value: "ci.cheque_number", label: "Cheque Number"},
  ]
};

const state = initialState;

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  }
};

const usePaymentList = globalHook(React, state, actions);

export default usePaymentList;
