import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';

const CustomDatePicker = ({
  date = new Date(),
  handleChange = false,
  label = "Date",
  format = "yyyy-MM-dd",
  maxDate = new Date(),
  required = false,
  ...props
}) => {
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    if(date && typeof date === "string") {
      setSelectedDate(new Date(date));  
    }
  }, [date])

  const handleDateChange = (date) => {
    setSelectedDate(date);
    
    if(typeof handleChange === "function") {
      let formattedDate = moment(date, "YYYY-MM-DD").format("YYYY-MM-DD");
      handleChange(formattedDate);
    }
  };

  if(required) {
    label = <span>{label}<span className={`text-danger`}>*</span></span>
  }

  return (
    <>
      {/*{maxDate ? (*/}
      {/*  <MuiPickersUtilsProvider utils={DateFnsUtils}>*/}
      {/*    <KeyboardDatePicker*/}
      {/*      fullWidth*/}
      {/*      autoOk={true}*/}
      {/*      disableToolbar*/}
      {/*      variant="inline"*/}
      {/*      margin="dense"*/}
      {/*      label={label}*/}
      {/*      value={selectedDate}*/}
      {/*      format={format}*/}
      {/*      onChange={handleDateChange}*/}
      {/*      inputVariant={"outlined"}*/}
      {/*      maxDate={maxDate}*/}
      {/*      {...props}*/}
      {/*    />*/}
      {/*  </MuiPickersUtilsProvider>*/}
      {/*) : (*/}
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            fullWidth
            autoOk={true}
            disableToolbar
            variant="inline"
            margin="dense"
            label={label}
            value={selectedDate}
            format={format}
            onChange={handleDateChange}
            inputVariant={"outlined"}
            {...props}
          />
        </MuiPickersUtilsProvider>
      {/*)}*/}

      </>
  )
}

CustomDatePicker.propTypes = {
  date: PropTypes.string || PropTypes.object,
  format: PropTypes.string,
  handleChange: PropTypes.func || PropTypes.bool
};

export default CustomDatePicker;
