import React from "react";
import {Autocomplete} from "@material-ui/lab";
import {TextField} from "@material-ui/core";
import {useReportExpenses} from "~store"

const FilterStaff = ({users}) => {
  const [state, actions] = useReportExpenses()
  const handleChange = (field, value) => {
    actions.setState({
      daily: {
        ...state.daily,
        [field]: value,
        items: []
      }
    })
  }
  return(
    <div className={"w-200px pr-2"}>
      <Autocomplete
        options={users}
        disabled={users.length === 0}
        getOptionLabel={(option) => option.name}
        value={state.daily.user}
        onChange={(event, newValue) => handleChange("user", newValue)}
        renderInput={(params) => (
          <TextField {...params} margin={"dense"} variant="outlined" label="Staff" />
        )}
      />
    </div>
  )
}
export default FilterStaff
