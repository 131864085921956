import React, { memo, useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
const CustomSelectField = ({
  value = "",
  className = "mb-4",
  options = [],
  optionLabelKey = "label",
  optionValueKey = "value",
  preOptions = <></>,
  ...props
}) =>  {
  const [selected, setSelected] = useState(value);

  useEffect(() => {
    setSelected(value);
  }, [value])

  return (
    <TextField
      fullWidth
      select
      margin="dense"
      variant="outlined"
      className={`${className}`}
      value={selected}
      SelectProps={{ native: true }}      
      {...props}
    >
      {preOptions && preOptions}
      {options.map((option) => (
        <option key={option[optionValueKey]} value={option[optionValueKey]}>
          {option[optionLabelKey]}
        </option>
        ))}
    </TextField>
  );
}

export default memo(CustomSelectField);
