import React, { memo } from "react";
import { makeStyles } from '@material-ui/core/styles';
import {NavLink} from "react-router-dom";
import {BorderColorRounded, VisibilityRounded} from '@material-ui/icons';
import {Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip} from "@material-ui/core";
import {green} from "@material-ui/core/colors";
import { CustomDialog } from "~components";
import {formatNumber} from "~/app/helpers/format_functions";
import moment from "moment";
import {useAuth} from "~/store";
import {isAdmin} from "~/app/helpers/user";

const useStyles = makeStyles({
  table: {
    minWidth: "100%",
    marginTop: "25px",

    '& th': {
      fontWeight: 600,
      color: "#7E8299 !important",
      borderBottom: "1px solid #a5a0a0",
    },
    '& td': {
      verticalAlign: "baseline",
      borderBottom: "1px solid #a5a0a0",
    },
    '& .MuiTableCell-root': {
      padding: '8px 4px'
    },
  },
});

const DetailDialog = ({ row }) => {
  const [, authActions] = useAuth()
  return(
    <CustomDialog
      maxWidth={"sm"}
      title={"Pullout Details"}
      triggerButton={
        <Tooltip arrow title={"View Details"}>
          <Button size={"small"} color={"secondary"} className={"min-w-auto p-1"}>
            <VisibilityRounded style={{color: green[600]}} />
          </Button>
        </Tooltip>
      }
      actionButtons={!authActions.hasPermission(['edit_pullouts']) ? <></> :
        <NavLink to={`/pullouts/edit/${row.id}`}>
          <Button variant={"contained"} size={"small"} color={"primary"}>
            <BorderColorRounded fontSize={"small"} className={"mr-2"} />
            Update
          </Button>
        </NavLink>
      }
    >
      <Content row={row} />
    </CustomDialog>
  )
}

const Content = ({ row }) => {
  const classes = useStyles()
  return(
     <div className={"w-100 px-2"}>
       <div className={"w-100 d-flex justify-content-between"}>
         <div className={"flex-grow-1"}>
           {isAdmin() && (
             <RowDetail label={"BRANCH:"} value={row.branch_name} />
           )}
           <RowDetail
             label={"SUPPLIER:"}
             value={<>
               {row.company_name}
               {row.company_area_name ? <> | <span className={"font-weight-bolder"}>{row.company_area_name}</span></> : ""}
             </>}
           />
           <RowDetail label={"TRANS. DATE:"} value={moment(row.trans_date).format("YYYY-MM-DD")} />
           {row.plate_number && <RowDetail label={"PLATE NO.:"} value={row.plate_number} />}
           {row.entry_number && <RowDetail label={"ENTRY NO:"} value={row.entry_number} />}
           {row.exit_number && <RowDetail label={"EXIT NO.:"} value={row.exit_number} />}
           {row.dr_number && <RowDetail label={"DR NO:"} value={row.dr_number} />}
           {row.remarks && <RowDetail label={"REMARKS:"} value={row.remarks} />}
         </div>
       </div>
       <div className={"w-100"}>
         <TableContainer className={classes.table}>
           <Table>
             <TableHead>
               <TableRow>
                 <TableCell>NAME</TableCell>
                 <TableCell className={'text-center'} style={{width: '90px'}}>QUANTITY</TableCell>
                 <TableCell className={'text-center'}>UOM</TableCell>
                 <TableCell className={'text-right'} style={{width: '20%'}}>PRICE</TableCell>
                 <TableCell className={'text-right'} style={{width: '15%'}}>AMOUNT</TableCell>
               </TableRow>
             </TableHead>
             <TableBody>
               {row.items.map((item, index) => {
                 return(
                   <TableRow key={index}>
                     <TableCell>
                       {item.raw_product_name}
                     </TableCell>
                     <TableCell className={'text-center'}>
                       {item.quantity}
                     </TableCell>
                     <TableCell className={'text-center'}>
                       {item.uom}
                     </TableCell>
                     <TableCell className={'text-right'}>
                       {formatNumber(item.price)}
                     </TableCell>
                     <TableCell className={'text-right font-weight-bolder'}>
                       {item.price && item.quantity ? formatNumber(item.price * item.quantity) : ""}
                     </TableCell>
                   </TableRow>
                 )
               })}
               <TableRow>
                 <TableCell colSpan={4} className={"text-right text-dark-50 font-weight-bolder border-0"}>
                   TOTAL AMOUNT:
                 </TableCell>
                 <TableCell className={"font-size-h4 font-weight-bolder text-right border-0"}>
                   {formatNumber(row.amount)}
                 </TableCell>
               </TableRow>
             </TableBody>
           </Table>
         </TableContainer>
       </div>
     </div>
  )
}

const RowDetail = ({ label, value }) => {
  return(
    <div className={"w-100 d-flex flex-row align-items-baseline"}>
      <div className={"font-weight-bold font-size-xs text-dark-50 pr-2 w-125px"}>{label}</div>
      <div className={"font-weight-bold font-size-lg"}>{value}</div>
    </div>
  )
}

export default memo(DetailDialog)
