import React from 'react';
import globalHook from 'use-global-hook';

const date_filters = [
  {value: "today", label: "Today"},
  {value: "yesterday", label: "Yesterday"},
  {value: "this_week", label: "This Week"},
  {value: "last_week", label: "Last Week"},
  {value: "this_month", label: "This Month"},
  {value: "last_month", label: "Last Month"},
  {value: "this_year", label: "This Year"},
  {value: "last_year", label: "Last Year"},
]

const filterByOptions = [
  {id: "branch", name: "Filter By Branch"},
  {id: "manager", name: "Filter By  Manager"},
]

const initialState = {

  filterBy: "branch",
  filterByOptions: filterByOptions,
  branch_id: "",
  manager_id: "",

  date_filters: date_filters,

  is_loading_recent_sales: false,
  recent_sales: [],

  is_loading_total_sales: false,
  total_sales_date: {value: "today", label: "Today"},
  total_sales: 0,

  is_loading_total_purchases: false,
  total_purchases_date: {value: "today", label: "Today"},
  total_purchases: 0,

  is_loading_total_expenses: false,
  total_expenses_date: {value: "today", label: "Today"},
  total_expenses: 0,

  counts: {
    products: 0,
    suppliers: 0,
    customers: 0,
    users: 0,
  },

  chart_expenses: {},
  chart_expenses_date: {value: "this_month", label: "This Month"},

  chart_sales_date: {value: "this_month", label: "This Month"},
  chart_sales: {},

};
const state = initialState;

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  fetchData: async (store, type = "recent_sales") => {
    switch (type) {
      case "recent_sales":
        await store.actions.fetchRecentSales()
        break;
      case "total_sales":
        await store.actions.fetchTotal("total_sales")
        break;
      case "total_purchases":
        await store.actions.fetchTotal("total_purchases")
        break;
      case "total_expenses":
        await store.actions.fetchTotal("total_expenses")
        break;
      case "counts":
        await store.actions.fetchCounts()
        break;
      case "chart_expenses":
        await store.actions.fetchChartExpenses()
        break;
      case "chart_sales":
        await store.actions.fetchChartSales()
        break;
    }
  },
  fetchRecentSales: async (store) => {
    store.setState({ is_loading_recent_sales: true })
    try {
      let params = `?branch_id=${store.state.branch_id}
        &user_id=${store.state.user_id}`

      let { data } = await global.axios({
        method: "GET",
        url: `/api/fetch-dashboard${params}`
      });
      store.actions.setState({
        recent_sales: data,
        is_loading_recent_sales: false
      });
    }
    catch (error) {
      console.log(error);
      store.setState({ is_loading_recent_sales: false })
    }
  },
  fetchTotal: async (store, type = "total_sales") => {
    store.setState({ [`is_loading_${type}`]: true })
    try {
      let url = `/api/fetch-dashboard?type=${type}
        &date=${store.state[`${type}_date`].value}
        &branch_id=${store.state.branch_id}
        &user_id=${store.state.user_id}`

      let { data } = await global.axios({
        method: "GET",
        url: url
      });

      store.actions.setState({
        [type]: data,
        [`is_loading_${type}`]: false
      });
    }
    catch (error) {
      console.log(error);
      store.setState({ [`is_loading_${type}`]: false })
    }
  },
  fetchCounts: async (store) => {
    try {
      let params = `?type=counts
        &branch_id=${store.state.branch_id}
        &user_id=${store.state.user_id}`

      let { data } = await global.axios({
        method: "GET",
        url: `/api/fetch-dashboard${params}`
      });
      store.actions.setState({ counts: data });
    }
    catch (error) {
      console.log(error);
    }
  },
  fetchChartExpenses: async (store) => {
    try {
      let params = `?type=chart_expenses
        &date=${store.state.chart_expenses_date.value}
        &branch_id=${store.state.branch_id}
        &user_id=${store.state.user_id}`

      let { data } = await global.axios({
        method: "GET",
        url: `/api/fetch-dashboard${params}`
      });
      store.actions.setState({ chart_expenses: data });
    }
    catch (error) {
      console.log(error);
    }
  },
  fetchChartSales: async (store) => {
    try {
      let params = `?type=chart_sales
        &date=${store.state.chart_sales_date.value}
        &branch_id=${store.state.branch_id}
        &user_id=${store.state.user_id}`

      let { data } = await global.axios({
        method: "GET",
        url: `/api/fetch-dashboard${params}`
      });
      store.actions.setState({ chart_sales: data });
    }
    catch (error) {
      console.log(error);
    }
  },
};

const useDashboard = globalHook(React, state, actions);

export default useDashboard;
