/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { checkIsActive } from "~helpers";
import { useAuth } from "~/store";


export function BottomMenuList({ layoutProps }) {

  const location = useLocation();
  const [, authActions] = useAuth();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };

  return (
    <ul className={`menu-nav pb-1 ${layoutProps.ulClasses}`}>
      <li className={`menu-item ${getMenuItemActive("/my-profile")}`}>
        <NavLink className="menu-link align-items-end" to={"/my-profile"} style={{padding: "5px 24px !important"}}>
          <span className="svg-icon menu-icon">
            <SVG src={`/media/svg/icons/General/User.svg`} />
          </span>
          <span className="menu-text">Profile</span>
        </NavLink>
      </li>
      <li className={`menu-item`} onClick={() => authActions.logout()}>
        <div className="menu-link d-flex flex-row align-items-end">
          <span className="svg-icon menu-icon">
            <SVG src={`/media/svg/icons/Navigation/Sign-out.svg`} />
          </span>
          <span className="menu-text">Logout</span>
        </div>
      </li>
    </ul>
  );
}
