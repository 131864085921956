import React from 'react';
import { headerSortingClasses, sortCaret } from "~helpers";
import {
  ActionsColumnFormatter,
  DateCreatedColumnFormatter
} from "~pages/Drivers/components/column-formatters";
import globalHook from 'use-global-hook';

const columns = [
  {
    dataField: "id",
    text: "ID",
    sort: true,
    sortCaret: sortCaret,
    headerClasses: "w-65px",
    headerSortingClasses,
  },
  {
    dataField: "name",
    text: "Name",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "partner_name",
    text: "Partner",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "w-140px",
  },
  {
    dataField: "branch_name",
    text: "Branch",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "w-140px",
  },
  {
    dataField: "created_by",
    text: "Created By",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "w-140px",
  },
  {
    dataField: "created_at",
    text: "Date Registered",
    formatter: DateCreatedColumnFormatter,
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    classes: "text-center",
    headerClasses: "w-160px text-center",
  },
  {
    dataField: "action",
    text: "Actions",
    formatter: ActionsColumnFormatter,
    classes: "text-right pr-0",
    headerClasses: "w-130px text-right pr-3",
  },
];

const initialState = {
  columns: columns,
  defaultSorted: [{ dataField: "id", order: "desc" }],
  sizePerPageList: [
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "30", value: 30 },
    { text: "50", value: 50 },
    { text: "100", value: 100 }
  ],
  queryParams: {
    sortOrder: "desc",
    sortField: "id",
    pageNumber: 1,
    pageSize: 15,
  },
  filter: {
    searchField: "users.name",
    searchText: "",
    branch_id: "all"
  },
  searchFields: [
    {value: "users.name", label: "Name"},
    {value: "p.name", label: "Partner"},
  ]
};

const state = initialState;

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  resetFilter: (store) => {
    store.setState({ filter: JSON.parse(JSON.stringify(initialState.filter)) });
  }
};

const useDriverList = globalHook(React, state, actions);

export default useDriverList;
