import React from "react";
import moment from "moment";

const ExpiryDateColumnFormatter = (
  cellContent,
  row
) => {

  const current_date = new Date();
  const expiry_date = new Date(row.expiry_date);
  const diffTime = Math.abs(expiry_date - current_date);
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

  return (
    <span style={{ color: diffDays <= 30 ? "red" : "inherit" }}>
      {row.expiry_date ? moment(row.expiry_date).format("YYYY-MM-DD") : ""}
    </span>
  )
}

export default ExpiryDateColumnFormatter;
