import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import SVG from "react-inlinesvg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import { toAbsoluteUrl } from "~helpers";
import AuthContainer from "./components/AuthContainer";
import { useAuth } from "~store";
import {Button} from "@material-ui/core";

const initialValues = {
  email: "",
  password: "",
};

function Login(props) {

  const { intl } = props;
  const history = useHistory();
  const [, authActions] = useAuth();
  const [loading, setLoading] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      // .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    // if (formik.touched[fieldname] && formik.errors[fieldname]) {
    //   return "is-invalid";
    // }

    // if (formik.touched[fieldname] && !formik.errors[fieldname]) {
    //   return "is-valid";
    // }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableLoading();
      let res = await authActions.login(values, history);
      if(res) {
        disableLoading();
        history.push("/");
      } else {
        disableLoading();
        setSubmitting(false);
        setStatus(
          intl.formatMessage({
            id: "AUTH.VALIDATION.INVALID_LOGIN",
          })
        );
      }
    },
  });

  return (
    <AuthContainer>
      <div className="login-form login-signin" id="kt_login_signin_form">

        <form className="form fv-plugins-bootstrap fv-plugins-framework"
          onSubmit={formik.handleSubmit}>
          <div className="text-center w-100 mb-12">
            <img alt={""} src={"/media/logos/scorpions-logo.png"} className={"w-250px"} />
          </div>
          {formik.status && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )}
          <div className="form-group fv-plugins-icon-container">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text svg-icon svg-icon-primary svg-icon-lg">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Mail.svg")} />
                </span>
              </div>
              <input
                type="text"
                placeholder="Username / Email"
                className={`form-control form-control-solid py-7 px-6 ${getInputClasses("email")}`}
                name="email"
                {...formik.getFieldProps("email")}
                autoComplete={"off"}
              />
            </div>
          </div>
          <div className="form-group fv-plugins-icon-container">
            <div className="input-group">
              <div className="input-group-prepend">
                  <span className="input-group-text svg-icon svg-icon-primary svg-icon-lg">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Lock.svg")} />
                  </span>
              </div>
              <input
                type="password"
                placeholder="Password"
                className={`form-control form-control-solid py-7 px-6 ${getInputClasses(
                        "password"
                )}`}
                name="password"
                {...formik.getFieldProps("password")}
                autoComplete={"off"}
              />
            </div>
            {/*{formik.touched.password && formik.errors.password ? (*/}
            {/*  <div className="fv-plugins-message-container">*/}
            {/*    <div className="fv-help-block">{formik.errors.password}</div>*/}
            {/*  </div>*/}
            {/*) : null}*/}
          </div>
          <div className="form-group d-flex flex-wrap justify-content-around align-items-center">
            <Button
              fullWidth
              variant={"contained"}
              color={"primary"}
              type={"submit"}
              id="kt_login_signin_submit"
              disabled={formik.isSubmitting}
              className={`font-size-h6 py-3 my-2`}
            >
              <span>SIGN IN</span>
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </Button>
          </div>
        </form>
      </div>
    </AuthContainer>
  );
}

export default injectIntl(Login);
