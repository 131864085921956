import React, {memo, useState} from "react"
import {Button, Tooltip} from "@material-ui/core";
import {DeleteOutlineRounded} from "@material-ui/icons";
import CustomDialog from "./CustomDialog"

const initTriggerButton = (
  <Button size={"small"} color={"secondary"} className={"min-w-auto p-1"}>
    <DeleteOutlineRounded />
  </Button>
)

const DeleteModal = ({
  dataId,
  confirmMessage,
  processDelete,
  triggerButton = initTriggerButton,
  timeOut = 500
}) => {
  const [triggerClose, setTriggerClose] = useState(false);
  const [message, setMessage] = useState(confirmMessage);

  const handleDelete = async () => {
    setMessage("Deleting ...");
    await processDelete(dataId);
    setMessage("Successfully deleted.");

    setTimeout(
      function() {
        setTriggerClose(true);
      }, timeOut
    );
    setTimeout(
      function() {
        setTriggerClose(false);
      }, timeOut + 500
    );
  }

  return(
    <CustomDialog
      title={""}
      dividers={false}
      maxWidth={"sm"}
      triggerCloseButton={triggerClose}
      triggerButton={
        <Tooltip arrow title={"Delete"}>
          {triggerButton}
        </Tooltip>
      }
      actionButtons={
        <Button className={"btn btn-danger btn-elevate"}
          disabled={message === "Deleting ..."}
          onClick={() => handleDelete(dataId)}>
          Yes
        </Button>
      }
    >
      <div className={"font-size-h3 w-100 text-center py-10"}>
        {message && message}
      </div>
    </CustomDialog>
  )
}

export default memo(DeleteModal)
