import React from "react";
import moment from "moment";
import {formatNumber} from "~/app/helpers/format_functions";

const AmountColumnFormatter = (
  cellContent,
  row
) => {
  return (
    <span>
      {row.amount ? formatNumber(row.amount) : ""}
    </span>
  )
}

export default AmountColumnFormatter;
