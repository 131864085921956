import React, { memo } from "react";
import {NavLink} from "react-router-dom";
import {BorderColorRounded, VisibilityRounded} from '@material-ui/icons';
import {Button, Tooltip} from "@material-ui/core";
import {green} from "@material-ui/core/colors";
import { CustomDialog } from "~components";
import { hasPermissions } from "~/app/helpers/user";

const DetailDialog = ({ row }) => {
  return(
    <CustomDialog
      maxWidth={"xs"}
      title={"Partner Details"}
      triggerButton={
        <Tooltip arrow title={"View Details"}>
          <Button size={"small"} color={"secondary"} className={"min-w-auto p-1"}>
            <VisibilityRounded style={{color: green[600]}} />
          </Button>
        </Tooltip>
      }
      actionButtons={!hasPermissions(['edit_partners']) ? <></> :
        <NavLink to={`/services/partners/edit/${row.id}`}>
          <Button variant={"contained"} size={"small"} color={"primary"}>
            <BorderColorRounded fontSize={"small"} className={"mr-2"} />
            Update
          </Button>
        </NavLink>
      }
    >
      <Content row={row} />
    </CustomDialog>
  )
}

const data = [
  {id: "branch_name", label: "Branch"},
  {id: "name", label: "Name"},
  {id: "contact_person", label: "Contact Person"},
  {id: "contact_number", label: "Contact Number"},
  {id: "email", label: "Email"},
  {id: "address", label: "Address"},
  {id: "user_name", label: "Created By"},
]

const Content = ({ row }) => {
  return(
     <div className={"w-100 px-5"}>
       {data.map(item => {
         return(
         <div key={item.id} className={"w-100 d-flex flex-row align-items-baseline mt-1"}>
           <div className={"font-weight-bold text-dark-50 pr-2 w-120px"}>{item.label}:</div>
           <div className={"font-weight-bold font-size-lg"}>{row[item.id]}</div>
         </div>
         )
       })}
     </div>
  )
}

export default memo(DetailDialog)
