import React, {memo} from "react";
import {NavLink} from "react-router-dom";
import {BorderColorRounded, VisibilityRounded} from '@material-ui/icons';
import {Button, Tooltip} from "@material-ui/core";
import {green} from "@material-ui/core/colors";
import { CustomDialog } from "~components";
import {formatNumber} from "~/app/helpers/format_functions";
import { useAuth } from "~store";

const DetailDialog = ({ row }) => {
  const [, authActions] = useAuth()
  return(
    <CustomDialog
      title={"Payment Details"}
      maxWidth={"sm"}
      triggerButton={
        <Tooltip arrow title={"View Details"}>
          <Button size={"small"} color={"secondary"} className={"min-w-auto p-1"}>
            <VisibilityRounded style={{color: green[600]}} />
          </Button>
        </Tooltip>
      }
      actionButtons={!authActions.hasPermission(['edit_customers']) ? "" :
        <NavLink to={`/payments/edit/${row.id}`}>
          <Button variant={"contained"} size={"small"} color={"primary"}>
            <BorderColorRounded fontSize={"small"} className={"mr-2"} />
            Update
          </Button>
        </NavLink>
      }
    >
      <Content row={row} />
    </CustomDialog>
  )
}

const Content = ({ row }) => {
  return(
    <div className={"w-100 px-2"}>
      <div className={"w-100 d-flex align-items-center py-2 px-1"}>
        <div className={"w-50 text-dark-50 pr-5"}>
          Branch:
        </div>
        <div className={"flex-grow-1 font-size-lg"}>
          {row.branch_name ? row.branch_name : ""}
        </div>
      </div>
      <div className={"w-100 d-flex align-items-center py-2 px-1"}>
        <div className={"w-50 text-dark-50 pr-5"}>
          Customer:
        </div>
        <div className={"flex-grow-1 font-size-lg"}>
          {row.customer ? row.customer.name : ""}
        </div>
      </div>
      <div className={"w-100 d-flex align-items-center py-2 px-1"}>
        <div className={"w-50 text-dark-50 pr-5"}>
          Payment Date:
        </div>
        <div className={"flex-grow-1 font-size-lg"}>
          {row.p_date}
        </div>
      </div>
      <div className={"w-100 d-flex flex-wrap align-items-center py-2 px-1"}>
        <div className={"w-50 text-dark-50 pr-5"}>
          Payment Type:
        </div>
        <div className={"w-50 font-size-lg"}>
          {row.payment_type}
        </div>
        {row.payment_type === "cheque" && (
          <div className={"w-100 d-flex flex-wrap mt-6 mb-4"}>
            <div className={"w-100 font-weight-bold mb-2"}>
              Cheque Details:
            </div>
            <div className={"w-100 pb-1"}>
              <span className={"text-dark-50 pr-2"}>Issued Date:</span> {row.issued_date}
            </div>
            <div className={"w-50 pb-1"}>
              <span className={"text-dark-50 pr-2"}>Payee:</span> {row.payee}
            </div>
            <div className={"w-50 pb-1"}>
              <span className={"text-dark-50 pr-2"}>Bank Name:</span> {row.bank_name}
            </div>
            <div className={"w-50 pb-1"}>
              <span className={"text-dark-50 pr-2"}>Account Number:</span> {row.account_number}
            </div>
            <div className={"w-50 pb-1"}>
              <span className={"text-dark-50 pr-2"}>Cheque Number:</span> {row.cheque_number}
            </div>
          </div>
        )}
      </div>
      <div className={"w-100 d-flex align-items-center py-2 px-1"}>
        <div className={"w-50 text-dark-50 pr-5"}>
          Amount:
        </div>
        <div className={"flex-grow-1 font-size-lg"}>
          {formatNumber(row.amount)}
        </div>
      </div>
    </div>
  )
}

export default memo(DetailDialog)
