import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {FormControlLabel, Switch} from "@material-ui/core";
import {useProductList} from "~store";

const useStyles = makeStyles({
  root: {
    "& .MuiTypography-body1": {
      fontSize: "12px",
      fontWeight: "500",
      lineHeight: "unset",
      letterSpacing: "normal"
    }
  },
});


const PriceHeaderColumnFormatter = () => {
  return (
    <div className={"w-100 d-flex flex-row flex-wrap justify-content-between align-items-end"}>
      <div className={"flex-grow-1"}>PRICE</div>
      <Switcher />
    </div>
  )
}

const Switcher = () => {
  const classes = useStyles()
  const [state, actions] = useProductList()
  return (
    <div className={classes.root}>
      <FormControlLabel
        control={
          <Switch
            checked={state.show_variants}
            onChange={() => actions.setState({ show_variants: !state.show_variants })}
            color="primary"
            size={"small"}
          />
        }
        className={"text-capitalize m-0 font-size-sm"}
        label={state.show_variants ? "Hide variants" : "Show variants"}
        labelPlacement={"start"}
      />
    </div>
  )
}

export default PriceHeaderColumnFormatter;
