/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "~helpers";
import "~assets/sass/pages/login/classic/login-1.scss";

const Container = ({ children }) => {
  return (
    <div className="d-flex flex-column flex-root">
      <div id="kt_login"
           className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white">
        <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
          <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
            { children }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Container;
