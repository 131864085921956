import React from "react";

const ReadOnlyField = ({ label = "", value }) => {
  return(
    <div className={`position-relative rounded-sm border mb-2 mt-3 px-5 pt-3 ${label ? "pb-2" : "pb-3"}`}>
      {label && (
        <div
          className={"position-absolute font-size-xs text-dark-50 font-weight-light bg-white px-1"}
          style={{top: -8}}
        >
          {label}
        </div>
      )}
      <div className={"text-capitalize"}>
         {value}
      </div>
    </div>
  )
}
export default ReadOnlyField
