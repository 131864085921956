import React from 'react';
import globalHook from 'use-global-hook';
import moment from "moment";

const initPurchaseItem = {
  id: '',
  purchase_id: '',
  product: '',
  unit: '',
  unit_price: '',
  quantity: '1',
}

const initialState = {
  loading: false,
  errors: null,
  isSaving: false,
  listCount: 0,
  lastParams: "",
  purchases: [],
  purchase: {
    id: "",
    company_id: "",
    branch_id: "",
    reference_no: "",
    date: new Date(),
    items: [JSON.parse(JSON.stringify(initPurchaseItem))],
    company: null
  },
  initPurchaseItem: JSON.parse(JSON.stringify(initPurchaseItem)),
};

const state = JSON.parse(JSON.stringify(initialState));

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  resetPurchase: (store) => {
    store.setState({
      loading: false,
      errors: null,
      purchase: JSON.parse(JSON.stringify(initialState.purchase))
    });
  },
  fetchList: async (store, queryParams = null, filters = null) => {
    store.setState({ loading: true });

    try {
      let params = "";

      if(!queryParams) {
        params = store.state.lastParams;
      } else {
        params = `?page=${queryParams.pageNumber}
          &pageSize=${queryParams.pageSize}
          &sortField=${queryParams.sortField}
          &sortOrder=${queryParams.sortOrder}
          &searchField=${filters.searchField}
          &searchText=${filters.searchText}
          &branch_id=${filters.branch_id !== "all" ? filters.branch_id : ""}`;
      }
      
      let { data } = await global.axios({
        method: "GET",
        url: `/api/purchases${params}`
      });

      store.setState({
        loading: false,
        purchases: data.data,
        listCount: data.meta.total,
        lastParams: params
      });
    }
    catch (error) {
      store.setState({ loading: false });
      console.log(error);
    }    
  },
  show: async (store, id) => {
    store.setState({ loading: true });
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/purchases/${id}`
      });
      store.actions.setState({
        purchase: {
          ...data.data,
          items: [...data.data.items, store.state.initPurchaseItem]
        },
        loading: false
      });
    }
    catch (error) {
      console.log(error);
      store.setState({ loading: false });
    }
  },
  save: async (store, history) => {
    store.setState({
      isSaving: true,
      errors: null
    });

    try {
      let payload = JSON.parse(JSON.stringify(store.state.purchase));
      let id = payload.id
      payload.date = moment(payload.date).format("YYYY-MM-DD")
      payload.items = payload.items.filter(item => item.product && item.quantity && item.unit_price)

      let url = `/api/purchases`;
      if(payload.id) {
        url += `/${payload.id}`;
        payload['_method'] = 'PUT';
      }

      let { data } = await global.axios.post(url, payload);
      store.actions.setState({
        isSaving: false,
        purchase: {
          ...data.data,
          items: [...data.data.items, store.state.initPurchaseItem]
        }
      });

      if(!id) {
        history.push(`/purchases/edit/${data.data.id}`);
      }

      return data.data;
    }
    catch ({ response }) {
      if(response.status === 422) {
        store.setState({
          isSaving: false,
          errors: response.data.errors
        });
        return false;
      }
    }
  },
  delete: async (store, id) => {
    try {
      await global.axios({
        method: "DELETE",
        url: `/api/purchases/${id}`
      });

      store.actions.fetchList();
    }
    catch (error) {
      console.log(error);
    }
  },
  hasItems: (store) => {
    let items = store.state.purchase.items.filter(item => item.product && item.quantity && item.unit_price)
    return items.length > 0
  }
};

const usePurchase = globalHook(React, state, actions);

export default usePurchase;
