import React from "react";
import {CircularProgress} from "@material-ui/core";

const SplashScreen = ({
  opacity = 0.7,
  marginTop = -80
}) => {
  return(
    <div className="kt-splash-screen w-100 position-absolute h-100 d-flex flex-center flex-column align-items-center"
      style={{
        zIndex: 1000,
        background: `rgb(255, 255, 255, ${opacity})`,
        transition: "opacity 0.45s ease-in-out",
        maxHeight: "100vh"
    }}>
      <CircularProgress thickness={5} size={45} style={{ marginTop: marginTop }} />
    </div>
  )
}
export default SplashScreen;
