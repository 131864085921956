import React from 'react';
import globalHook from 'use-global-hook';

const initialState = {
  loading: false,
  permissions: [],
};

const state = JSON.parse(JSON.stringify(initialState));

const actions = {
  fetchAll: async (store) => {
    store.setState({ loading: true });

    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/permissions`
      });

      store.setState({
        loading: false,
        permissions: data
      });
    }
    catch (error) {
      store.setState({ loading: false });
      console.log(error);
    }
  },
};

const usePermission = globalHook(React, state, actions);

export default usePermission;
