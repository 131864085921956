import React from 'react';
import globalHook from 'use-global-hook';
import moment from "moment/moment";
import DailyReport from "../app/pages/Reports/expenses/components/DailyReport";
import MonthlyReport from "../app/pages/Reports/expenses/components/MonthlyReport";
import YearlyReport from "../app/pages/Reports/expenses/components/YearlyReport";
import BySupplierReport from "../app/pages/Reports/expenses/components/BySupplierReport";

const current_date = new Date();
let start_date = new Date(current_date.getFullYear(), current_date.getMonth(), 1)
let end_date = new Date()
start_date = moment(start_date).format("YYYY-MM-DD")
end_date = moment(end_date).format("YYYY-MM-DD")

const initDaily = {
  data: [],
  amount: 0,
  date: moment(new Date()).format("YYYY-MM-DD"),
  user: null,
  items: [],
  date_filter_by: {label: "Date", value: "date"},
  date_start: start_date,
  date_end: end_date,
}
const initMonthly = {
  data: [],
  amount: 0,
  month: current_date.getMonth() + 1,
  year: current_date.getFullYear()
}
const initYearly = {
  data: [],
  amount: 0,
  year: new Date().getFullYear()
}

const tabs = [
  {label: "Daily", component: <DailyReport />},
  {label: "Monthly", component: <MonthlyReport />},
  {label: "Yearly", component: <YearlyReport />},
  {label: "By Supplier", component: <BySupplierReport />},
]

const by_supplier_columns = [
  {id: "name", title: "Supplier Name", order: 1, class: ""},
  {id: "address", title: "Address", order: 2, class: ""},
  {id: "tin", title: "TIN", order: 3, class: ""},
  {id: "vat_in", title: "VAT IN.", order: 4, class: "text-right", decimal: 2},
  {id: "vat_ex", title: "VAT EX.", order: 5, class: "text-right", decimal: 2},
]

const state = {
  tabs: tabs,
  selectedTab: 3,
  isLoading: false,
  dateFilterByOptions: [
    {label: "Date", value: "date"},
    {label: "Date Range", value: "date_range"},
  ],
  daily: initDaily,
  monthly: initMonthly,
  yearly: initYearly,

  by_supplier_data: [],
  by_supplier_export_data: [],
  by_supplier_vat_in_total: 0,
  by_supplier_vat_ex_total: 0,
  by_supplier_date_start: start_date,
  by_supplier_date_end: end_date,
  by_supplier_all_columns: JSON.parse(JSON.stringify(by_supplier_columns)),
  by_supplier_columns: JSON.parse(JSON.stringify(by_supplier_columns))
}

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  fetchExpenses: async (store, type) => {
    store.setState({isLoading: true})
    try {
      let url = `/api/fetch-expense-report?type=${type}
        &user_id=${store.state.daily.user ? store.state.daily.user.id : ""}
        &date_filter_by=${store.state.daily.date_filter_by.value}
        &date=${store.state.daily.date}
        &date_start=${store.state.daily.date_start}
        &date_end=${store.state.daily.date_end}
        &month=${store.state.monthly.month}
        &year=${store.state[`${type}`].year}`

      if(type === "daily" && store.state.daily.items.length) {
        let items = []
        for(let item of store.state.daily.items) {
          items.push(item.id)
        }
        url += `&items=${items}`
      }

      let { data } = await global.axios({
        method: "GET",
        url: url
      });

      store.actions.setState({
        [`${type}`]: {...store.state[`${type}`], ...data},
        isLoading: false
      })

      return data
    }
    catch (error) {
      console.log(error)
      store.setState({isLoading: false})
      return false
    }
  },
  fetchBySupplier: async (store) => {
    store.setState({isLoading: true})
    try {
      let url = `/api/fetch-expense-report?type=by_supplier
        &date_start=${store.state.by_supplier_date_start}
        &date_end=${store.state.by_supplier_date_end}`

      let { data } = await global.axios({
        method: "GET",
        url: url
      });

      let export_data = store.actions.processBySupplierData(data.data)

      store.actions.setState({
        by_supplier_data: data.data,
        by_supplier_vat_in_total: data.vat_in_total,
        by_supplier_vat_ex_total: data.vat_ex_total,
        by_supplier_export_data: export_data,
        isLoading: false
      })

      return data
    }
    catch (error) {
      console.log(error)
      store.setState({isLoading: false})
      return false
    }
  },
  processBySupplierData: (store, data) => {
    let export_data = []
    for(let item of data) {
      let temp = {}
      for(let column of store.state.by_supplier_columns) {
        temp[column.title] = (['vat_in', 'vat_ex'].includes(column.id) && item[column.id] === 0) ? "" : item[column.id];
      }
      export_data.push(temp)
    }

    return export_data
  }
};

const useReportExpenses = globalHook(React, state, actions);

export default useReportExpenses;
