import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { ContentRoute } from "~layout";
import { SplashScreen } from "~components";
import { useRoute, useAuth } from "~store";
import { Layout } from "~layout";

export default function BasePage() {

  const [routeState, ] = useRoute();
  const [, authActions] = useAuth();

  return (
    <Layout>
      <Suspense fallback={<SplashScreen />}>
        <div className={"w-100 h-100 p-5"}>
          <Switch>
            {routeState.routes.map((route, i) => {
              if(route.permissions.length === 0
                || ( route.permissions.length > 0
                    && authActions.isLoggedIn()
                    && authActions.hasPermission(route.permissions)
                )
              ){
                if(route.hasOwnProperty('redirect')) {
                  return (
                    <Route key={i} exact
                      path={route.path}
                      render={() => (<Redirect to={route.redirect} />)}
                    />
                  )
                }
                return(
                  <ContentRoute key={i}
                    path={route.path}
                    component={route.component}
                    exact={route.exact}
                    private={route.private}
                  />
                )
              }
            })}
          </Switch>
        </div>
      </Suspense>
    </Layout>
  );
}
