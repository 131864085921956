import React from "react";
import {Redirect, Switch, Route, useLocation} from "react-router-dom";
import BasePage from "./BasePage";
import AuthPage from "./AuthPage";
import NotFound from "~app/pages/NotFound";
import { useRoute, useAuth } from "~store";


export function Routes() {

  const location = useLocation();
  const [, routeActions] = useRoute();
  const [, authActions] = useAuth();

  if(routeActions.isFound(location.pathname)) {
    if(!authActions.isLoggedIn() && routeActions.isPrivate(location.pathname)) {
      return(<Redirect to={"/login"} />)
    }
    if(authActions.isLoggedIn() && !routeActions.isPrivate(location.pathname)) {
      return(<Redirect to={"/dashboard"} />)
    }
  }

  return (
      <Switch>
        {!authActions.isLoggedIn() ? (
          <Route>
            <AuthPage />
          </Route>
        ) : <BasePage />
        }
        <Route component={NotFound} />
      </Switch>
  );
}
