

export const isAdmin = () => {
  let user_roles = JSON.parse(localStorage.getItem('roles'))
  return ["super-admin", "admin"].some(role => user_roles.indexOf(role) !== -1);
}

export const myBranchId = () => {
  let user = JSON.parse(localStorage.getItem('user'))
  return user && user.hasOwnProperty('branch_id') && user.branch_id && user.branch_id !== "null" ? parseInt(user.branch_id) : ""
}

export const hasPermissions = (permissions) => {
  let user_permissions = JSON.parse(localStorage.getItem('permissions'))
  return permissions.some(permission => user_permissions.indexOf(permission) !== -1);
}
