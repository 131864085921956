import React from "react";
const RoleColumnFormatter = (
  cellContent,
  row
) => {
  return (
    <span>
      {row.roles.length > 0 ? row.roles[0].name : ""}
    </span>
  )
}

export default RoleColumnFormatter;
