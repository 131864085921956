import React from "react";
import {Link} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    borderRadius: "50%",
    bottom: "-35px",
    right: "101px",
    zIndex: 9,

    "& .avatar-wrapper": {
      width: "72px",
      height: "72px",
      borderRadius: "50%",
      fontSize: 30,

      "& img": {
        width: "72px",
        height: "72px",
        borderRadius: "50%",
      }
    },
  }
});

const Avatar = () => {
  const classes = useStyles()
  let user = JSON.parse(localStorage.user)
  let avatar = user.hasOwnProperty('avatar') ? user.avatar : null

  return(
    <div className={`${classes.root} position-absolute`}>
      <Link to="/my-profile" className="brand-logo">
        <div className="avatar-wrapper d-flex flex-center align-items-center font-weight-bolder bg-light-primary border border-3 border-light-dark">
          {!avatar
            ? user.name.trim() !== "" ? user.name.charAt(0) : user.email.charAt(0)
            : <img alt={""} className={"h-100 w-100"} src={avatar}/>
          }
        </div>
      </Link>
    </div>
  )
}

export default Avatar;
