import React from 'react';
import { headerSortingClasses, sortCaret } from "~helpers";
import {
  ActionsColumnFormatter,
  DateCreatedColumnFormatter,
  ExpiryDateColumnFormatter
} from "~pages/Documents/components/column-formatters";
import globalHook from 'use-global-hook';

const columns = [
  {
    dataField: "created_at",
    text: "Date Created",
    formatter: DateCreatedColumnFormatter,
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "w-140px"
  },
  {
    dataField: "document_number",
    text: "Doc. Number",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "document_type",
    text: "Doc. Type",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "holder_name",
    text: "Holder Name",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "issuing_authority",
    text: "Issuing Authority",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "issue_date",
    text: "Issue Date",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "expiry_date",
    text: "Expiry Date",
    formatter: ExpiryDateColumnFormatter,
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "status",
    text: "Status",
    sort: false,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "action",
    text: "Actions",
    formatter: ActionsColumnFormatter,
    classes: "text-right pr-0",
    headerClasses: "w-130px text-right pr-3",
  },
];

const initialState = {
  columns: columns,
  defaultSorted: [{ dataField: "created_at", order: "desc" }],
  sizePerPageList: [
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "30", value: 30 },
    { text: "50", value: 50 },
    { text: "100", value: 100 }
  ],
  queryParams: {
    sortOrder: "desc",
    sortField: "id",
    pageNumber: 1,
    pageSize: 15,
  },
  filter: {
    searchField: "document_number",
    searchText: "",
    branch_id: "all"
  },
  searchFields: [
    {value: "document_number", label: "Document Number"},
    {value: "document_type", label: "Document Type"},
    {value: "holder_name", label: "Holder Name"},
    {value: "issuing_authority", label: "Issuing Authority"},
    {value: "status", label: "Status"},
  ]
};

const state = initialState;

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  resetFilter: (store) => {
    store.setState({ filter: JSON.parse(JSON.stringify(initialState.filter)) });
  }
};

const useDocumentList = globalHook(React, state, actions);

export default useDocumentList;
