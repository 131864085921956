import React, { lazy } from 'react';
import globalHook from 'use-global-hook';

const MyProfile = lazy(() => import("~pages/MyProfile"));

const Dashboard = lazy(() => import("~pages/Dashboard"));

const UserManagement = lazy(() => import("~pages/UserManagement"));
const UserManagementEdit = lazy(() => import("~pages/UserManagementEdit"));

const Roles = lazy(() => import("~pages/Roles"));
const RolesEdit = lazy(() => import("~pages/RolesEdit"));

const Expenses = lazy(() => import("~pages/Expenses"));
const ExpensesEdit = lazy(() => import("~pages/ExpensesEdit"));

const Companies = lazy(() => import("~pages/Companies"));
const CompaniesEdit = lazy(() => import("~pages/CompaniesEdit"));

const Customers = lazy(() => import("~pages/Customers"));
const CustomersEdit = lazy(() => import("~pages/CustomersEdit"));
const CustomersPDF = lazy(() => import("~pages/CustomersPDF"));

const Products = lazy(() => import("~pages/Products"));
const ProductsEdit = lazy(() => import("~pages/ProductsEdit"));
const ProductsAddStocks = lazy(() => import("~pages/ProductsAddStocks"));

const Sales = lazy(() => import("~pages/Sales"));
const SalesEdit = lazy(() => import("~pages/SalesEdit"));

const TransferStocks = lazy(() => import("~pages/TransferStocks"));

const Purchases = lazy(() => import("~pages/Purchases"));
const PurchasesEdit = lazy(() => import("~pages/PurchasesEdit"));

const Payments = lazy(() => import("~pages/Payments"));
const PaymentsEdit = lazy(() => import("~pages/PaymentsEdit"));

const Branches = lazy(() => import("~pages/Branches"));

const CashOnHand = lazy(() => import("~pages/CashOnHand"));
const CashOnHandPDF = lazy(() => import("~pages/CashOnHand/CashOnHandPDF"));

const Partners = lazy(() => import("~pages/Partners"));
const PartnersEdit = lazy(() => import("~pages/PartnersEdit"));

const Vehicles = lazy(() => import("~pages/Vehicles"));
const VehiclesEdit = lazy(() => import("~pages/VehiclesEdit"));

const Services = lazy(() => import("~pages/Services"));
const ServicesEdit = lazy(() => import("~pages/ServicesEdit"));

const AwardMemos = lazy(() => import("~pages/AwardMemos"));
const AwardMemosEdit = lazy(() => import("~pages/AwardMemosEdit"));

const Drivers = lazy(() => import("~pages/Drivers"));
const DriversEdit = lazy(() => import("~pages/DriversEdit"));

const Documents = lazy(() => import("~pages/Documents"));
const DocumentsEdit = lazy(() => import("~pages/DocumentsEdit"));

const Billings = lazy(() => import("~pages/Billings"));
const BillingsEdit = lazy(() => import("~pages/BillingsEdit"));
const BillingPDFHaulingWaste = lazy(() => import("~pages/BillingsEdit/components/PDFHaulingWaste"));
const BillingPDFQuarry = lazy(() => import("~pages/BillingsEdit/components/PDFQuarry"));
const BillingPDFQuarryByPartner = lazy(() => import("~pages/BillingsEdit/components/PDFQuarryByPartner"));

const ReportsExpenses = lazy(() => import("~pages/Reports/expenses"));
const ReportsPurchases = lazy(() => import("~pages/Reports/purchases"));
const ReportsSales = lazy(() => import("~pages/Reports/sales"));
const ReportsProducts = lazy(() => import("~pages/Reports/products"));

const Pullouts = lazy(() => import("~pages/Pullouts"));
const PulloutsEdit = lazy(() => import("~pages/PulloutsEdit"));

const Payables = lazy(() => import("~pages/Payables"));
const PayablesEdit = lazy(() => import("~pages/PayablesEdit"));

const state = {
  routes: [
    {
      path: "/my-profile",
      component: MyProfile,
      permissions: [],
      exact: true,
      private: true
    },
    {
      path: "/",
      redirect: "/my-profile",
      permissions: [],
      exact: true,
      private: true
    },
    {
      path: "/dashboard",
      component: Dashboard,
      permissions: [],
      exact: true,
      private: true
    },
    {
      path: "/management/branches",
      component: Branches,
      permissions: ['list_branches'],
      exact: true,
      private: true
    },
    {
      path: "/management/users",
      component: UserManagement,
      permissions: ['list_users'],
      exact: true,
      private: true
    },
    {
      path: "/management/users/edit/:id",
      component: UserManagementEdit,
      permissions: ['create_users', 'edit_users'],
      exact: true,
      private: true
    },
    {
      path: "/management/roles",
      component: Roles,
      permissions: ['list_roles'],
      exact: true,
      private: true
    },
    {
      path: "/management/roles/edit/:id",
      component: RolesEdit,
      permissions: ['create_roles', 'update_roles'],
      exact: true,
      private: true
    },
    {
      path: "/expenses",
      component: Expenses,
      permissions: ['list_expenses'],
      exact: true,
      private: true
    },
    {
      path: "/expenses/edit/:id",
      component: ExpensesEdit,
      permissions: ['create_expenses', 'edit_expenses'],
      exact: true,
      private: true
    },
    {
      path: "/suppliers",
      component: Companies,
      permissions: ['list_suppliers'],
      exact: true,
      private: true
    },
    {
      path: "/suppliers/edit/:id",
      component: CompaniesEdit,
      permissions: ['create_suppliers', 'edit_suppliers'],
      exact: true,
      private: true
    },
    {
      path: "/customers",
      component: Customers,
      permissions: ['list_customers'],
      exact: true,
      private: true
    },
    {
      path: "/customers/edit/:id",
      component: CustomersEdit,
      permissions: ['create_customers', 'edit_customers'],
      exact: true,
      private: true
    },
    {
      path: "/customers-transactions-pdf",
      component: CustomersPDF,
      permissions: [],
      exact: true,
      private: true
    },
    {
      path: "/products",
      component: Products,
      permissions: ['list_products'],
      exact: true,
      private: true
    },
    {
      path: "/products/edit/:id",
      component: ProductsEdit,
      permissions: ['create_products', 'edit_products'],
      exact: true,
      private: true
    },
    {
      path: "/products/add-stocks",
      component: ProductsAddStocks,
      permissions: [],
      exact: true,
      private: true
    },
    {
      path: "/sales",
      component: Sales,
      permissions: ['list_sales'],
      exact: true,
      private: true
    },
    {
      path: "/sales/edit/:id",
      component: SalesEdit,
      permissions: ['create_sales', 'edit_sales'],
      exact: true,
      private: true
    },
    {
      path: "/purchases",
      component: Purchases,
      permissions: ['list_purchases'],
      exact: true,
      private: true
    },
    {
      path: "/purchases/edit/:id",
      component: PurchasesEdit,
      permissions: ['create_purchases', 'edit_purchases'],
      exact: true,
      private: true
    },
    {
      path: "/payments",
      component: Payments,
      permissions: ['list_payments'],
      exact: true,
      private: true
    },
    {
      path: "/payments/edit/:id",
      component: PaymentsEdit,
      permissions: ['create_payments', 'edit_payments'],
      exact: true,
      private: true
    },
    {
      path: "/transfer-stocks",
      component: TransferStocks,
      permissions: ['list_transfer_stocks'],
      exact: true,
      private: true
    },
    {
      path: "/services/partners",
      component: Partners,
      permissions: ['list_partners'],
      exact: true,
      private: true
    },
    {
      path: "/services/partners/edit/:id",
      component: PartnersEdit,
      permissions: ['edit_partners'],
      exact: true,
      private: true
    },
    {
      path: "/services/vehicles",
      component: Vehicles,
      permissions: ['list_vehicles'],
      exact: true,
      private: true
    },
    {
      path: "/services/vehicles/edit/:id",
      component: VehiclesEdit,
      permissions: ['edit_vehicles'],
      exact: true,
      private: true
    },
    {
      path: "/services/list",
      component: Services,
      permissions: ['list_services'],
      exact: true,
      private: true
    },
    {
      path: "/services/list/edit/:id",
      component: ServicesEdit,
      permissions: ['edit_services'],
      exact: true,
      private: true
    },
    {
      path: "/services/award-memos",
      component: AwardMemos,
      permissions: ['list_award_memos'],
      exact: true,
      private: true
    },
    {
      path: "/services/award-memos/edit/:id",
      component: AwardMemosEdit,
      permissions: ['edit_award_memos'],
      exact: true,
      private: true
    },
    {
      path: "/services/drivers",
      component: Drivers,
      permissions: ['list_drivers'],
      exact: true,
      private: true
    },
    {
      path: "/services/drivers/edit/:id",
      component: DriversEdit,
      permissions: ['edit_drivers'],
      exact: true,
      private: true
    },
    {
      path: "/services/billings",
      component: Billings,
      permissions: ['list_billings'],
      exact: true,
      private: true
    },
    {
      path: "/services/billings/edit/:id",
      component: BillingsEdit,
      permissions: ['edit_billings'],
      exact: true,
      private: true
    },
    {
      path: "/billing-pdf-hauling-waste",
      component: BillingPDFHaulingWaste,
      permissions: [],
      exact: true,
      private: true
    },
    {
      path: "/billing-pdf-quarry",
      component: BillingPDFQuarry,
      permissions: [],
      exact: true,
      private: true
    },
    {
      path: "/billing-pdf-quarry-by-partner",
      component: BillingPDFQuarryByPartner,
      permissions: [],
      exact: true,
      private: true
    },
    {
      path: "/reports/expense-report",
      component: ReportsExpenses,
      permissions: ['view_expense_report'],
      exact: true,
      private: true
    },
    {
      path: "/reports/purchase-report",
      component: ReportsPurchases,
      permissions: ['view_purchase_report'],
      exact: true,
      private: true
    },
    {
      path: "/reports/sale-report",
      component: ReportsSales,
      permissions: ['view_sale_report'],
      exact: true,
      private: true
    },
    {
      path: "/reports/product-report",
      component: ReportsProducts,
      permissions: ['view_product_report'],
      exact: true,
      private: true
    },
    {
      path: "/cash-on-hand",
      component: CashOnHand,
      permissions: ['list_cash_on_hand'],
      exact: true,
      private: true
    },
    {
      path: "/cash-on-hand-pdf",
      component: CashOnHandPDF,
      permissions: [],
      exact: true,
      private: true
    },
    {
      path: "/documents",
      component: Documents,
      permissions: ['list_documents'],
      exact: true,
      private: true
    },
    {
      path: "/documents/edit/:id",
      component: DocumentsEdit,
      permissions: ['create_documents', 'edit_documents'],
      exact: true,
      private: true
    },
    {
      path: "/pullouts",
      component: Pullouts,
      permissions: ['list_pullouts'],
      exact: true,
      private: true
    },
    {
      path: "/pullouts/edit/:id",
      component: PulloutsEdit,
      permissions: ['create_pullouts', 'edit_pullouts'],
      exact: true,
      private: true
    },
    {
      path: "/payables",
      component: Payables,
      permissions: ['list_payables'],
      exact: true,
      private: true
    },
    {
      path: "/payables/edit/:id",
      component: PayablesEdit,
      permissions: ['create_payables', 'edit_payables'],
      exact: true,
      private: true
    },
  ]
};

const actions = {
  isPrivate: (store, path) => {
    let res = store.state.routes.filter(route => route.path === path && route.private);
    return res.length > 0;
  },
  isFound: (store, path) => {
    let res = store.state.routes.filter(route => route.path === path);
    return res.length > 0;
  },
};

const useRoute = globalHook(React, state, actions);

export default useRoute;
