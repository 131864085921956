import React from 'react';
import globalHook from 'use-global-hook';

const initialState = {
  loading: false,
  errors: null,
  isSaving: false,
  listCount: 0,
  lastParams: "",
  listData: [],
  listAll: [],
  data: {
    id: "",
    branch_id: "",
    user_id: "",
    name: "",
  },
};

const state = JSON.parse(JSON.stringify(initialState));

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  resetData: (store) => {
    store.setState({
      loading: false,
      errors: null,
      data: JSON.parse(JSON.stringify(initialState.data))
    });
  },
  fetchAll: async (store, branch_id = "") => {
    store.setState({ loading: true });
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/fetch-raw-products?branch_id=${branch_id}`
      });
      store.actions.setState({
        listAll: data.data,
        loading: false
      });
    }
    catch (error) {
      console.log(error);
      store.setState({ loading: false });
    }
  },
  fetchList: async (store, queryParams = null, filters = null) => {
    store.setState({ loading: true });

    try {
      let params = "";

      if(!queryParams) {
        params = store.state.lastParams;
      } else {
        params = `?page=${queryParams.pageNumber}
          &pageSize=${queryParams.pageSize}
          &sortField=${queryParams.sortField}
          &sortOrder=${queryParams.sortOrder}
          &searchField=${filters.searchField}
          &searchText=${filters.searchText}
          &branch_id=${filters.branch_id !== "all" ? filters.branch_id : ""}`;
      }
      
      let { data } = await global.axios({
        method: "GET",
        url: `/api/raw-products${params}`
      });

      store.setState({
        loading: false,
        listData: data.data,
        listCount: data.meta.total,
        lastParams: params
      });
    }
    catch (error) {
      store.setState({ loading: false });
      console.log(error);
    }    
  },
  show: async (store, id) => {
    store.setState({ loading: true });
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/raw-products/${id}`
      });
      store.actions.setState({
        data: data.data,
        loading: false
      });
    }
    catch (error) {
      console.log(error);
      store.setState({ loading: false });
    }
  },
  save: async (store) => {
    store.setState({
      isSaving: true,
      errors: null
    });

    try {
      let payload = JSON.parse(JSON.stringify(store.state.data));

      let url = `/api/raw-products`;
      if(payload.id) {
        url += `/${payload.id}`;
        payload['_method'] = 'PUT';
      }

      let { data } = await global.axios.post(url, payload);
      store.actions.setState({
        isSaving: false,
        data: data.data
      });

      return data.data;
    }
    catch ({ response }) {
      if(response.status === 422) {
        store.setState({
          isSaving: false,
          errors: response.data.errors
        });
        return false;
      }
    }
  },
  delete: async (store, id) => {
    try {
      await global.axios({
        method: "DELETE",
        url: `/api/raw-products/${id}`
      });

      store.actions.fetchList();
    }
    catch (error) {
      console.log(error);
    }
  },
};

const useRawProduct = globalHook(React, state, actions);

export default useRawProduct;
