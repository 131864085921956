import React from 'react';
import globalHook from 'use-global-hook';
import moment from "moment";
import {myBranchId} from "~/app/helpers/user";

const from_stat_options = [
  {value: "preparing", label: "Preparing"},
  {value: "on-the-way", label: "On the way"},
]

const to_stat_options = [
  {value: "on-the-way", label: "On the way"},
  {value: "received", label: "Received"},
  {value: "completed", label: "Completed"},
]

const admin_stat_options = [
  {value: "preparing", label: "Preparing"},
  {value: "on-the-way", label: "On the way"},
  {value: "received", label: "Received"},
  {value: "completed", label: "Completed"},
]

const initItem = {
  id: "",
  transfer_stock_id: "",
  from_variant_id: "",
  to_variant_id: "",
  quantity: "",
  product_data: null,
  to_product_data: null
}

const my_branch_id = myBranchId()

const initialState = {
  loading: false,
  isSaving: false,
  errors: null,
  success: false,
  listCount: 0,
  lastParams: "",
  listData: [],
  data: {
    id: "",
    t_date: new Date(),
    from_branch_id: "",
    to_branch_id: "",
    created_by_id: "",
    received_by_id: "",
    status: "preparing",
    items: [JSON.parse(JSON.stringify(initItem))]
  },
  initItem: JSON.parse(JSON.stringify(initItem)),
  from_stat_options: from_stat_options,
  to_stat_options: to_stat_options,
  admin_stat_options: admin_stat_options
};

const state = JSON.parse(JSON.stringify(initialState));

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  resetData: (store) => {
    store.setState({
      loading: false,
      errors: null,
      isSaving: false,
      data: JSON.parse(JSON.stringify(initialState.data))
    });
  },
  fetchList: async (store, queryParams = {}, filters = {}) => {
    store.setState({ loading: true });

    try {
      let params = "";

      if(Object.keys(queryParams).length === 0) {
        params = store.state.lastParams;
      } else {
        params = `?page=${queryParams.pageNumber}
          &pageSize=${queryParams.pageSize}
          &sortField=${queryParams.sortField}
          &sortOrder=${queryParams.sortOrder}
          &searchField=${filters.searchField}
          &searchText=${filters.searchText}`;
      }
      
      let { data } = await global.axios({
        method: "GET",
        url: `/api/transfer-stocks${params}`
      });

      store.setState({
        loading: false,
        listData: data.data,
        listCount: data.meta.total,
        lastParams: params
      });
    }
    catch (error) {
      store.setState({ loading: false });
      console.log(error);
    }    
  },
  show: async (store, id) => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/transfer-stocks/${id}`
      });

      let items = data.data.items

      if(!["received", "completed"].includes(data.data.status)
          && data.data.from_branch_id === my_branch_id
      ) {
        items.push(store.state.initItem)
      }

      let newData = {
        ...data.data,
        items: data.data.items
      }

      store.actions.setState({ data: newData });

      return newData
    }
    catch (error) {
      console.log(error);
    }
  },
  save: async (store, status) => {
    store.setState({
      loading: true,
      isSaving: true,
      errors: null,
      success: false
    });

    try {
      let payload = JSON.parse(JSON.stringify(store.state.data));
      payload.t_date = moment(payload.t_date).format("YYYY-MM-DD")
      payload.items = payload.items.filter(item => item.from_variant_id && item.quantity)

      let url = ""

      if(payload.to_branch_id === my_branch_id && status === "received") {
        url = `/api/map-transfer-stocks/${payload.id}`;
        payload['_method'] = 'PUT';
      } else {
        url = `/api/transfer-stocks`;

        if(payload.id) {
          url += `/${payload.id}`;
          payload['_method'] = 'PUT';
        }
      }

      let { data } = await global.axios.post(url, payload);

      let newData = {
        ...store.state.data,
        id: data.data.id
      }

      store.setState({
        data: newData,
        loading: false,
        isSaving: false,
        success: true
      })

      store.actions.fetchList();

      return newData;
    }
    catch ({ response }) {
      if(response.status === 422) {
        store.setState({
          loading: false,
          isSaving: false,
          errors: response.data.errors
        });
        return false;
      }
    }
  },
  delete: async (store, id) => {
    try {
      await global.axios({
        method: "DELETE",
        url: `/api/transfer-stocks/${id}`
      });

      store.actions.fetchList();
    }
    catch (error) {
      console.log(error);
    }
  },
};

const useTransferStock = globalHook(React, state, actions);

export default useTransferStock;
