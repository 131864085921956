import React, { memo } from "react";
import { makeStyles } from '@material-ui/core/styles';
import {NavLink} from "react-router-dom";
import {BorderColorRounded, VisibilityRounded} from '@material-ui/icons';
import {Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip} from "@material-ui/core";
import {green} from "@material-ui/core/colors";
import { CustomDialog } from "~components";
import {formatNumber} from "~/app/helpers/format_functions";
import moment from "moment";
import {useAuth} from "~/store";
import {isAdmin} from "~/app/helpers/user";

const useStyles = makeStyles({
  table: {
    minWidth: "100%",
    marginTop: "25px",

    '& th': {
      fontWeight: 600,
      color: "#7E8299 !important",
      borderBottom: "1px solid #a5a0a0",
    },
    '& td': {
      verticalAlign: "baseline",
      borderBottom: "1px solid #a5a0a0",
    },
    '& .MuiTableCell-root': {
      padding: '8px 4px'
    },
  },
});

const DetailDialog = ({ row }) => {
  const [, authActions] = useAuth()
  return(
    <CustomDialog
      maxWidth={"sm"}
      title={"Purchase Details"}
      triggerButton={
        <Tooltip arrow title={"View Details"}>
          <Button size={"small"} color={"secondary"} className={"min-w-auto p-1"}>
            <VisibilityRounded style={{color: green[600]}} />
          </Button>
        </Tooltip>
      }
      actionButtons={!authActions.hasPermission(['edit_purchases']) ? <></> :
        <NavLink to={`/purchases/edit/${row.id}`}>
          <Button variant={"contained"} size={"small"} color={"primary"}>
            <BorderColorRounded fontSize={"small"} className={"mr-2"} />
            Update
          </Button>
        </NavLink>
      }
    >
      <Content row={row} />
    </CustomDialog>
  )
}

const Content = ({ row }) => {
  const classes = useStyles()

  let total = 0
  for(let item of row.items) {
    total += item.quantity * item.unit_price
  }

  return(
     <div className={"w-100 px-2"}>
       <div className={"w-100 d-flex justify-content-between"}>
         <div className={"flex-grow-1"}>
           {isAdmin() && (
             <div className={"w-100 d-flex flex-row align-items-baseline"}>
               <div className={"font-weight-bold font-size-xs text-dark-50 pr-2"}>Branch:</div>
               <div className={"font-weight-bold font-size-lg"}>{row.branch ? row.branch.name : ""}</div>
             </div>
           )}
           <div className={"w-100 d-flex flex-row align-items-baseline mt-1"}>
             <div className={"font-weight-bold font-size-xs text-dark-50 pr-2"}>REFERENCE NO:</div>
             <div className={"font-weight-bold font-size-lg"}>{row.reference_no}</div>
           </div>
           <div className={"w-100 d-flex flex-row align-items-baseline mt-1"}>
             <div className={"font-weight-bold font-size-xs text-dark-50 pr-2"}>SUPPLIER:</div>
             <div className={"font-weight-bold font-size-lg"}>{row.hasOwnProperty('company') ? row.company.name : ""}</div>
           </div>
           {row.company && (
             <div className={"w-100 border rounded px-2 py-3 mt-2"} style={{fontFamily: "monospace"}}>
               <div>TIN: {" "}{row.company.tin}</div>
               <div>Address: {" "}{row.company.address}</div>
             </div>
           )}
         </div>
         <div className={"min-w-130px"}>
           <div className={"d-flex flex-row align-items-baseline justify-content-end"}>
             <div className={"font-weight-bold font-size-xs text-dark-50 pr-2"}>DATE:</div>
             <div className={"font-weight-bold font-size-lg"}>
               {row.date ? moment(row.date).format("YYYY-MM-DD") : ""}
             </div>
           </div>
         </div>
       </div>
       <div className={"w-100"}>
         <TableContainer className={classes.table}>
           <Table>
             <TableHead>
               <TableRow>
                 <TableCell>ITEM NAME</TableCell>
                 <TableCell className={'text-center'} style={{width: '90px'}}>QUANTITY</TableCell>
                 <TableCell className={'text-center'}>UNIT</TableCell>
                 <TableCell className={'text-right'} style={{width: '20%'}}>UNIT AMOUNT</TableCell>
                 <TableCell className={'text-right'} style={{width: '15%'}}>AMOUNT</TableCell>
               </TableRow>
             </TableHead>
             <TableBody>
               {row.items.map((item, index) => {
                 return(
                   <TableRow key={index}>
                     <TableCell>
                       {item.product}
                     </TableCell>
                     <TableCell className={'text-center'}>
                       {item.quantity}
                     </TableCell>
                     <TableCell className={'text-center'}>
                       {item.unit}
                     </TableCell>
                     <TableCell className={'text-right'}>
                       {formatNumber(item.unit_price)}
                     </TableCell>
                     <TableCell className={'text-right font-weight-bolder'}>
                       {item.unit_price && item.quantity ? formatNumber(item.unit_price * item.quantity) : ""}
                     </TableCell>
                   </TableRow>
                 )
               })}
               <TableRow>
                 <TableCell colSpan={4} className={"text-right text-dark-50 font-weight-bolder border-0"}>
                   TOTAL AMOUNT:
                 </TableCell>
                 <TableCell className={"font-size-h4 font-weight-bolder text-right border-0"}>
                   {formatNumber(total)}
                 </TableCell>
               </TableRow>
             </TableBody>
           </Table>
         </TableContainer>
       </div>
     </div>
  )
}

export default memo(DetailDialog)
