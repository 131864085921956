import React from 'react';
import globalHook from 'use-global-hook';
import moment from 'moment';

const initItem = {
  id: "",
  expense_id: "",
  name: "",
  amount: "",
  quantity: "1",
}

const initialState = {
  loading: false,
  isSaving: false,
  errors: null,
  listCount: 0,
  lastParams: "",
  expenses: [],
  expense: {
    id: "",
    reference_no: "",
    user_id: "",
    user: null,
    company_id: "",
    branch_id: "",
    company: null,
    date: moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD"),
    items: [JSON.parse(JSON.stringify(initItem))],
  },
  initItem: JSON.parse(JSON.stringify(initItem)),
};

const state = JSON.parse(JSON.stringify(initialState));

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  resetExpense: (store) => {
    store.setState({
      loading: true,
      errors: null,
      expense: JSON.parse(JSON.stringify(initialState.expense))
    });
  },
  fetchExpenses: async (store, queryParams = null, filters = null) => {
    store.setState({ loading: true });

    try {
      let params = "";

      if(!queryParams) {
        params = store.state.lastParams;
      } else {
        params = `?page=${queryParams.hasOwnProperty('pageNumber') ? queryParams.pageNumber : ""}
          &pageSize=${queryParams.hasOwnProperty('pageSize') ? queryParams.pageSize : ""}
          &sortField=${queryParams.hasOwnProperty('sortField') ? queryParams.sortField : ""}
          &sortOrder=${queryParams.hasOwnProperty('sortOrder') ? queryParams.sortOrder : ""}
          &searchText=${filters.searchText}
          &searchField=${filters.searchField !== "all" ? filters.searchField : "" }
          &branch_id=${filters.branch_id !== "all" ? filters.branch_id : "" }`;
      }

      let { data } = await global.axios({
        method: "GET",
        url: `/api/expenses${params}`
      });

      store.setState({
        loading: false,
        expenses: data.data,
        listCount: data.meta.total,
        lastParams: params
      });
    }
    catch (error) {
      store.setState({ loading: false });
      console.log(error);
    }
  },
  fetchExpense: async (store, expense_id) => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/expenses/${expense_id}`
      });
      store.actions.setState({
        expense: {
          ...data.data,
          items: [
            ...data.data.items,
            JSON.parse(JSON.stringify(initItem))
          ]
        }
      });
    }
    catch (error) {
      console.log(error);
    }
  },
  saveExpense: async (store, history) => {
    store.setState({
      isSaving: true,
      errors: null
    });

    try {
      let payload = JSON.parse(JSON.stringify(store.state.expense));

      payload.items = payload.items.filter(item => item.name && item.quantity && item.amount)
      payload.user_id = payload.user ? payload.user.id : ""
      payload.company_id = payload.company ? payload.company.id : ""

      delete payload.user
      delete payload.company

      let url = `/api/expenses`;

      if(payload.id) {
        url += `/${payload.id}`;
        payload['_method'] = 'PUT';
      }

      let { data } = await global.axios.post(url, payload);
      store.actions.setState({
        isSaving: false,
        expense: {
          ...data.data,
          items: [
            ...data.data.items,
            JSON.parse(JSON.stringify(initItem))
          ]
        }
      });

      if(!payload.id) {
        history.push(`/expenses/edit/${data.data.id}`);
      }

      return data.data;
    }
    catch ({ response }) {
      if(response.status === 422) {
        store.setState({
          isSaving: false,
          errors: response.data.errors
        });
        return false;
      }
    }
  },
  deleteExpense: async (store, expense_id) => {
    try {
      await global.axios({
        method: "DELETE",
        url: `/api/expenses/${expense_id}`
      });

      store.actions.fetchExpenses();

    }
    catch (error) {
      console.log(error);
    }
  },
  hasItems: (store) => {
    let items = store.state.expense.items.filter(item => item.name && item.quantity && item.amount)
    return items.length > 0
  }
};

const useExpense = globalHook(React, state, actions);

export default useExpense;
