import React, { memo } from "react";
import {NavLink} from "react-router-dom";
import {BorderColorRounded, VisibilityRounded} from '@material-ui/icons';
import {Button, Tooltip} from "@material-ui/core";
import {green} from "@material-ui/core/colors";
import { CustomDialog } from "~components";
import {hasPermissions} from "~app/helpers/user";
import {service_types} from "~app/helpers/data";
import {formatNumber} from "~/app/helpers/format_functions";

const DetailDialog = ({ row }) => {
  return(
    <CustomDialog
      maxWidth={"xs"}
      title={"Transaction Details"}
      triggerButton={
        <Tooltip arrow title={"View Details"}>
          <Button size={"small"} color={"secondary"} className={"min-w-auto p-1"}>
            <VisibilityRounded style={{color: green[600]}} />
          </Button>
        </Tooltip>
      }
      actionButtons={!hasPermissions(['edit_services']) ? <></> :
        <NavLink to={`/services/list/edit/${row.id}`}>
          <Button variant={"contained"} size={"small"} color={"primary"}>
            <BorderColorRounded fontSize={"small"} className={"mr-2"} />
            Update
          </Button>
        </NavLink>
      }
    >
      <Content row={row} />
    </CustomDialog>
  )
}

const data  = [
  {id: "user_name", label: "Created By"},
  {id: "branch_name", label: "Branch"},
  {id: "s_date", label: "Date"},
  {id: "company_name", label: "Client"},
  {id: "service_type", label: "Service Type"},
  {id: "description", label: "Description"},
]

const sub_data = [
  {id: 'ticket_number', label: 'Ticket Number'},
  {id: 'vehicle_id', label: 'Plate Number'},
  {id: 'driver_id', label: 'Driver'},
  {id: 'trip_from', label: 'Trip From'},
  {id: 'trip_to', label: 'Trip To'},
  {id: 'weight', label: 'Weight in tons'},
  {id: 'site', label: 'Site'},
  {id: 'approved_by', label: 'Approved By'},
  {id: 'date_from', label: 'From Date'},
  {id: 'date_to', label: 'To Date'},
  {id: 'charge', label: 'Charge / Cost'},
]

const Content = ({ row }) => {

  let service_type = ""
  let types = service_types.filter(item => item.id === row.service_type)
  if(types.length > 0) {
    service_type = types[0].name
  }

  return(
    <div className={"w-100 max-w-325px px-5 d-flex flex-center flex-column mx-auto"}>
      <div className={"w-100"}>
        {data.map(item => {
          return (
            <div className={"d-flex flex-row align-items-baseline py-1"}>
               <div className={"font-weight-bold text-dark-50 w-120px"}>{item.label}:</div>
               <div className={"font-weight-bold font-size-lg flex-grow-1"}>{item.id === "service_type" ? service_type : row[item.id]}</div>
            </div>
          )
        })}
      </div>
      <div className={"w-100 border-top mt-4 pt-3 border-light-dark "}>
        {sub_data.map((item, i) => {
          if(row.data.hasOwnProperty(item.id)) {
            let value = row.data[item.id]
            if(item.id === "driver_id") {
              value = row.driver_name
            }
            if(item.id === "vehicle_id") {
              value = row.plate_number
              value += row.partner_name ? ` | ${row.partner_name.toUpperCase()}` : ""
            }
            if(item.id === "charge") {
              value = formatNumber(row.data.charge)
            }
            return (
              <div key={i} className={"d-flex flex-row align-items-baseline py-1"}>
                <div className={"font-weight-bold text-dark-50 w-120px"}>{item.label}:</div>
                <div className={"font-weight-bold font-size-lg flex-grow-1"}>{value}</div>
              </div>
            )
          }
        })}
      </div>
      {row.data.hasOwnProperty('expenses') && (
        <div className={"w-100 border-top mt-4 pt-3 border-light-dark "}>
          <div className={"font-weight-bold text-dark-50"}>EXPENSES</div>
          {row.data.expenses.map((item, i) => {
            return(
              <div key={i} className={"d-flex flex-row align-items-baseline py-1"}>
                <div className={"font-weight-bold w-120px px-5 text-right"}>{item.description}:</div>
                <div className={"font-weight-bold font-size-lg flex-grow-1"}>{formatNumber(item.amount)}</div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default memo(DetailDialog)
