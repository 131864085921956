import React from 'react';
import { headerSortingClasses, sortCaret } from "~helpers";
import { 
  ActionsColumnFormatter,
  BalanceColumnFormatter,
  DateCreatedColumnFormatter
} from "~pages/Customers/components/column-formatters";
import globalHook from 'use-global-hook';

const columns = [
  {
    dataField: "id",
    text: "ID",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    style: { width: "70px" },
  },
  {
    dataField: "created_at",
    text: "Date Registered",
    formatter: DateCreatedColumnFormatter,
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    classes: "text-center",
    headerClasses: "text-center"
  },
  {
    dataField: "name",
    text: "Name",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "email",
    text: "Email",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "phone_number",
    text: "Contact Number",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "balance",
    text: "Balance",
    sort: true,
    formatter: BalanceColumnFormatter,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "text-right",
    classes: "text-right font-weight-bolder"
  },
  {
    dataField: "branch_name",
    text: "Branch",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "action",
    text: "Actions",
    formatter: ActionsColumnFormatter,
    classes: "text-right pr-0",
    headerClasses: "text-right pr-3",
    style: { minWidth: "125px" },
  },
];

const initialState = {
  columns: columns,
  defaultSorted: [{ dataField: "created_at", order: "desc" }],
  sizePerPageList: [
    { text: "10", value: 10 },
    { text: "20", value: 20 },
    { text: "50", value: 50 },
    { text: "100", value: 100 }
  ],
  queryParams: {
    sortOrder: "desc",
    sortField: "created_at",
    pageNumber: 1,
    pageSize: 20,
  },
  filter: {
    searchText: "",
    branch_id: "all"
  },
};

const state = initialState;

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  }
};

const useCustomerList = globalHook(React, state, actions);

export default useCustomerList;
