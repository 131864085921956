import React from "react";
import { green } from "@material-ui/core/colors";

const StatusColumnFormatter = (
  cellContent,
  row
) => {
  return (
    <>
      {row.billing_id && (
        <span style={{ color: green[600], border: `1px solid ${green[600]}` }} className={"px-2 py-1 rounded-sm"}>
          {row.billing_id ? "Billed" : ""}
        </span>
      )}
    </>

  )
}

export default StatusColumnFormatter;
