import React, { memo } from "react";
import {NavLink} from "react-router-dom";
import {BorderColorRounded, VisibilityRounded} from '@material-ui/icons';
import {Button, Tooltip} from "@material-ui/core";
import {green} from "@material-ui/core/colors";
import { CustomDialog } from "~components";
import Details from "../../UserManagementEdit/components/Details"
import {useAuth} from "~store";

const DetailDialog = ({ row }) => {
  const [, authActions] = useAuth()
  return(
    <CustomDialog
      maxWidth={"sm"}
      title={"User Details"}
      triggerButton={
        <Tooltip arrow title={"View Details"}>
          <Button size={"small"} color={"secondary"} className={"min-w-auto p-1"}>
            <VisibilityRounded style={{color: green[600]}} />
          </Button>
        </Tooltip>
      }
      actionButtons={!authActions.hasPermission(['edit_users']) ? <></> :
        <NavLink to={`/management/users/edit/${row.id}`}>
          <Button variant={"contained"} size={"small"} color={"primary"}>
            <BorderColorRounded fontSize={"small"} className={"mr-2"} />
            Update
          </Button>
        </NavLink>
      }
    >
      <Details row={row} />
    </CustomDialog>
  )
}
export default memo(DetailDialog)
