import React, {useState} from "react";
import {Button, Tooltip} from "@material-ui/core";
import {DeleteOutlineRounded} from "@material-ui/icons";
import { CustomDialog } from "~components";
import DetailDialog from "../DetailDialog";
import EditDialog from "../EditDialog";
import {hasPermissions} from "~/app/helpers/user";
import { useTransferStock } from "~store";

const ActionsColumnFormatter = (cellContent, row) => {
  return <Actions row={row} />
}

const Actions = ({ row }) => {
  return(
    <div>
      {hasPermissions(['view_transfer_stocks']) && <DetailDialog row={row} />}
      {hasPermissions(['edit_transfer_stocks']) && (
              <Tooltip arrow title={"Edit"}>
                <EditDialog id={row.id} />
              </Tooltip>
      )}
      {hasPermissions(['delete_transfer_stocks']) && <DeleteButton row={row} />}
    </div>
  )
}

const DeleteButton = ({ row }) => {
  const [, actions] = useTransferStock();
  const [triggerClose, setTriggerClose] = useState(false);
  const [message, setMessage] = useState("");
  const [subMessage, setSubMessage] = useState("");

  const handleDelete = async (id) => {
    setMessage("Deleting ...");

    if(row.status !== "completed") {
      await actions.delete(id);
      setMessage("Successfully deleted.");
    } else {
      setMessage("Failed to deleted.");
      setSubMessage("When a transaction is completed, it typically becomes a permanent record that cannot be deleted to ensure the integrity of transaction history.");
    }

    setTimeout(
      function() {
        setTriggerClose(true);
      }, 2500
    );
    setTimeout(
    function() {
      setTriggerClose(false);
      }, 3000
    );
  }

  return(
    <CustomDialog
      title={""}
      dividers={false}
      maxWidth={"sm"}
      triggerCloseButton={triggerClose}
      triggerButton={
        <Tooltip arrow title={"Delete"}>
          <Button size={"small"} color={"secondary"} className={"min-w-auto p-1"}>
            <DeleteOutlineRounded />
          </Button>
        </Tooltip>
      }
      actionButtons={!message ?
        <Button  className={"btn btn-danger btn-elevate"}
          onClick={() => handleDelete(row.id)}>
          Yes
        </Button> : ""
      }
    >
      <div className={"font-size-h3 w-100 text-center py-10"}>
        {!message && <>
            Are you sure you want to delete record?
          </>
        }
        {message && message}
        {subMessage && <div className={"text-center font-size-sm"}>
          {subMessage}
        </div>}
      </div>
    </CustomDialog>
  )
}

export default ActionsColumnFormatter;
