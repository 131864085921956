import React from "react";
import {Chip} from "@material-ui/core";
import {green, red} from "@material-ui/core/colors";

const colors = {
  unpaid: red[600],
  paid: green[600],
}

const StatusColumnFormatter = (
  cellContent,
  row
) => {
  return (
    <Chip
      size={"small"}
      label={row.status}
      variant={"outlined"}
      className={"text-uppercase font-weight-bold"}
      style={{ borderColor: colors[row.status], color: colors[row.status] }}
    />
  )
}

export default StatusColumnFormatter;
