import React from 'react';
import globalHook from 'use-global-hook';
import moment from "moment/moment";

const current_date = new Date();
let start_date = new Date(current_date.getFullYear(), current_date.getMonth() , 1)
let end_date = new Date()
start_date = moment(start_date).format("YYYY-MM-DD")
end_date = moment(end_date).format("YYYY-MM-DD")

const allColumns = [
  {id: "reference_no", title: "Reference Number", order: 1, class: ""},
  {id: "customer_name", title: "Customer Name", order: 2, class: ""},
  {id: "product", title: "Product", order: 3, class: ""},
  {id: "quantity", title: "Quantity", order: 4, class: "", decimal: 0},
  {id: "unit", title: "Unit", order: 5, class: ""},
  {id: "unit_price", title: "Unit Price", order: 6, class: "text-right", decimal: 2},
  {id: "amount", title: "Total", order: 7, class: "text-right", decimal: 2},
]

const initDaily = {
  data: [],
  amount: 0,
  date: moment(new Date()).format("YYYY-MM-DD"),
  allColumns: JSON.parse(JSON.stringify(allColumns)),
  columns: JSON.parse(JSON.stringify(allColumns)),
  customers: [],
  customer: null,
  date_filter_by: {label: "Date", value: "date"},
  date_start: start_date,
  date_end: end_date,
  export_data: []
}
const initMonthly = {
  data: [],
  amount: 0,
  month: current_date.getMonth() + 1,
  year: current_date.getFullYear()
}
const initYearly = {
  data: [],
  amount: 0,
  year: new Date().getFullYear()
}

const state = {
  isLoading: false,
  dateFilterByOptions: [
    {label: "Date", value: "date"},
    {label: "Date Range", value: "date_range"},
  ],
  daily: initDaily,
  monthly: initMonthly,
  yearly: initYearly,
}

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  fetchSales: async (store, type, modified_field = "") => {
    store.setState({isLoading: true})
    try {
      let url = `/api/fetch-sale-report?type=${type}
        &date_filter_by=${store.state.daily.date_filter_by.value}
        &date=${store.state.daily.date}
        &date_start=${store.state.daily.date_start}
        &date_end=${store.state.daily.date_end}
        &customer_id=${store.state.daily.customer ? store.state.daily.customer.id : ""}
        &month=${store.state.monthly.month}
        &year=${store.state[`${type}`].year}`

      let { data } = await global.axios({
        method: "GET",
        url: url
      });

      if(type === "daily") {
        store.actions.processDailyData(data, modified_field)
      } else {
        store.actions.setState({
          [`${type}`]: {...store.state[`${type}`], ...data},
          isLoading: false
        })
      }

      return data
    }
    catch (error) {
      console.log(error)
      store.setState({isLoading: false})
      return false
    }
  },
  processDailyData: (store, data, modified_field) => {
    let customers = [],
      export_data = []
    let report_data = JSON.parse(JSON.stringify(data.data))

    for(let item of report_data) {
      if(customers.filter(customer => customer.id === item.customer.id).length === 0) {
        customers.push(item.customer)
      }
      for(let column of store.state.daily.columns) {
        export_data.push({
          [column.title]: report_data[column.id]
        })
      }
    }

    store.actions.setState({
      isLoading: false,
      daily: {
        ...store.state["daily"],
        ...data,
        customers: modified_field !== "customer" ? customers : store.state.daily.customers,
        export_data: export_data,
      }
    })
  }
};

const useReportSales = globalHook(React, state, actions);

export default useReportSales;
