import React from 'react';
import globalHook from 'use-global-hook';

const initialState = {
  loading: false,
  isSaving: false,
  errors: null,
  listCount: 0,
  lastParams: "",
  all_companies: [],
  companies: [],
  company: {
    id: "",
    name: "",
    vat_type: "",
    tin: "",
    email: "",
    contact_no: "",
    contact_person: "",
    address: "",
    notes: "",
    branch_id: "",
  },
};

const state = JSON.parse(JSON.stringify(initialState));

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  resetCompany: (store) => {
    store.setState({
      loading: false,
      isSaving: false,
      errors: null,
      company: JSON.parse(JSON.stringify(initialState.company))
    });
  },
  fetchAll: async (store, branch_id = "") => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/fetch-companies?branch_id=${branch_id}`
      });
      store.actions.setState({ all_companies: data.data });
    }
    catch (error) {
      console.log(error);
    }
  },
  fetchList: async (store, queryParams = null, filters = null) => {
    store.setState({ loading: true });

    try {
      let params = "";

      if(!queryParams) {
        params = store.state.lastParams;
      } else {
        params = `?page=${queryParams.pageNumber}
          &pageSize=${queryParams.pageSize}
          &sortField=${queryParams.sortField}
          &sortOrder=${queryParams.sortOrder}
          &searchText=${filters.searchText}
          &searchField=${filters.searchField}
          &branch_id=${filters.branch_id !== "all" ? filters.branch_id : ""}`;
      }
      
      let { data } = await global.axios({
        method: "GET",
        url: `/api/companies${params}`
      });

      store.setState({
        loading: false,
        companies: data.data,
        listCount: data.meta.total,
        lastParams: params
      });
    }
    catch (error) {
      store.setState({ loading: false });
      console.log(error);
    }    
  },
  show: async (store, id) => {
    store.setState({
      loading: true,
      errors: null
    });
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/companies/${id}`
      });
      store.actions.setState({
        company: data.data,
        loading: false
      });
    }
    catch (error) {
      console.log(error);
      store.actions.setState({ loading: false });
    }
  },
  save: async (store, history = null) => {
    store.setState({
      isSaving: true,
      errors: null
    });

    try {
      let id = store.state.company.id
      let payload = store.state.company;
      let url = `/api/companies`;

      if(payload.id) {
        url += `/${payload.id}`;
        payload['_method'] = 'PUT';
      }

      let { data } = await global.axios.post(url, payload);
      store.actions.setState({ isSaving: false, company: data.data });

      if(id === "" && history) {
        history.push(`/suppliers/edit/${data.data.id}`);
      }

      return data.data;
    }
    catch ({ response }) {
      if(response.status === 422) {
        store.setState({
          isSaving: false,
          errors: response.data.errors
        });
        return false;
      }
    }
  },
  delete: async (store, id) => {
    try {
      await global.axios({
        method: "DELETE",
        url: `/api/companies/${id}`
      });

      store.actions.fetchList();

    }
    catch (error) {
      console.log(error);
    }
  },
};

const useCompany = globalHook(React, state, actions);

export default useCompany;
