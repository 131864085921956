import React, {useEffect, useState} from "react"
import CustomSelectField from "./CustomSelectField"

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
]

const DateYearPicker = ({
   className= "",
   label = "YEAR",
   value = new Date().getFullYear(),
   handleChange
}) => {
  const [options, setOptions] = useState([])
  const [selected, setSelected] = useState(value)
  useEffect(() => {
    let currentYear = new Date().getFullYear()
    let temp = []
    for(let i = currentYear; i >= 2020; i--) {
      temp.push({label: i, value: i})
    }
    setOptions(temp)
  }, [])
  const handleOnChange = (newValue) => {
    setSelected(newValue)
    handleChange(newValue)
  }
  return(
    <CustomSelectField
      className={className}
      label={label}
      options={options}
      value={selected}
      onChange={(event) => handleOnChange(event.target.value)}
    />
  )
}
export default DateYearPicker
