import React from 'react';
import globalHook from 'use-global-hook';
import moment from "moment/moment";
import TrackingReport from "~/app/pages/Reports/products/components/TrackingReport";
import TrackingReport2 from "~/app/pages/Reports/products/components/TrackingReport2";

const current_date = new Date();
let start_date = new Date(current_date.getFullYear(), current_date.getMonth(), 1)
let end_date = new Date()
start_date = moment(start_date).format("YYYY-MM-DD")
end_date = moment(end_date).format("YYYY-MM-DD")

const tabs = [
  {label: "Tracking", component: <TrackingReport />},
  {label: "Tracking2", component: <TrackingReport2 />},
]

const state = {
  tabs: tabs,
  selectedTab: 0,

  isLoading: false,

  dateFilterByOptions: [
    {label: "Date", value: "date"},
    {label: "Date Range", value: "date_range"},
  ],

  tracking: {
    list_data: [],
    company: null,
    products: [],
    date_start: start_date,
    date_end: end_date,
  },

  tracking_data: [],
  tracking_old_stocks: 0,
  tracking_product: null,
  tracking_date_start: start_date,
  tracking_date_end: end_date,
  tracking_products: [],
  tracking_count_ins: [],
  tracking_count_outs: [],

}

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  fetchTracking: async (store) => {
    if(!store.state.tracking.company) return

    store.setState({isLoading: true})
    try {
      let url = `/api/fetch-tracked-sales
        ?company_id=${store.state.tracking.company.id}
        &date_start=${store.state.tracking.date_start}
        &date_end=${store.state.tracking.date_end}`

      if(store.state.tracking.products.length) {
        let product_ids = []
        for(let item of store.state.tracking.products) {
          product_ids.push(item.id)
        }
        url += `&product_ids=${product_ids}`
      }

      let { data } = await global.axios({
        method: "GET",
        url: url
      });

      store.actions.setState({
        tracking: {
          ...store.state.tracking,
          list_data: data
        },
        isLoading: false
      })

      return data
    }
    catch (error) {
      console.log(error)
      store.setState({isLoading: false})
      return false
    }
  },
  fetchTrackingFilters: async (store) => {
    store.setState({isLoading: true})
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/tracking-filters`
      });

      store.actions.setState({
        tracking_products: data.products,
        tracking_companies: data.companies,
        isLoading: false
      })

      return data
    }
    catch (error) {
      console.log(error)
      store.setState({isLoading: false})
      return false
    }
  },
  fetchProductTracking: async (store) => {
    if(!store.state.tracking_product) {
      store.actions.setState({ tracking_data: [] })
      return
    }

    store.setState({isLoading: true})
    try {
      let payload = JSON.parse(JSON.stringify(store.state));
      let url = `/api/tracking-product`
      url += `?product_id=${payload.tracking_product ? payload.tracking_product.id : ""}`
      url += `&date_start=${payload.tracking_date_start}&date_end=${payload.tracking_date_end}`

      let { data } = await global.axios({
        method: "GET",
        url: url
      });

      store.actions.setState({
        tracking_data: data.data,
        tracking_old_stocks: data.old_stocks,
        isLoading: false
      })

      return data
    }
    catch (error) {
      console.log(error)
      store.setState({isLoading: false})
      return false
    }
  },
  fetchDetails: async (store, date, type = "count_in") => {
    store.setState({isLoading: true})
    try {
      let payload = JSON.parse(JSON.stringify(store.state));
      let url = `/api/tracking-details`
      url += `?product_id=${payload.tracking_product ? payload.tracking_product.id : ""}`
      url += `&date=${date}`
      url += `&type=${type}`

      let { data } = await global.axios({ method: "GET", url: url });

      store.actions.setState({
        [`tracking_${type}s`]: data,
        isLoading: false
      })

      return data
    }
    catch (error) {
      console.log(error)
      store.setState({isLoading: false})
      return false
    }
  }
};

const useReportProducts = globalHook(React, state, actions);

export default useReportProducts;
