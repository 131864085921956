import React from 'react';
import globalHook from 'use-global-hook';
import {myBranchId} from "~app/helpers/user";

const initialState = {
  loading: false,
  isSaving: false,
  errors: null,
  success: false,
  listCount: 0,
  lastParams: "",
  listData: [],
  data: {
    id: "",
    branch_id: myBranchId(),
    user_id: "",
    company_id: "",
    company_area_id: "",
    title: null,
    description: null,
    date_from: null,
    date_to: null,
    amount: "",
    cr_number: "",
    status: "unpaid",
    paid_at: null,
    company: null
  },
  pulloutDetails: {
    data: [],
    total: 0
  }
};

const state = JSON.parse(JSON.stringify(initialState));

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  resetData: (store) => {
    store.setState({
      loading: false,
      errors: null,
      isSaving: false,
      data: JSON.parse(JSON.stringify(initialState.data)),
    });
  },
  fetchList: async (store, queryParams = {}, filters = {}) => {
    store.setState({ loading: true });

    try {
      let params = "";

      if(Object.keys(queryParams).length === 0) {
        params = store.state.lastParams;
      } else {
        params = `?page=${queryParams.pageNumber}
          &pageSize=${queryParams.pageSize}
          &sortField=${queryParams.sortField}
          &sortOrder=${queryParams.sortOrder}
          &searchField=${filters.searchField}
          &searchText=${filters.searchText}
          &branch_id=${filters.branch_id}`;
      }
      
      let { data } = await global.axios({
        method: "GET",
        url: `/api/payables${params}`
      });

      store.setState({
        loading: false,
        listData: data.data,
        listCount: data.meta.total,
        lastParams: params
      });
    }
    catch (error) {
      store.setState({ loading: false });
      console.log(error);
    }    
  },
  show: async (store, id) => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/payables/${id}`
      });

      store.actions.setState({ data: {...store.state.data, ...data.data} });

      return data.data
    }
    catch (error) {
      console.log(error);
    }
  },
  save: async (store, history) => {

    store.setState({
      loading: true,
      isSaving: true,
      errors: null,
      success: false
    });

    let payload = JSON.parse(JSON.stringify(store.state.data));

    try {
      let url = `/api/payables`;

      if(payload.id) {
        url += `/${payload.id}`;
        payload['_method'] = 'PUT';
      }

      let { data } = await global.axios.post(url, payload);

      store.setState({
        data: data.data,
        loading: false,
        isSaving: false,
        success: true
      })

      if(!payload.id) {
        history.push(`/payables/edit/${data.data.id}`);
      }

      return data;
    }
    catch ({ response }) {
      if(response.status === 422) {
        store.setState({
          loading: false,
          isSaving: false,
          errors: response.data.errors
        });
        return false;
      }
    }
  },
  delete: async (store, id) => {
    try {
      await global.axios({
        method: "DELETE",
        url: `/api/payables/${id}`
      });

      store.actions.fetchList();
    }
    catch (error) {
      console.log(error);
    }
  },
  fetchPayablePullouts: async (store) => {
    try {
      let params = `?company_id=${store.state.data.company_id}`
          params += `&company_area_id=${store.state.data.company_area_id}`
          params += `&date_from=${store.state.data.date_from}`
          params += `&date_to=${store.state.data.date_to}`

      let { data } = await global.axios({
        method: "GET",
        url: `/api/fetch-payable-pullouts${params}`
      });

      store.actions.setState({ pulloutDetails: data });

      return data.data
    }
    catch (error) {
      console.log(error);
    }
  },
};

const usePayable = globalHook(React, state, actions);

export default usePayable;
