import React from "react";
import {service_types} from "~app/helpers/data";

const ServiceTypeColumnFormatter = (
  cellContent,
  row
) => {
  let service_type = ""
  let type = service_types.filter(item => item.id === row.service_type)
  if(type.length > 0) {
    service_type = type[0].name
  }
  return (
    <span>
      {service_type}
    </span>
  )
}

export default ServiceTypeColumnFormatter;
