import React, {Fragment} from "react";
import {ExportToExcel} from "~components";
import ReorderColumns from "./ReorderColumns";
import FilterColumns from "./FilterColumns";
import FilterStaff from "./FilterStaff";
import FilterDate from "./FilterDate";
import FilterItems from "./FilterItems";
import {useReportExpenses} from "~store"

const DailyFilters = ({
  exportData,
  users,
  items,
  allColumns,
  columns,
  setColumns
}) => {
  const [state,] = useReportExpenses()
  return(
    <Fragment>
      <div className={"w-100 d-flex justify-content-between"}>
        <div className={"flex-grow-1 font-size-h5 font-weight-bold mt-3"}>
          Daily Expenses
        </div>
        <div className={"d-flex flex-row"}>
          <div style={{marginRight: '1px'}}>
            <FilterColumns
              allColumns={allColumns}
              columns={columns}
              setColumns={setColumns}
            />
          </div>
          <div style={{marginRight: '1px'}}>
            <ReorderColumns columns={columns} setColumns={setColumns} />
          </div>
          <div>
            <ExportToExcel
              data={exportData}
              fileName={`Daily Expenses [${state.daily.date}]`}
            />
          </div>
        </div>
      </div>
      <div className={"d-flex flex-row align-items-center mt-5"}>
        <FilterDate />
        <FilterStaff users={users} />
        <FilterItems items={items} />
      </div>
    </Fragment>
  )
}
export default DailyFilters
