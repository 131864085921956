import React from 'react';
import { headerSortingClasses, sortCaret } from "~helpers";
import {
  ActionsColumnFormatter
} from "~pages/TransferStocks/components/column-formatters";
import globalHook from 'use-global-hook';

const columns = [
  {
    dataField: "id",
    text: "ID",
    sort: true,
    sortCaret: sortCaret,
    headerClasses: "w-65px",
    headerSortingClasses,
  },
  {
    dataField: "t_date",
    text: "Date",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "w-140px"
  },
  {
    dataField: "from_branch",
    text: "From Branch",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "w-170px"
  },
  {
    dataField: "to_branch",
    text: "To Branch",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "created_by",
    text: "Created By",
    sort: false,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "w-170px",
  },
  {
    dataField: "received_by",
    text: "Received By",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "w-170px",
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "text-center w-170px",
    classes: "text-center"
  },
  {
    dataField: "action",
    text: "Actions",
    formatter: ActionsColumnFormatter,
    classes: "text-right pr-0",
    headerClasses: "w-130px text-right pr-3",
  },
];

const initialState = {
  columns: columns,
  defaultSorted: [{ dataField: "created_at", order: "desc" }],
  sizePerPageList: [
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "30", value: 30 },
    { text: "50", value: 50 },
    { text: "100", value: 100 }
  ],
  queryParams: {
    sortOrder: "desc",
    sortField: "id",
    pageNumber: 1,
    pageSize: 15,
  },
  filter: {
    searchField: "frb.name",
    searchText: "",
  },
  search_fields: [
    {value: "frb.name", label: "From Branch"},
    {value: "tob.name", label: "To Branch"},
    {value: "cbu.name", label: "Created By"},
    {value: "rbu.name", label: "Received By"},
    {value: "transfer_stocks.status", label: "Status"},
  ]
};

const state = initialState;

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  resetFilter: (store) => {
    store.setState({ filter: JSON.parse(JSON.stringify(initialState.filter)) });
  }
};

const useTransferStockList = globalHook(React, state, actions);

export default useTransferStockList;
