import React from "react";
import {red} from "@material-ui/core/colors";

const TypeColumnFormatter = (
  cellContent,
  row
) => {
  return (
    <span className={"text-capitalize"}>
      {row.type.replace("_", " ")}
    </span>
  )
}

export default TypeColumnFormatter;
