import React from "react";

const AmountColumnFormatter = (
        cellContent,
        row
) => {
  return (
    <span className={"font-weight-bold"}>
      {row.amount.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
    </span>
  )
}

export default AmountColumnFormatter;
