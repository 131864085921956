import React from 'react';
import { headerSortingClasses, sortCaret } from "~helpers";
import {
  ActionsColumnFormatter,
  DateCreatedColumnFormatter,
  StocksColumnFormatter,
  PriceColumnFormatter,
  PriceHeaderColumnFormatter
} from "~pages/Products/components/column-formatters";
import globalHook from 'use-global-hook';

const columns = [
  {
    dataField: "id",
    text: "ID",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    style: { width: "70px" },
  },  
  {
    dataField: "name",
    text: "Name",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "unit",
    text: "Unit",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "price",
    text: "Price",
    formatter: PriceColumnFormatter,
    headerFormatter: PriceHeaderColumnFormatter,
    sort: false,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "stocks",
    text: "Stocks",
    formatter: StocksColumnFormatter,
    sort: false,
    sortCaret: sortCaret,
    classes: "text-center",
    headerClasses: "text-center",
    headerSortingClasses,
  },
  {
    dataField: "branch_name",
    text: "Branch",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "created_at",
    text: "Date Created",
    formatter: DateCreatedColumnFormatter,
    sort: true,
    sortCaret: sortCaret,
    headerClasses: "w-150px",
    headerSortingClasses,
  },
  {
    dataField: "action",
    text: "Actions",
    formatter: ActionsColumnFormatter,
    classes: "text-right pr-0",
    headerClasses: "text-right pr-3 w-120px",
  },
];

const initialState = {
  columns: columns,
  defaultSorted: [{ dataField: "created_at", order: "desc" }],
  sizePerPageList: [
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "30", value: 30 },
    { text: "50", value: 50 },
    { text: "100", value: 100 }
  ],
  queryParams: {
    sortOrder: "desc",
    sortField: "created_at",
    pageNumber: 1,
    pageSize: 15,
  },
  filter: {
    searchText: "",
    branch_id: "",
  },
  show_variants: false
};

const state = initialState;

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  }
};

const useProducList = globalHook(React, state, actions);

export default useProducList;
