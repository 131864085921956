import React from "react";
import moment from "moment";

const DateCreatedColumnFormatter = (
  cellContent,
  row
) => {
  return (
    <span>
      {moment(row.created_at).format("YYYY-MM-DD HH:mm")}
    </span>
  )
}

export default DateCreatedColumnFormatter;
