import React, {memo, useEffect, useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import {NavLink} from "react-router-dom";
import {BorderColorRounded, VisibilityRounded} from '@material-ui/icons';
import {Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip} from "@material-ui/core";
import {green} from "@material-ui/core/colors";
import { CustomDialog, SplashScreen } from "~components";
import {formatNumber} from "~/app/helpers/format_functions";
import moment from "moment";
import { useSaleItem } from "~store"

const useStyles = makeStyles({
  table: {
    minWidth: "100%",
    marginTop: "25px",

    '& th': {
      fontWeight: 600,
      color: "#7E8299 !important",
      borderBottom: "1px solid #a5a0a0",
    },
    '& td': {
      verticalAlign: "baseline",
      borderBottom: "1px solid #a5a0a0",
    },
    '& .MuiTableCell-root': {
      padding: '8px 4px'
    },
    '& .variant-item': {
      border: "1px solid #000",
      padding: "2px 8px",
      backgroundColor: "#000",
      color: "#fff",
      borderRadius: "3px",
    },
  },
});

const DetailDialog = ({ row }) => {
  return(
    <CustomDialog
      title={"Sale Details"}
      triggerButton={
        <Tooltip arrow title={"View Details"}>
          <Button size={"small"} color={"secondary"} className={"min-w-auto p-1"}>
            <VisibilityRounded style={{color: green[600]}} />
          </Button>
        </Tooltip>
      }
      actionButtons={
        <NavLink to={`/sales/edit/${row.id}`}>
          <Button variant={"contained"} size={"small"} color={"primary"}>
            <BorderColorRounded fontSize={"small"} className={"mr-2"} />
            Update
          </Button>
        </NavLink>
      }
    >
      <Content row={row} />
    </CustomDialog>
  )
}

const Content = ({ row }) => {

  const classes = useStyles()
  const [state, actions] = useSaleItem()
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    init()
  }, [row.id])

  const init = async () => {
    await actions.fetch(row.id)
    setIsReady(true)
  }

  return(
     <div className={"w-100 min-h-200px px-2 d-flex flex-center"}>
       <div className={"w-100 position-relative"}>
         {!isReady && <SplashScreen opacity={0.9} marginTop={-10} />}
         <div className={"w-100 d-flex justify-content-between"}>
           <div className={"flex-grow-1 d-flex align-items-baseline"}>
             <div className={"font-weight-bold font-size-xs text-dark-50 pr-2"}>REFERENCE NO.:</div>
             <div className={"font-weight-bold font-size-lg"}>{row.reference_no}</div>
           </div>
           <div className={"min-w-100px d-flex align-items-baseline"}>
             <div className={"font-weight-bold font-size-xs text-dark-50 pr-2"}>DATE:</div>
             <div className={"font-weight-bold font-size-lg"}>
               {row.s_date ? moment(row.s_date).format("YYYY-MM-DD") : ""}
             </div>
           </div>
         </div>
         <div className={"w-100 d-flex justify-content-between mt-2"}>
           <div className={"flex-grow-1 d-flex align-items-baseline"}>
             <div className={"font-weight-bold font-size-xs text-dark-50 pr-2"}>CUSTOMER:</div>
             <div className={"font-weight-bold font-size-lg"}>{row.customer_name}</div>
           </div>
         </div>
         <div className={"w-100"}>
           <TableContainer className={classes.table}>
             <Table>
               <TableHead>
                 <TableRow>
                   <TableCell>ITEM NAME</TableCell>
                   <TableCell className={'text-right'} style={{width: '20%'}}>UNIT AMOUNT</TableCell>
                   <TableCell className={'text-right'} style={{width: '90px'}}>QUANTITY</TableCell>
                   <TableCell className={'text-right'} style={{width: '130px'}}>DISCOUNT TYPE</TableCell>
                   <TableCell className={'text-right'} style={{width: '90px'}}>DISCOUNT</TableCell>
                   <TableCell className={'text-right'} style={{width: '15%'}}>AMOUNT</TableCell>
                 </TableRow>
               </TableHead>
               <TableBody>
                 {state.items.map((item, index) => {
                   let parent_variant_name = ""
                   let variant = item.product_sku.product_variant
                   let variant_name = variant.name === "no_variant" ? "" : variant.name
                   if(variant && variant.parent_variant) {
                     parent_variant_name = variant.parent_variant.name
                   }

                   return(
                     <TableRow key={index}>
                       <TableCell>
                         <div className={'d-flex flex-row flex-wrap align-items-center'}>
                           {item.product_sku.product_variant.product.name}
                           {parent_variant_name && (
                             <>
                               <div className={"px-2"}>|</div>
                               <div className={`variant-item`}>
                                 {parent_variant_name}
                               </div>
                             </>
                           )}
                           {variant_name && (
                             <>
                               <div className={"px-2"}>|</div>
                               <div className={`variant-item`}>
                                 {variant_name}
                               </div>
                             </>
                           )}
                         </div>
                       </TableCell>
                       <TableCell className={'text-right'}>
                         {formatNumber(item.product_sku.price)}
                       </TableCell>
                       <TableCell className={'text-right'}>
                         {item.quantity}
                       </TableCell>
                       <TableCell className={'text-right'}>
                         {item.discount_type}
                       </TableCell>
                       <TableCell className={'text-right'}>
                         {item.discount ? item.discount : "" }
                       </TableCell>
                       <TableCell className={'text-right font-weight-bolder'}>
                         {formatNumber(item.amount)}
                       </TableCell>
                     </TableRow>
                   )
                 })}
                 <TableRow>
                   <TableCell colSpan={5} className={"text-right text-dark-50 font-weight-bolder border-0"}>
                     TOTAL AMOUNT:
                   </TableCell>
                   <TableCell className={"font-size-h4 font-weight-bolder text-right border-0"}>
                     {formatNumber(state.total)}
                   </TableCell>
                 </TableRow>
               </TableBody>
             </Table>
           </TableContainer>
         </div>
       </div>
     </div>
  )
}

export default memo(DetailDialog)
