import React, { memo } from "react";
import {NavLink} from "react-router-dom";
import {BorderColorRounded, VisibilityRounded} from '@material-ui/icons';
import {Button, Tooltip} from "@material-ui/core";
import {green} from "@material-ui/core/colors";
import { CustomDialog } from "~components";
import {hasPermissions} from "~/app/helpers/user";

const DetailDialog = ({ row }) => {
  return(
    <CustomDialog
      maxWidth={"sm"}
      title={"Vehicle Details"}
      triggerButton={
        <Tooltip arrow title={"View Details"}>
          <Button size={"small"} color={"secondary"} className={"min-w-auto p-1"}>
            <VisibilityRounded style={{color: green[600]}} />
          </Button>
        </Tooltip>
      }
      actionButtons={!hasPermissions(['edit_vehicles']) ? <></> :
        <NavLink to={`/services/vehicles/edit/${row.id}`}>
          <Button variant={"contained"} size={"small"} color={"primary"}>
            <BorderColorRounded fontSize={"small"} className={"mr-2"} />
            Update
          </Button>
        </NavLink>
      }
    >
      <Content row={row} />
    </CustomDialog>
  )
}

const data  = [
  {id: "user_name", label: "Created By"},
  {id: "branch_name", label: "Branch"},
  {id: "partner_name", label: "Partner"},
  {id: "plate_number", label: "Plate No."},
  {id: "make", label: "Make"},
  {id: "model", label: "Model"},
  {id: "year", label: "Year"},
  {id: "color", label: "Color"},
  {id: "type", label: "Type"},
  {id: "notes", label: "Notes"},
]

const Content = ({ row }) => {
  return(
    <div className={"w-100 px-5 d-flex flex-center flex-column"}>
     <div style={{ width: "fit-content" }}>
       {data.map(item => {
         return (
           <div className={"d-flex flex-row align-items-baseline py-1"}>
             <div className={"font-weight-bold text-dark-50 w-100px"}>{item.label}:</div>
             <div className={"font-weight-bold font-size-lg"}>{row[item.id]}</div>
           </div>
         )
       })}
     </div>
    </div>
  )
}

export default memo(DetailDialog)
