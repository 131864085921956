import React, { memo, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {DeleteOutlineRounded, GroupAddRounded} from '@material-ui/icons';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {orange} from "@material-ui/core/colors";
import { CustomDialog, SplashScreen } from "~components";
import {useCustomer} from "~store";

const useStyles = makeStyles({
  table: {
    minWidth: "100%",

    '& th': {
      padding: '2px 4px !important',
      borderBottom: "1px solid #e0e0e0",
      fontSize: 12,
      fontWeight: 600,
    },
    '& td': {
      padding: '7px 4px !important',
      fontSize: 12,
      borderBottom: "1px solid #e0e0e0"
    },
  },
});

const AssignCustomerDialog = ({ row }) => {

  const [, actions] = useCustomer()
  const [customerIds, setCustomerIds] = useState([])
  const [isSaving, setIsSaving] = useState(false)
  const [isReady, setIsReady] = useState(false)
  const [success, setSuccess] = useState(false)

  const handleUpdate = async () => {
    setIsSaving(true)
    await actions.assignToUser(row.id, customerIds)
    setIsSaving(false)
    setSuccess(true)

    setTimeout(function() {
      setSuccess(false)
    }, 6000)
  }

  return(
    <CustomDialog
      maxWidth={"sm"}
      title={"Assigned Customers"}
      triggerButton={
        <Tooltip arrow title={"View Assigned Customers"}>
          <Button size={"small"} color={"secondary"} className={"min-w-auto p-1"}>
            <GroupAddRounded style={{ color: orange[600], fontSize: 21 }} />
          </Button>
        </Tooltip>
      }
      actionButtons={
        <Button size={"small"} color={"primary"} onClick={handleUpdate}>
          Save Changes
        </Button>
      }
    >
      <div className={"w-100 h-100 min-h-120px position-relative"}>
        {(!isReady || isSaving) && <SplashScreen marginTop={0} />}
        <Content
          row={row}
          customerIds={customerIds}
          setCustomerIds={setCustomerIds}
          isReady={isReady}
          setIsReady={setIsReady}
          success={success}
        />
      </div>
    </CustomDialog>
  )
}

const Content = ({
  row,
  customerIds,
  setCustomerIds,
  isReady,
  setIsReady,
  success
}) => {

  const classes = useStyles()
  const [customerState, customerActions] = useCustomer()
  const [customers, setCustomers] = useState([])
  const [newCustomers, setNewCustomers] = useState([])
  const [options, setOptions] = useState([])

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    setIsReady(false)

    customerActions.fetchAll(row.branch_id)

    let res = await customerActions.fetchAssignCustomers(row.id)

    setCustomers(res)
    setIsReady(true)
  }

  useEffect(() => {
    if(success) {
      setNewCustomers([])
      init()
    }
  }, [success])

  useEffect(() => {
    if(isReady) {
      handleSetCustomerIds()
    }
  }, [
    JSON.stringify(customers),
    JSON.stringify(newCustomers)
  ])

  useEffect(() => {
    if(isReady) {
      handleSetOptions()
    }
  }, [customerIds])

  const handleSetOptions = () => {
    let data = JSON.parse(JSON.stringify(customerState.all_customers))
    data = JSON.parse(JSON.stringify(data))
    data = data.filter(item => customerIds.indexOf(item.id) === -1)
    setOptions(data)
  }

  const handleChange = (index, value) => {
    setNewCustomers(newCustomers.map((item, i) => {
      if(i === index) {
        return value ?? {}
      }
      return item
    }))
  }

  const handleAdd = () => {
    let temp = JSON.parse(JSON.stringify(newCustomers))
    temp.push({})
    setNewCustomers(temp)
  }

  const handleDelete = (index) => {
    let temp = JSON.parse(JSON.stringify(customers))
    temp = temp.filter((item, i) => i !== index)
    setCustomers(temp)
  }

  const handleDeleteNew = (index) => {
    let temp = JSON.parse(JSON.stringify(newCustomers))
    temp = temp.filter((item, i) => i !== index)
    setNewCustomers(temp)
  }

  const handleSetCustomerIds = () => {
    let ids = []
    for(let item of newCustomers) {
      if(item.hasOwnProperty('id') && item.id) {
        ids.push(item.id)
      }
    }
    for(let item of customers) {
      if(item.hasOwnProperty('id') && item.id) {
        ids.push(item.id)
      }
    }
    setCustomerIds(ids)
  }

  return (
    <div className={"w-100"}>
      {(customers.length > 0 || newCustomers.length > 0) && (
        <TableContainer className={`${classes.table}`}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  NAME
                </TableCell>
                <TableCell className={"w-10px"} />
              </TableRow>
            </TableHead>
            <TableBody>
              {customers.map((item, i) => (
                <TableRow key={i}>
                  <TableCell className={"font-weight-bold pl-4"}>
                    {item.name}
                  </TableCell>
                  <TableCell className={`text-right`}>
                    <Tooltip arrow title={"Delete"}>
                      <Button size={"small"} color={"secondary"} className={"min-w-1px"}
                        onClick={() => handleDelete(i)}
                      >
                        <DeleteOutlineRounded />
                      </Button>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
              {newCustomers.map((item, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <Autocomplete
                      options={options}
                      getOptionLabel={(option) => option.name}
                      value={item}
                      onChange={(event, newValue) => handleChange(i, newValue)}
                      renderInput={(params) => (
                        <TextField {...params} margin={"dense"} variant="outlined" label={"Choose customer"} />
                      )}
                    />
                  </TableCell>
                  <TableCell className={`text-right`}>
                    <Tooltip arrow title={"Delete"}>
                      <Button size={"small"} color={"secondary"} className={"min-w-1px"}
                        onClick={() => handleDeleteNew(i)}
                      >
                        <DeleteOutlineRounded />
                      </Button>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {(isReady && customers.length === 0 && newCustomers.length === 0) && (
        <div className={"font-weight-bold text-dark-65"}>No assigned customers yet.</div>
      )}
      <div className={"pl-2 text-right mt-5"}>
        <Button variant={"outlined"} color={"primary"} size={"small"} onClick={handleAdd}>
          Add Customer
        </Button>
      </div>
    </div>
  )
}

export default memo(AssignCustomerDialog)
