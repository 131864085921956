import React from "react";
import moment from "moment";

const DateCreatedColumnFormatter = (
  cellContent,
  row
) => {
  return (
    <span>
      {row.date ? moment(row.date).format("YYYY-MM-DD") : ""}
    </span>
  )
}

export default DateCreatedColumnFormatter;
