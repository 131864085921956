import React from 'react';
import globalHook from 'use-global-hook';
import Login from "~pages/Auth/Login"
import Registration from "~pages/Auth/Registration"
import ForgotPassword from "~pages/Auth/ForgotPassword"

const state = {
  routes: [
    {
      path: "/login",
      component: Login,
      permissions: [],
      exact: true,
      private: false
    },
    {
      path: "/registration",
      component: Registration,
      permissions: [],
      exact: true,
      private: false
    },
    {
      path: "/forgot-password",
      component: ForgotPassword,
      permissions: [],
      exact: true,
      private: false
    },
  ]
};

const actions = {
};

const useAuthRoute = globalHook(React, state, actions);

export default useAuthRoute;
