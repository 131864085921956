import React from 'react';
import { headerSortingClasses, sortCaret } from "~helpers";
import { 
  ActionsColumnFormatter,
  AmountColumnFormatter,
  BalanceColumnFormatter,
  DateCreatedColumnFormatter,
  PaymentStatusColumnFormatter
} from "~pages/Sales/components/column-formatters";
import globalHook from 'use-global-hook';

const columns = [
  {
    dataField: "id",
    text: "ID",
    sort: true,
    sortCaret: sortCaret,
    headerClasses: "w-65px",
    headerSortingClasses,
  },
  {
    dataField: "branch_name",
    text: "Branch",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "s_date",
    text: "Date",
    formatter: DateCreatedColumnFormatter,
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "w-140px"
  },
  {
    dataField: "reference_no",
    text: "Reference No.",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "w-170px"
  },
  {
    dataField: "customer_name",
    text: "Customer",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "total",
    text: "Amount",
    formatter: AmountColumnFormatter,
    sort: false,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "text-right w-170px",
    classes: "text-right"
  },
  {
    dataField: "balance",
    text: "Balance",
    formatter: BalanceColumnFormatter,
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "text-right w-170px",
    classes: "text-right"
  },
  {
    dataField: "payment_status",
    text: "Status",
    formatter: PaymentStatusColumnFormatter,
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "text-center w-170px",
    classes: "text-center"
  },
  {
    dataField: "notes",
    text: "Notes",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },

  {
    dataField: "user_name",
    text: "Created By",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "action",
    text: "Actions",
    formatter: ActionsColumnFormatter,
    classes: "text-right pr-0",
    headerClasses: "w-130px text-right pr-3",
  },
];

const initialState = {
  columns: columns,
  defaultSorted: [{ dataField: "created_at", order: "desc" }],
  sizePerPageList: [
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "30", value: 30 },
    { text: "50", value: 50 },
    { text: "100", value: 100 }
  ],
  queryParams: {
    sortOrder: "desc",
    sortField: "id",
    pageNumber: 1,
    pageSize: 15,
  },
  filter: {
    searchField: "reference_no",
    searchText: "",
    branch_id: "all",
  },
  search_fields: [
    {value: "reference_no", label: "Reference No."},
    {value: "c.name", label: "Customer"},
  ]
};

const state = initialState;

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  }
};

const useSalesList = globalHook(React, state, actions);

export default useSalesList;
