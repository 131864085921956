import React, {Fragment} from "react";
import {Autocomplete} from "@material-ui/lab";
import {Checkbox, Chip, TextField} from "@material-ui/core";
import {CheckBox, CheckBoxOutlineBlank} from "@material-ui/icons";
import {useReportExpenses} from "~store"

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const FilterItems = ({ items }) => {
  const [state, actions] = useReportExpenses()
  const handleChange = (field, value) => {
    actions.setState({
      daily: {
        ...state.daily,
        [field]: value
      }
    })
  }
  return (
    <div className={"pr-2"}>
      <Autocomplete
        multiple
        disableCloseOnSelect
        disabled={items.length === 0}
        className={"my-0 py-0 min-w-200px"}
        options={items}
        getOptionLabel={(option) => option.name}
        renderTags={(value, getTagProps) =>
          value.slice(0, 1).map((option, index) => (
            <Chip variant="outlined" label={option.title}
              className={"m-0 h-25px ml-1"}
              {...getTagProps({ index })}
            />
          )).concat(
            value.length > 1
              ? <span className={"pl-2 font-size-xs font-weight-bolder"}>
                  + {value.length - 1} more
                </span>
              : []
          )
        }
        renderOption={(option, { selected }) => (
          <Fragment key={option.name}>
            <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
            />
            {option.name}
          </Fragment>
        )}
        renderInput={(params) =>
          <TextField {...params} margin={"dense"} variant="outlined" label={"Items"} />
        }
        value={state.daily.items}
        onChange={(event, newValue) => handleChange("items", newValue)}
      />
    </div>
  )
}
export default FilterItems
