import React, {useEffect, useState} from "react";
import {
  CustomDatePicker,
  FormErrorMessage,
  FormSuccessMessage,
  SplashScreen,
  CustomSelect,
  ReadOnlyField
} from "~/app/components";
import EditFormItems from "./EditFormItems";
import moment from "moment/moment";
import { useTransferStock, useProduct, useBranch } from "~store";
import {isAdmin, myBranchId} from "~/app/helpers/user";

const my_branch_id = myBranchId()
const EditForm = ({ id, status, setStatus, isReady, setIsReady }) => {

  const [state, actions] = useTransferStock();
  const [, productActions] = useProduct()
  const [branchState, branchActions] = useBranch()

  const [toBranches, setToBranches] = useState([])
  const [statOptions, setStatOptions] = useState([])
  const [isFromReadOnly, setIsFromReadOnly] = useState(false)
  const [isToReadOnly, setIsToReadOnly] = useState(false)
  const [toProductOptions, setToProductOptions] = useState([])

  useEffect(() => {
    init()

    return () => {
      actions.resetData();
      setIsReady(false)
      setToBranches([])
      setStatOptions([])
      setIsFromReadOnly(false)
      setIsToReadOnly(false)
      setToProductOptions([])
      setStatus("preparing")
    }
  }, [])

  useEffect(() => {
    handleBranchUpdate()
  }, [
    state.data.from_branch_id,
    JSON.stringify(branchState.listAll)
  ])

  useEffect(() => {
    handleReadOnlyUpdate()
  }, [
    state.data.from_branch_id,
    state.data.to_branch_id,
    status
  ])

  const init = async () => {
    branchActions.fetchAll()

    if(id) {
      let res = await actions.show(id);
      await setStatus(res.status)
    }

    setTimeout(function() {
      setIsReady(true)
    }, 2000)
  }

  const handleReadOnlyUpdate = () => {
    // set flag whether origin and destination fields are editable
    if(status === "completed") {
      setIsFromReadOnly(true)
      setIsToReadOnly(true)
    } else {
      if(!isAdmin() || state.data.id) {
        setIsFromReadOnly(state.data.to_branch_id === my_branch_id
          || (state.data.from_branch_id === my_branch_id && status === "received")
        )
      } else {
        setIsFromReadOnly(false)
        setIsToReadOnly(false)
      }
    }
  }

  const handleBranchUpdate = async () => {
    // set destination branch options
    if(branchState.listAll.length > 0) {
      let to = branchState.listAll.filter(item => item.id !== parseInt(state.data.from_branch_id))

      setToBranches(to)

      if(state.data.from_branch_id === state.data.to_branch_id) {
        handleChange("to_branch_id", "")
      }

      if(state.data.from_branch_id) {
        productActions.fetchProductOptions(state.data.from_branch_id)
      } else {
        productActions.setState({ product_options: [] })
      }

      if(state.data.to_branch_id) {
        let res = await productActions.fetchProductOptions(state.data.from_branch_id, false)
        setToProductOptions(res)
      }

    }

    // set status options
    let stat_options = []
    if(isAdmin()) {
      stat_options = state.admin_stat_options
    } else {
      if(state.data.id) {
        stat_options = state.data.from_branch_id === my_branch_id ? state.from_stat_options : state.to_stat_options
      } else {
        stat_options = state.from_stat_options
      }
    }
    setStatOptions(stat_options)
  }

  const handleChange = (key, value) => {
    actions.setState({
      data: {
        ...state.data,
        [key]: value
      }
    });
  }

  const handleDateChange = (newDate) => {
    handleChange("t_date", newDate)
  }

  const getBranchName = (id) => {
    let branch =  branchState.listAll.filter(item => item.id === id)
    let branch_name = ""

    if(branch.length > 0) {
      branch_name = branch[0].name
    }

    return branch_name
  }

  return (
    <div className={"w-100 position-relative"}>
      {!isReady && <SplashScreen />}
      {(state.success || (state.errors && Object.keys(state.errors).length)) && (
        <div className="row mb-5">
          <div className="col col-sm-12">
            {state.success && <FormSuccessMessage message={"User successfully saved."} />}
            {state.errors && Object.keys(state.errors).length > 0 && <FormErrorMessage errors={state.errors} />}
          </div>
        </div>
      )}
      <div className="row">
        <div className="col col-md-6">
          {isFromReadOnly
            ? <ReadOnlyField label={"From Branch"} value={getBranchName(state.data.from_branch_id)} />
            : <CustomSelect
              fieldLabel={"From Branch"}
              value={state.data.from_branch_id}
              options={branchState.listAll}
              optionLabelKey="name"
              optionValueKey="id"
              handleChange={(event) => handleChange("from_branch_id", event.target.value)}
              preOptions={<option />}
              required={true}
            />
          }

        </div>
        <div className="col col-md-6">
          {isFromReadOnly
            ? <ReadOnlyField label={"Date"} value={moment(state.data.t_date).format("YYYY-MM-DD")} />
            : <CustomDatePicker
                required={true}
                label={"Date"}
                date={state.data.t_date} handleChange={handleDateChange}
              />
          }
        </div>
        <div className="col col-md-6">
          {isFromReadOnly
            ? <ReadOnlyField label={"To Branch"} value={getBranchName(state.data.to_branch_id)} />
            : <CustomSelect
              fieldLabel={"To Branch"}
              value={state.data.to_branch_id}
              options={toBranches}
              optionLabelKey="name"
              optionValueKey="id"
              handleChange={(event) => handleChange("to_branch_id", event.target.value)}
              preOptions={<option />}
              required={true}
            />
          }
        </div>
        <div className="col col-md-6">
          {(status === "completed"
              || (state.data.from_branch_id === my_branch_id && !['preparing', 'on-the-way'].includes(status))
              || (state.data.to_branch_id === my_branch_id && ['preparing'].includes(status))
            )
            ? <ReadOnlyField
                label={"Status"}
                value={state.data.status}
              />
            : <CustomSelect
                fieldLabel={"Status"}
                value={state.data.status}
                options={statOptions}
                handleChange={(event) => handleChange("status", event.target.value)}
                required={true}
            />
          }
        </div>
      </div>
      {isReady && <EditFormItems
        isFromReadOnly={isFromReadOnly}
        isToReadOnly={isToReadOnly}
        toProductOptions={toProductOptions}
        status={status}
      />}
    </div>
  );
};

export default EditForm;
