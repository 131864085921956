import React from 'react';
import globalHook from 'use-global-hook';
import moment from "moment/moment";

const initialState = {
  loading: false,
  isSaving: false,
  errors: null,
  data: {
    id: "",
    amount: "",
    f_date: moment(new Date()).format("YYYY-MM-DD"),
    description: "",
    branch_id: "",
    user_id: "",
  },
  lastParams: "",
};

const state = JSON.parse(JSON.stringify(initialState));

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  resetData: (store) => {
    store.setState({
      data: JSON.parse(JSON.stringify(initialState.data)),
      errors: null,
      loading: false,
    });
  },
  show: async (store, id) => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/funds/${id}`
      });

      store.actions.setState({ data: data.data });
    }
    catch (error) {
      console.log(error);
    }
  },
  save: async (store) => {
    store.setState({
      errors: null,
      loading: true
    });
    try {
      let url = `/api/funds`
      let payload = store.state.data

      if(payload.id) {
        url += `/${payload.id}`
        payload["_method"] = "PUT"
      }

      let { data } = await global.axios.post(url, payload);

      store.setState({ loading: false });

      return data.data;
    }
    catch ({ response }) {
      if(response.status === 422) {

        store.setState({
          loading: false,
          errors: response.data.errors
        });

        return false;
      }
    }
  },
  delete: async (store, id) => {
    try {
      await global.axios({
        method: "DELETE",
        url: `/api/funds/${id}`
      });

      return true

    }
    catch (error) {
      console.log(error);
      return true
    }
  },
};

const useFund = globalHook(React, state, actions);

export default useFund;
