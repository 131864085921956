/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import {Typography} from "@material-ui/core";
import SVG from "react-inlinesvg";

export default function BreadCrumbs ({ items }) {
  if (!items || !items.length) {
    return "";
  }

  return (
    <div className={"d-flex flex-row"}>
      {items.map((item, i) => {
        return(
          <Typography key={i} variant={"body1"} component={"div"}>
            {i !== items.length -1 && item.pathname
              ? (<Typography component={"div"}>
                <Link className={"text-dark-75 text-hover-primary"} to={item.pathname}>
                  {item.title}
                </Link>
                <span className="svg-icon menu-icon svg-icon-sm px-2">
                  <SVG src={`/media/svg/icons/Navigation/Angle-double-right.svg`} />
                </span>
              </Typography>)
              : <Typography component={"div"} className={"font-weight-bold"}>{item.title}</Typography>
            }
          </Typography>
        )
      })}
    </div>
  );
}
