import React, {useState} from "react";
import {CustomDialog} from "~/app/components";
import {Button, Tooltip, FormControl, FormGroup, FormControlLabel, Checkbox} from "@material-ui/core";
import {FilterListRounded} from "@material-ui/icons";

const FilterColumns = ({ allColumns, columns, setColumns }) => {
  const [state, setState] = useState(JSON.parse(JSON.stringify(columns)))
  const [triggerClose, setTriggerClose] = useState(false)
  const handleApply = () => {
    setColumns(state)
    setTriggerClose(true)
  }
  return(
    <CustomDialog
      title={"Filter Columns"}
      maxWidth={"xs"}
      triggerCloseButton={triggerClose}
      triggerButton={
        <Tooltip title={"Filter Columns"}>
          <Button variant={"outlined"} color={"primary"} size={"small"} className={"min-w-20px"}>
            <FilterListRounded />
          </Button>
        </Tooltip>
      }
      actionButtons={
        <Button variant={"contained"} color={"primary"} size={"small"} onClick={handleApply}>
          Apply Changes
        </Button>
      }
    >
      <Content
        allColumns={allColumns}
        state={state}
        setState={setState}
        setTriggerClose={setTriggerClose}
      />
    </CustomDialog>
  )
}

const Content = ({ allColumns, state, setState, setTriggerClose }) => {

  const [all, setAll] = useState(allColumns.length === state.length)

  const toggleAll = () => {
    setState(all ? [] : JSON.parse(JSON.stringify(allColumns)))
    setAll(!all)
  }

  const handleChange = (event, col) => {
    let newState = JSON.parse(JSON.stringify(state))

    if(!event.target.checked) {
      newState = newState.filter(item => item.id !== col.id)
    } else {
      newState.push(col)
    }

    newState.sort((a, b) => a.order > b.order ? 1 : -1);
    setState(newState)
    setTriggerClose(false)
  }

  return(
    <FormControl component="fieldset" className={"w-100"}>
      <FormControlLabel className={"border-bottom pb-1 mb-3"}
        control={<Checkbox checked={all} onChange={toggleAll} />}
        label={all ? "Unselect All" : "Select All"}
      />
      <FormGroup>
        {allColumns.map((item, i) => (
          <FormControlLabel key={i} label={item.title} className={"mb-0"}
            control={<Checkbox
              checked={state.filter(col => col.id === item.id).length > 0}
              onChange={(event) => handleChange(event, item)}
            />}
          />
        ))}
      </FormGroup>
    </FormControl>
  )
}
export default FilterColumns
