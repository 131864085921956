
const quarry_data = {
  ticket_number: "",
  vehicle_id: "",
  driver_id: "",
  trip_from: "",
  trip_to: "",
  weight: "",
  charge: "",
  expenses: []
}

const hauling_data = {
  ticket_number: "",
  vehicle_id: "",
  driver_id: "",
  trip_from: "",
  trip_to: "",
  weight: "",
  charge: "",
}

const hauling_waste_data = {
  ticket_number: "",
  vehicle_id: "",
  driver_id: "",
  site: "",
  approved_by: "",
  weight: "",
  charge: "",
}

const rent_data = {
  vehicle_id: "",
  charge: "",
  date_from: "",
  date_to: "",
}

export const service_types = [
  {id: "", name: "", standard_rate: ""},
  {id: "quarry", name: "Quarry", standard_rate: 3300.00, data: quarry_data},
  {id: "hauling", name: "Hauling", data: hauling_data},
  {id: "hauling_residual_waste", name: "Hauling Residual Waste", data: hauling_waste_data},
  {id: "hauling_waste_water", name: "Hauling Waste Water", data: hauling_waste_data},
  {id: "rent", name: "Rent", data: rent_data},
];
