import React from "react";
import moment from "moment";

const DateColumnFormatter = (
  cellContent,
  row
) => {
  return (
    <span>
      {row.s_date ? moment(row.s_date).format("YYYY-MM-DD") : ""}
    </span>
  )
}

export default DateColumnFormatter;
