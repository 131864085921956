import React from 'react';
import { headerSortingClasses, sortCaret } from "~helpers";
import {
  ActionsColumnFormatter,
  StatusColumnFormatter,
  TypeColumnFormatter
} from "~pages/AwardMemos/components/column-formatters";
import globalHook from 'use-global-hook';

const columns = [
  {
    dataField: "id",
    text: "ID",
    sort: true,
    sortCaret: sortCaret,
    headerClasses: "w-65px",
    headerSortingClasses,
  },
  {
    dataField: "branch_name",
    text: "Branch",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "w-140px",
  },
  {
    dataField: "company_name",
    text: "Client",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "w-140px",
  },
  {
    dataField: "m_date",
    text: "Date",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "document_number",
    text: "P.O. Number",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "type",
    text: "Type",
    formatter: TypeColumnFormatter,
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "number_of_trips",
    text: "No. of Trips",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "text-center",
    classes: "text-center"
  },
  {
    dataField: "remaining_trips",
    text: "Remaining Trips",
    sort: false,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "text-center",
    classes: "text-center",
  },
  {
    dataField: "date_from",
    text: "Date From",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "date_to",
    text: "Date To",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "status",
    text: "Status",
    formatter: StatusColumnFormatter,
    sort: false,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "text-center",
    classes: "text-center",
  },
  {
    dataField: "user_name",
    text: "Created By",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "w-140px",
  },
  {
    dataField: "action",
    text: "Actions",
    formatter: ActionsColumnFormatter,
    classes: "text-right pr-0",
    headerClasses: "w-130px text-right pr-3",
  },
];

const initialState = {
  columns: columns,
  defaultSorted: [{ dataField: "id", order: "desc" }],
  sizePerPageList: [
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "30", value: 30 },
    { text: "50", value: 50 },
    { text: "100", value: 100 }
  ],
  queryParams: {
    sortOrder: "desc",
    sortField: "id",
    pageNumber: 1,
    pageSize: 15,
  },
  filter: {
    searchField: "document_number",
    searchText: "",
    branch_id: "all"
  },
  searchFields: [
    {value: "document_number", label: "P.O. Number"},
    {value: "c.name", label: "Client"},
  ]
};

const state = initialState;

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  resetFilter: (store) => {
    store.setState({ filter: JSON.parse(JSON.stringify(initialState.filter)) });
  }
};

const useAwardMemoList = globalHook(React, state, actions);

export default useAwardMemoList;
