import React from 'react';
import PropTypes from 'prop-types';
import {TextField} from "@material-ui/core";

const CustomTextField = ({
  label= "",
  type = "text",
  margin = "dense",
  required = false,
  className = "mt-2 mb-5",
  ...props
 }) => {

  if(required) {
    label = <>{label}<span className={`text-danger`}>*</span></>
  }

  return(
    <TextField
      fullWidth
      margin={margin}
      variant="outlined"
      type={type}
      // required={required}
      className={`${className}`}
      label={label}
      autoComplete={"off"}
      {...props}
    />
  )
}

CustomTextField.propTypes = {
  error: PropTypes.bool,
  required: PropTypes.bool,
  type: PropTypes.string
};

export default CustomTextField;
