import React from "react";
import {Collapse, Grow, Table, TableBody, TableCell, TableContainer, TableRow} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {formatNumber} from "~/app/helpers/format_functions";
import {useProductList} from "~store";

const useStyles = makeStyles({
  table: {
    minWidth: "100%",
    marginBottom: 0,

    '& td': {
      fontSize: 12,
      padding: "4px !important",
      border: 0
    },
  },
});

const PriceColumnFormatter = (
  cellContent,
  row
) => {
  return <RowContent row={row} />
}

const RowContent = ({ row }) => {
  const [state, ] = useProductList()

  let prices = ""
  let data = [];

  if(row.hasOwnProperty('variant_data')) {
    prices = row.variant_data.prices !== undefined ? row.variant_data.prices : ""
    data = row.variant_data.data !== undefined ? row.variant_data.data : []
  }

  return (
    <>
      <Collapse in={!state.show_variants} timeout={800}>
        {prices}
      </Collapse>
      <Collapse in={state.show_variants} timeout={800}>
        {(data.length > 0 && !data[0].hasOwnProperty('name')) ? prices : <DetailedView data={data} />}
      </Collapse>
    </>
  )
}

const DetailedView = ({ data }) => {
  const classes = useStyles()
  return (
    <TableContainer className={`${classes.table}`}>
      <Table className={"table-striped"}>
        <TableBody>
          <TableRow>
            <TableCell className={"font-weight-bolder text-dark-50"}>
              VARIANT
            </TableCell>
            <TableCell className={"w-75px text-right font-weight-bolder text-dark-50"}>
              STOCKS
            </TableCell>
            <TableCell className={"w-75px text-right font-weight-bolder text-dark-50"}>
              PRICE
            </TableCell>
          </TableRow>
          {data.map((item, i) => {
            return (
              <TableRow key={i}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell className={"text-right"}>
                    {formatNumber(item.stocks, 0)}
                  </TableCell>
                  <TableCell className={"text-right"}>
                    {formatNumber(item.price)}
                  </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default PriceColumnFormatter;
