import React, {Fragment} from "react";
import {Autocomplete} from "@material-ui/lab";
import {TextField} from "@material-ui/core";
import {CustomDatePicker} from "~components";
import {useReportProducts} from "~store"
const FilterDate = () => {
  const [state, actions] = useReportProducts()
  const handleChange = (field, value) => {
    actions.setState({ [field]: value })
  }

  return(
    <div className={"d-flex flex-row"}>
      <div className={"w-160px pr-2"}>
        <CustomDatePicker
          label={"Date Start (yyyy-mm-dd)"}
          date={state.tracking_date_start}
          handleChange={(newDate) => handleChange("tracking_date_start", newDate)}
        />
      </div>
      <div className={"w-160px pr-2"}>
        <CustomDatePicker
          label={"Date End (yyyy-mm-dd)"}
          date={state.tracking_date_end}
          handleChange={(newDate) => handleChange("tracking_date_end", newDate)}
        />
      </div>
    </div>
  )
}
export default FilterDate
