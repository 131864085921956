import React from 'react';
import globalHook from 'use-global-hook';

const initialState = {
  loading: false,
  isSaving: false,
  errors: null,
  totalUsers: 0,
  users: [],
  allUsers: [],
  user: {
    id: "",
    role: "",
    name: "",
    username: "",
    email: "",
    password: "",
    password_confirmation: "",
    avatar: "",
    branch_id: "",
  },
  lastParams: "",
  managers: [],
};

const state = JSON.parse(JSON.stringify(initialState));

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  resetUser: (store) => {
    store.setState({
      user: JSON.parse(JSON.stringify(initialState.user)),
      errors: null,
      loading: false,
    });
  },
  fetchAllUsers: async (store, branch_id = "") => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/fetch-users?branch_id=${branch_id}`
      });
      store.setState({ allUsers: data.data });
    }
    catch (error) {
      console.log(error);
    }
  },
  fetchUsers: async (store, queryParams = null, filters = null) => {
    store.setState({ loading: true });

    try {
      let params = "";

      if(!queryParams) {
        params = store.state.lastParams;
      } else {
        params = `?page=${queryParams.pageNumber}
          &pageSize=${queryParams.pageSize}
          &sortField=${queryParams.sortField}
          &sortOrder=${queryParams.sortOrder}
          &searchText=${filters.searchText}
          &branch_id=${filters.branch_id !== "all" ? filters.branch_id : ""}`;
      }

      let { data } = await global.axios({
        method: "GET",
        url: `/api/users${params}`
      });

      store.setState({
        users: data.data,
        totalUsers: data.meta.total,
        lastParams: params,
        loading: false
      });
    }
    catch (error) {
      console.log(error);
      store.setState({ loading: false });
    }
  },
  fetchUser: async (store, user_id) => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/users/${user_id}`
      });
      store.setState({
        user: {
          ...store.state.user,
          ...data.data,
          role: data.data.roles.length > 0 ? data.data.roles[0].name : ""
        }
      });
    }
    catch (error) {
      console.log(error);
    }
  },
  saveUser: async (store, history = null) => {
    store.setState({
      errors: null,
      isSaving: true
    });

    try {
      let url = `/api/users`;
      let payload = store.state.user;
      delete payload.roles;

      if(payload.id) {
        url += `/${payload.id}`;
        payload['_method'] = 'PUT';
      }

      const formData = new FormData()
      for(let key in payload) {
        formData.append(key, payload[key])
      }

      let config = {headers: { "Content-Type": "multipart/form-data" }}
      let { data } = await global.axios.post(url, formData, config);
      store.setState({
        isSaving: false,
        user: {
          ...store.state.user,
          password: "",
          password_confirmation: ""
        }
      });

      if(!store.state.id && history) {
        history.push(`/management/users/edit/${data.data.id}`);
      }

      return data.data;
    }
    catch ({ response }) {
      if(response.hasOwnProperty('status') && response.status === 422) {
        store.setState({
          errors: response.data.errors,
          isSaving: false,
        });
        return false;
      }
    }
  },
  deleteUser: async (store, user_id) => {
    try {
      await global.axios({
        method: "DELETE",
        url: `/api/users/${user_id}`
      });

      store.actions.fetchUsers();

    }
    catch (error) {
      console.log(error);
    }
  },
  fetchManagers: async (store) => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/fetch-managers`
      });
      store.setState({ managers: data });
    }
    catch (error) {
      console.log(error);
    }
  },
};

const useUser = globalHook(React, state, actions);

export default useUser;
