import React, {useEffect, useState} from "react"
import {makeStyles} from "@material-ui/core/styles";
import {CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {DateYearPicker, ExportToExcel} from "~components";
import {formatNumber} from "~/app/helpers/format_functions";
import {useReportExpenses} from "~store"

const useStyles = makeStyles({
  table: {
    minWidth: "100%",

    '& th': {
      fontWeight: 600,
      border: "1px solid #b3b3b3",
      paddingLeft: "2px"
    },
    '& td': {
      verticalAlign: "baseline",
      border: "1px solid #b3b3b3",
    },
    '& .MuiTableCell-root': {
      padding: '4px 6px'
    },
  },
});

const YearlyReport = () => {

  const classes = useStyles()
  const [state, actions] = useReportExpenses()
  const [exportData, setExportData] = useState([])

  useEffect(() => {
    fetchData()
  },[state.yearly.year])

  const fetchData = async () => {
    let res = await actions.fetchExpenses("yearly")

    if(res) {
      let temp = []
      for(let item of res.data) {
        temp.push({
          "MONTH": item.month,
          "AMOUNT": formatNumber(item.amount),
        })
      }
      setExportData(temp)
    }
  }

  const handleChange = (field, value) => {
    actions.setState({
      yearly: {
        ...state.yearly,
        [field]: value
      }
    })
  }

  return(
    <div className={"d-flex flex-center"}>
      <div className={"min-w-400px"}>
        <div className={"d-flex justify-content-between"}>
          <div className={"flex-grow-1 font-size-h5 font-weight-bolder"}>
            Yearly Expenses
          </div>
          <ExportToExcel
            data={exportData}
            fileName={`Yearly Expenses [${state.yearly.year}]`}
          />
        </div>
        <div className={"d-flex flex-row align-items-center mt-5"}>
          <div className={"w-130px pr-1"}>
            <DateYearPicker
              value={state.yearly.year}
              handleChange={(newValue) => handleChange("year", newValue)}
            />
          </div>
        </div>
        <TableContainer className={classes.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2} className={"border-0"}>
                  {state.isLoading && (
                    <div className={"d-flex font-weight-bolder text-dark-50 font-size-sm"}>
                      <CircularProgress thickness={8} size={14} className={"mr-2"} />
                      Fetching data ...
                    </div>
                  )}
                </TableCell>
                <TableCell className={"text-right border-0 p-0 pb-5"}>
                  <div className={"font-size-xs font-weight-bold text-dark-65 mb-1"}>
                    TOTAL AMOUNT
                  </div>
                  <div className={"font-size-h3 font-weight-boldest"}>
                    {formatNumber(state.yearly.amount)}
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={"w-35px"} />
                <TableCell>MONTH</TableCell>
                <TableCell className={"text-right"}>AMOUNT</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.yearly.data.map((item, index) => {
                return(
                  <TableRow key={index}>
                    <TableCell className={"text-center"}>{index + 1}</TableCell>
                    <TableCell>{item.month}</TableCell>
                    <TableCell className={"text-right font-weight-bolder"}>
                      {formatNumber(item.amount)}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {!state.isLoading && state.yearly.data.length === 0 && (
          <div className={"text-center mt-10 font-weight-bold font-italic text-dark-50 font-size-sm"}>
            -- No data to display --
          </div>
        )}
      </div>
    </div>
  )
}

export default YearlyReport
