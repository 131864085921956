  import React from 'react';
import globalHook from 'use-global-hook';

const initialState = {
  token: '',
  user: {},
  roles: [],
  permissions: [],
};

const state = initialState;

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  isLoggedIn: () => {
    return localStorage.hasOwnProperty('token')
            && localStorage.token !== ""
            && localStorage.hasOwnProperty('user')
            && localStorage.user !== "";
  },
  isAdmin: () => {
    let user_roles = JSON.parse(localStorage.getItem('roles'))
    return ["super-admin", "admin"].some(role => user_roles.indexOf(role) !== -1);
  },
  getMyBranchId: () => {
    let user = JSON.parse(localStorage.getItem('user'))
    return user.branch_id
  },
  hasPermission: (store, permissions) => {
    let user_permissions = JSON.parse(localStorage.getItem('permissions'))
    return permissions.some(permission => user_permissions.indexOf(permission) !== -1);
  },
  login: async (store, postData) => {
    await global.axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}/sanctum/csrf-cookie`
    });
    try {
      let { data } = await global.axios({
        method: "POST",
        url: `/api/login`,
        data: postData
      });

      let token = data.data.token.split("|")[1];
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(data.data.user));
      localStorage.setItem('roles', JSON.stringify(data.data.roles));
      localStorage.setItem('permissions', JSON.stringify(data.data.permissions));

      store.setState({
        token: token,
        user: data.data.user,
        roles: data.data.roles,
        permissions: data.data.permissions
      });

      return true;
    } catch (err) {
      return false;
    }
  },
  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('roles');
    localStorage.removeItem('permissions');
    window.location.href = "/login"
  },
  setUserDetails: async (store) => {
    await store.setState({
      token: localStorage.getItem("token"),
      user: JSON.parse(localStorage.getItem("user")),
      permissions: JSON.parse(localStorage.getItem("permissions"))
    });
  },
  updateUserDetails: (store, newUser) => {
    let user = {
      ...store.state.user,
      name: newUser.name,
      email: newUser.email,
      avatar: newUser.avatar
    }
    store.setState({ user: user})
    localStorage.setItem("user", JSON.stringify(user));
    window.location.reload()
  },
  getUserRole: () => {
    let roles = JSON.parse(localStorage.roles)
    return roles.join(", ");
  },
};

const useAuth = globalHook(React, state, actions);

export default useAuth;
