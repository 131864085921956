import React from 'react';
import { headerSortingClasses, sortCaret } from "~helpers";
import {
  ActionsColumnFormatter,
  AmountColumnFormatter
} from "~pages/Payables/components/column-formatters";
import globalHook from 'use-global-hook';

const columns = [
  {
    dataField: "id",
    text: "ID",
    sort: true,
    sortCaret: sortCaret,
    headerClasses: "w-65px",
    headerSortingClasses,
  },
  {
    dataField: "branch_name",
    text: "Branch",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "company_name",
    text: "Company",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "company_area_name",
    text: "Area",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "title",
    text: "Coverage",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "date_from",
    text: "Date From",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    classes: "text-center",
    headerClasses: "text-center"
  },
  {
    dataField: "date_to",
    text: "Date To",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    classes: "text-center",
    headerClasses: "text-center"
  },
  {
    dataField: "amount",
    text: "Amount",
    formatter: AmountColumnFormatter,
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "text-right",
    classes: "text-right",
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "text-center",
    classes: "text-center",
  },
  {
    dataField: "paid_at",
    text: "Payment Date",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    classes: "text-center",
    headerClasses: "text-center"
  },
  {
    dataField: "action",
    text: "Actions",
    formatter: ActionsColumnFormatter,
    classes: "text-right pr-0",
    headerClasses: "text-right pr-2",
  },
];

const initialState = {
  columns: columns,
  defaultSorted: [{ dataField: "id", order: "desc" }],
  sizePerPageList: [
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "30", value: 30 },
    { text: "50", value: 50 },
    { text: "100", value: 100 }
  ],
  queryParams: {
    sortOrder: "desc",
    sortField: "id",
    pageNumber: 1,
    pageSize: 15,
  },
  filter: {
    searchField: "",
    searchText: "",
    branch_id: "all",
  },
  search_fields: [
    {value: "c.name", label: "Company"},
    {value: "ca.name", label: "area"},
  ]
};

const state = initialState;

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  resetFilter: (store) => {
    store.setState({ filter: JSON.parse(JSON.stringify(initialState.filter)) });
  }
};

const usePayableList = globalHook(React, state, actions);

export default usePayableList;
