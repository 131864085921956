import React from 'react';
import globalHook from 'use-global-hook';
import moment from "moment/moment";

const current_date = new Date();
let start_date = new Date(current_date.getFullYear(), current_date.getMonth(), 1)
let end_date = new Date()
start_date = moment(start_date).format("YYYY-MM-DD")
end_date = moment(end_date).format("YYYY-MM-DD")

const state = {
  loading: false,
  isReady: true,
  date_start: start_date,
  date_end: end_date,
  data: [],
  cash_on_hand: 0,
  expense_total: 0,
  payment_total: 0,
  branch_id: "all",
  user_id: "all",
  sort_direction: "DESC",

  pdf_data: {}
}

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  fetchData: async (store, key = "data", params = "") => {
    store.setState({ loading: true })
    try {
      let s = store.state
      params = params ? params
        : `?date_start=${s.date_start}
          &date_end=${s.date_end}
          &branch_id=${s.branch_id !== "all" ? s.branch_id : ""}
          &user_id=${s.user_id !== "all" ? s.user_id : ""}
          &sort_direction=${s.sort_direction}`

      let url = `/api/cash-on-hand${params}`

      let { data } = await global.axios({
        method: "GET",
        url: url
      });

      if(key === "data") {
        store.actions.setState({
          data: data.data,
          cash_on_hand: data.cash_on_hand,
          payment_total: data.payment_total,
          expense_total: data.expense_total,
          loading: false
        })
      } else {
        store.actions.setState({
          [key]: data,
          loading: false
        })
      }

      return data
    }
    catch (error) {
      console.log(error)
      store.setState({ loading: false })
      return false
    }
  },
};

const useReportCashOnHand = globalHook(React, state, actions);

export default useReportCashOnHand;
