import React, {memo} from "react";
import { makeStyles } from '@material-ui/core/styles';
import {VisibilityRounded} from '@material-ui/icons';
import {Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip} from "@material-ui/core";
import {green} from "@material-ui/core/colors";
import { CustomDialog } from "~components";
import {formatNumber} from "~/app/helpers/format_functions";

const useStyles = makeStyles({
  table: {
    minWidth: "100%",
    marginTop: "25px",

    '& th': {
      fontWeight: 600,
      color: "#7E8299 !important",
      borderBottom: "1px solid #a5a0a0",
    },
    '& td': {
      verticalAlign: "baseline",
      borderBottom: "1px solid #a5a0a0",
    },
    '& .MuiTableCell-root': {
      padding: '8px 4px'
    },
    '& .variant-item': {
      border: "1px solid #000",
      padding: "2px 8px",
      backgroundColor: "#000",
      color: "#fff",
      borderRadius: "3px",
    },
  },
});

const DetailDialog = ({ row }) => {
  return(
    <CustomDialog
      maxWidth={"sm"}
      title={"Transfer Stocks Details"}
      triggerButton={
        <Tooltip arrow title={"View Details"}>
          <Button size={"small"} color={"secondary"} className={"min-w-auto p-1"}>
            <VisibilityRounded style={{color: green[600]}} />
          </Button>
        </Tooltip>
      }
    >
      <Content row={row} />
    </CustomDialog>
  )
}

const Content = ({ row }) => {
  const classes = useStyles()
  return(
     <div className={"w-100 min-h-200px px-2 d-flex justify-content-center"}>
       <div className={"w-100 position-relative"}>
         <div className={"w-100 d-flex justify-content-between"}>
           <div className={"flex-grow-1"}>
             <FieldRow label={"STATUS"} value={row.status} />
             <FieldRow label={"FROM BRANCH"} value={row.from_branch} />
             <FieldRow label={"TO BRANCH"} value={row.to_branch} />
           </div>
           <div>
             <FieldRow label={"DATE"} value={row.t_date} />
             <FieldRow label={"CREATED BY"} value={row.created_by} />
             <FieldRow label={"RECEIVED BY"} value={row.received_by} />
           </div>
         </div>
         <div className={"w-100"}>
           <TableContainer className={classes.table}>
             <Table>
               <TableHead>
                 <TableRow>
                   <TableCell>ITEM NAME</TableCell>
                   <TableCell className={'text-right'} style={{width: '90px'}}>QUANTITY</TableCell>
                 </TableRow>
               </TableHead>
               <TableBody>
                 {row.items.map((item, index) => {
                   return(
                     <TableRow key={index}>
                       <TableCell>
                         {item.product_data.name}
                       </TableCell>
                       <TableCell className={'text-right'}>
                         {formatNumber(item.quantity, 0)}
                       </TableCell>
                     </TableRow>
                   )
                 })}
               </TableBody>
             </Table>
           </TableContainer>
         </div>
       </div>
     </div>
  )
}

const FieldRow = ({ label, value }) => {
  return(
    <div className={"w-100 d-flex align-items-baseline"}>
      <div className={"font-weight-bold font-size-xs text-dark-50 pr-2"}>{label}:</div>
      <div className={"font-weight-bold font-size-lg text-capitalize"}>{value}</div>
    </div>
  )
}

export default memo(DetailDialog)
