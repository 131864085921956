import React from "react";
const BranchColumnFormatter = (
  cellContent,
  row
) => {
  return (
    <span>
      {row.branch ? row.branch.name : ""}
    </span>
  )
}

export default BranchColumnFormatter;
