import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import {arrayMoveImmutable} from 'array-move';
import {CustomDialog} from "~/app/components";
import {Button, Tooltip} from "@material-ui/core";
import { ImportExportRounded, DragIndicatorRounded } from '@material-ui/icons';

const useStyles = makeStyles({
  root: {
    '& .sort-item': {
      cursor: "move",
      zIndex: 999999
    }
  },
});

const SortableItem = SortableElement(({value}) => (
  <div className={"sort-item px-2 py-2 mb-1 border rounded-sm"} style={{zIndex: 999999}} >
    <DragIndicatorRounded fontSizeAdjust={3} className={"mr-1 text-dark-50"} />
    {value}
  </div>
));

const SortableList = SortableContainer(({items}) => {
  return (
    <div className={"sort-container"}>
      {items.map((value, index) => (
        <SortableItem key={`item-${value.id}`} index={index} value={value.title} />
      ))}
    </div>
  );
});

const ReorderColumns = ({columns, setColumns}) => {
  const classes = useStyles()
  const [state, setState] = useState(columns)
  const [triggerClose, setTriggerClose] = useState(false)
  const onSortEnd = ({oldIndex, newIndex}) => {
    setState(arrayMoveImmutable(state, oldIndex, newIndex))
  }

  const handleApply = () => {
    setColumns(state)
    setTriggerClose(true)
  }

  return (
    <CustomDialog
      title={"Re-order Columns"}
      maxWidth={"xs"}
      triggerCloseButton={triggerClose}
      triggerButton={
        <Tooltip title={"Re-order Columns"}>
          <Button
            variant={"outlined"}
            color={"primary"}
            size={"small"}
            className={"min-w-20px"}
          >
            <ImportExportRounded />
          </Button>
        </Tooltip>
      }
      actionButtons={
        <Button
          variant={"contained"}
          color={"primary"}
          size={"small"}
          onClick={handleApply}
        >
          Apply Changes
        </Button>
      }
    >
      <div className={classes.root}>
        <SortableList items={state} onSortEnd={onSortEnd}  />
      </div>
    </CustomDialog>
  )
}

export default ReorderColumns
