import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from "@material-ui/core";
import {CustomDialog} from "~components";
import {formatNumber} from "~app/helpers/format_functions";
import {useReportProducts} from "~/store";

const useStyles = makeStyles({
  root: {
  },
  table: {
    minWidth: "100%",

    '& th': {
      fontWeight: 600,
      border: "1px solid #b3b3b3",
      paddingLeft: "2px"
    },
    '& td': {
      verticalAlign: "baseline",
      border: "1px solid #b3b3b3",
    },
    '& .MuiTableCell-root': {
      padding: '4px 6px'
    },
  },
});

const CountOutDialog = ({ item }) => {
  const [state, ] = useReportProducts()
  return(
    <CustomDialog
      title={`${state.tracking_product.name} | ${item.date}`}
      maxWidth={"sm"}
      triggerButton={
        <Tooltip title={"View Details"}>
          <span className={"text-primary"}>
            {formatNumber(item.count_out, 0)}
          </span>
        </Tooltip>
      }
    >
      <Content item={item} />
    </CustomDialog>
  )
}

const Content = ({ item }) => {

  const classes = useStyles()
  const [state, actions] = useReportProducts()

  useEffect(() => {
    actions.fetchDetails(item.date, "count_out")
  }, [item])

  if(state.tracking_count_outs.length === 0) {
    return(
      <div className={"w-100 d-flex flex-column flex-center pt-3"}>
        <CircularProgress size={30} thickness={7} />
        <div className={"mt-6 mb-2 font-weight-bold"}>Fetching data ...</div>
      </div>
    )
  }

  return(
    <TableContainer className={classes.table}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>CUSTOMER</TableCell>
            <TableCell>PRODUCT</TableCell>
            <TableCell>REFERENCE NO.</TableCell>
            <TableCell style={{width: "20%"}} className={"text-right"}>QUANTITY</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {state.tracking_count_outs.map((d_item, index) => {
            return(
              <TableRow key={index}>
                <TableCell>{d_item.customer_name}</TableCell>
                <TableCell>{d_item.product}</TableCell>
                <TableCell>{d_item.reference_no}</TableCell>
                <TableCell className={"text-right"}>
                  {formatNumber(d_item.quantity, 0)}
                </TableCell>
              </TableRow>
            )
          })}
          <TableRow>
            <TableCell className={"border-0"} colSpan={3} />
            <TableCell className={"text-right border-0 py-2 font-weight-bolder border-bottom border-bottom-md border-bottom-dark"}>
              {formatNumber(item.count_out, 0)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CountOutDialog
