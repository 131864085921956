import React from 'react';
import globalHook from 'use-global-hook';

const initialState = {
  menuItems: [
    {
      path: "/dashboard",
      text: "Dashboard",
      iconPath: "/Layout/Layout-arrange.svg",
      permissions: [],
    },
    {
      path: "/cash-on-hand",
      text: "Cash on hand",
      iconPath: "/Shopping/Calculator.svg",
      permissions: ['list_cash_on_hand'],
    },
    {
      path: "/expenses",
      text: "Expenses",
      iconPath: "/Shopping/Chart-bar2.svg",
      permissions: ['list_expenses'],
    },
    {
      path: "/sales",
      text: "Sales",
      iconPath: "/Shopping/Chart-line1.svg",
      permissions: ['list_sales'],
    },
    {
      path: "/payments",
      text: "Payments",
      iconPath: "/Shopping/Rouble.svg",
      permissions: ['list_payments'],
    },
    {
      path: "/transfer-stocks",
      text: "Transfer Stocks",
      iconPath: "/Navigation/Exchange.svg",
      permissions: ['list_transfer_stocks'],
    },
    {
      path: "/products",
      text: "Products",
      iconPath: "/Shopping/Box2.svg",
      permissions: ['list_products'],
    },
    {
      path: "/purchases",
      text: "Purchases",
      iconPath: "/Shopping/Cart1.svg",
      permissions: ['list_purchases'],
    },
    {
      path: "/customers",
      text: "Customers",
      iconPath: "/Communication/Address-card.svg",
      permissions: ['list_customers'],
    },
    {
      path: "/suppliers",
      text: "Suppliers / Clients",
      iconPath: "/Home/Building.svg",
      permissions: ['list_suppliers'],
    },
    {
      path: "/documents",
      text: "Documents",
      iconPath: "/Files/Group-folders.svg",
      permissions: ['list_documents'],
    },
    {
      path: "/pullouts",
      text: "Pullouts",
      iconPath: "/Files/Group-folders.svg",
      permissions: ['list_pullouts'],
    },
    {
      path: "/payables",
      text: "Payables",
      iconPath: "/Files/Group-folders.svg",
      permissions: ['list_payables'],
    },
    {
      path: "/services",
      text: "Services",
      iconPath: "/Code/Settings4.svg",
      permissions: ['list_services', 'list_partners', 'list_vehicles', 'list_drivers', 'list_award_memos', 'list_billings'],
      subItems: [
        {
          path: "/services/billings",
          text: "Billing",
          permissions: ['list_billings'],
        },
        {
          path: "/services/list",
          text: "Transactions",
          permissions: ['list_services'],
        },
        {
          path: "/services/award-memos",
          text: "Award Memos",
          permissions: ['list_award_memos'],
        },
        {
          path: "/services/partners",
          text: "Partners",
          permissions: ['list_partners'],
        },
        {
          path: "/services/vehicles",
          text: "Vehicles",
          permissions: ['list_vehicles'],
        },
        {
          path: "/services/drivers",
          text: "Drivers",
          permissions: ['list_drivers'],
        },
      ]
    },
    {
      path: "/management",
      text: "Management",
      iconPath: "/Code/Settings4.svg",
      permissions: ['list_users', 'list_roles', 'list_branches'],
      subItems: [
        {
          path: "/management/branches",
          text: "Branches",
          permissions: ['list_branches'],
        },
        {
          path: "/management/users",
          text: "Users",
          permissions: ['list_users'],
        },
        {
          path: "/management/roles",
          text: "Roles",
          permissions: ['list_roles'],
        }
      ]
    },
    // {
    //   path: "/reports",
    //   text: "Reports",
    //   iconPath: "/Shopping/Chart-pie.svg",
    //   permissions: ['view_expense_report', 'view_purchase_report', 'view_sale_report', 'view_product_report'],
    //   subItems: [
    //     {
    //       path: "/reports/expense-report",
    //       text: "Expenses",
    //       permissions: ['view_expense_report'],
    //     },
    //     {
    //       path: "/reports/purchase-report",
    //       text: "Purchases",
    //       permissions: ['view_purchase_report'],
    //     },
    //     {
    //       path: "/reports/sale-report",
    //       text: "Sales",
    //       permissions: ['view_sale_report'],
    //     },
    //     {
    //       path: "/reports/product-report",
    //       text: "Products",
    //       permissions: ['view_product_report'],
    //     },
    //   ]
    // },
  ]
};

const actions = {}

const useMenu = globalHook(React, initialState, actions);

export default useMenu;
