import React from 'react';
import { headerSortingClasses, sortCaret } from "~helpers";
import {
  ActionsColumnFormatter,
  ServiceTypeColumnFormatter,
  // GrossAmountColumnFormatter,
  NetAmountColumnFormatter,
  StatusColumnFormatter,
} from "~pages/Billings/components/column-formatters";
import globalHook from 'use-global-hook';

const columns = [
  {
    dataField: "id",
    text: "ID",
    sort: true,
    sortCaret: sortCaret,
    headerClasses: "w-65px",
    headerSortingClasses,
  },
  {
    dataField: "branch_name",
    text: "Branch",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "user_name",
    text: "Created By",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "company_name",
    text: "Company",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "service_type",
    text: "Service Type",
    formatter: ServiceTypeColumnFormatter,
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "b_date",
    text: "Billing Date",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "due_date",
    text: "Due Date",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  {
    dataField: "paid_at",
    text: "Date Paid",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
  },
  // {
  //   dataField: "gross_amount",
  //   text: "Gross Amount",
  //   formatter: GrossAmountColumnFormatter,
  //   sort: true,
  //   sortCaret: sortCaret,
  //   headerSortingClasses,
  //   headerClasses: "text-right",
  //   classes: "text-right"
  // },
  {
    dataField: "net_amount",
    text: "Amount",
    formatter: NetAmountColumnFormatter,
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "text-right",
    classes: "text-right"
  },
  {
    dataField: "status",
    text: "Status",
    formatter: StatusColumnFormatter,
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "text-center",
    classes: "text-center",
  },
  {
    dataField: "action",
    text: "Actions",
    formatter: ActionsColumnFormatter,
    classes: "text-right pr-0",
    headerClasses: "text-right pr-2",
  },
];

const initialState = {
  columns: columns,
  defaultSorted: [{ dataField: "id", order: "desc" }],
  sizePerPageList: [
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "30", value: 30 },
    { text: "50", value: 50 },
    { text: "100", value: 100 }
  ],
  queryParams: {
    sortOrder: "desc",
    sortField: "id",
    pageNumber: 1,
    pageSize: 15,
  },
  filter: {
    searchField: "v.plate_number",
    searchText: "",
    branch_id: "all",
  },
  search_fields: [
    {value: "c.name", label: "Client"},
    {value: "u.name", label: "Created By"},
    {value: "d.name", label: "Driver"},
    {value: "p.name", label: "Partner"},
    {value: "v.plate_number", label: "Plate No."},
    {value: "service_type", label: "Service Type"},
  ]
};

const state = initialState;

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  resetFilter: (store) => {
    store.setState({ filter: JSON.parse(JSON.stringify(initialState.filter)) });
  }
};

const useBillingList = globalHook(React, state, actions);

export default useBillingList;
