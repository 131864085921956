import React, {Fragment} from "react";
import {Autocomplete} from "@material-ui/lab";
import {TextField} from "@material-ui/core";
import {CustomDatePicker} from "~components";
import {useReportExpenses} from "~store"

const FilterDate = () => {
  const [state, actions] = useReportExpenses()
  const handleChange = (field, value) => {
    actions.setState({
      daily: {
        ...state.daily,
        [field]: value
      }
    })
  }
  return(
    <div className={"d-flex flex-row align-items-center"}>
      <div className={"w-130px pr-2"}>
        <Autocomplete
          disableClearable
          options={state.dateFilterByOptions}
          getOptionLabel={(option) => option.label}
          value={state.daily.date_filter_by}
          onChange={(event, newValue) => handleChange("date_filter_by", newValue)}
          renderInput={(params) => (
            <TextField {...params} margin={"dense"} variant="outlined" label="Filter By" />
          )}
        />
      </div>
      {state.daily.date_filter_by.value === "date" && (
        <div className={"w-160px pr-2"}>
          <CustomDatePicker
            label={"Date (yyyy-mm-dd)"}
            date={state.daily.date}
            handleChange={(newDate) => handleChange("date", newDate)}
          />
        </div>
      )}
      {state.daily.date_filter_by.value === "date_range" && (
        <Fragment>
          <div className={"w-160px pr-2"}>
            <CustomDatePicker
              label={"Date Start (yyyy-mm-dd)"}
              date={state.daily.date_start}
              handleChange={(newDate) => handleChange("date_start", newDate)}
            />
          </div>
          <div className={"w-160px pr-2"}>
            <CustomDatePicker
              label={"Date End (yyyy-mm-dd)"}
              date={state.daily.date_end}
              handleChange={(newDate) => handleChange("date_end", newDate)}
            />
          </div>
        </Fragment>
      )}
    </div>
  )
}
export default FilterDate
