import React, {useEffect} from "react"
import {makeStyles} from "@material-ui/core/styles";
import {CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import FilterDate from "./FilterDate";
import FilterProduct from "./FilterProduct";
import CountInDialog from "./CountInDialog";
import CountOutDialog from "./CountOutDialog";
import {formatNumber} from "~/app/helpers/format_functions";
import {useReportProducts} from "~store"

const useStyles = makeStyles({
  root: {
  },
  table: {
    minWidth: "100%",
    marginTop: "15px",

    '& th': {
      fontWeight: 600,
      border: "1px solid #b3b3b3",
      paddingLeft: "2px"
    },
    '& td': {
      verticalAlign: "baseline",
      border: "1px solid #b3b3b3",
    },
    '& .MuiTableCell-root': {
      padding: '4px 6px'
    },
  },
});

const TrackingReport = () => {

  const classes = useStyles()
  const [state, actions] = useReportProducts()

  useEffect(() => {
    actions.fetchTrackingFilters()
  }, [])

  useEffect(() => {
    actions.fetchProductTracking()
  },[
    state.tracking_product,
    state.tracking_date_start,
    state.tracking_date_end
  ])

  return(
    <div className={`${classes.root} d-flex flex-center`}>
      <div className={"w-550px"}>
        <div className={"d-flex flex-row"}>
          <FilterDate />
          <FilterProduct />
        </div>
        <div className={"mt-8"}>
          <TableContainer className={classes.table}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={"w-35px"} />
                  <TableCell>DATE</TableCell>
                  <TableCell style={{width: "20%"}} className={"text-right"}>IN</TableCell>
                  <TableCell style={{width: "20%"}} className={"text-right"}>OUT</TableCell>
                  <TableCell style={{width: "20%"}} className={"text-right"}>STOCKS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.tracking_product && state.tracking_data.length > 0 && (
                  <>
                    <TableRow>
                      <TableCell />
                      <TableCell colSpan={3}> Old Stocks</TableCell>
                      <TableCell className={"text-right"}>
                        {formatNumber(state.tracking_old_stocks, 0)}
                      </TableCell>
                    </TableRow>
                    {state.tracking_data.map((item, index) => {
                      return(
                        <TableRow key={index}>
                          <TableCell className={"text-center"}>{index + 1}</TableCell>
                          <TableCell>{item.date}</TableCell>
                          <TableCell className={"text-right"}>
                            {item.hasOwnProperty('count_in') ? <CountInDialog item={item} />: ""}
                          </TableCell>
                          <TableCell className={"text-right"}>
                            {item.hasOwnProperty('count_out') ? <CountOutDialog item={item} /> : ""}
                          </TableCell>
                          <TableCell className={"text-right"}>
                            {formatNumber(item.stocks, 0)}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {state.isLoading && (
          <div className={"w-100 d-flex flex-row flex-center mt-6 font-weight-bold"}>
            <CircularProgress size={16} thickness={7} />
            <span className={"ml-3"}>Fetching data ...</span>
          </div>
        )}
      </div>
    </div>
)
}

export default TrackingReport
