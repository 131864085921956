import React from 'react';
import globalHook from 'use-global-hook';
import moment from "moment";

const memo_types = [
  {value: "trips", label: "Trips"},
  {value: "date_range", label: "Date Range"},
]

const initialState = {
  loading: false,
  isSaving: false,
  errors: null,
  success: false,
  listCount: 0,
  lastParams: "",
  listData: [],
  listAll: [],
  data: {
    id: "",
    m_date: moment(new Date()).format("YYYY-MM-DD"),
    document_number: "",
    type: "trips",
    number_of_trips: "",
    date_from: "",
    date_to: "",
    company_id: "",
    company_name: "",
    branch_id: "",
    branch_name: "",
    status: "",
    remaining_trips: "",
    completed_trips: 0,
  },
  memo_types: memo_types
};

const state = JSON.parse(JSON.stringify(initialState));

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  resetData: (store) => {
    store.setState({
      loading: false,
      errors: null,
      isSaving: false,
      data: JSON.parse(JSON.stringify(initialState.data))
    });
  },
  fetchAll: async (store, branch_id = "", company_id = "") => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/fetch-award-memos?branch_id=${branch_id}&company_id=${company_id}`
      });

      store.actions.setState({ listAll: data.data });
    }
    catch (error) {
      console.log(error);
    }
  },
  fetchList: async (store, queryParams = {}, filters = {}) => {
    store.setState({ loading: true });

    try {
      let params = "";

      if(Object.keys(queryParams).length === 0) {
        params = store.state.lastParams;
      } else {
        params = `?page=${queryParams.pageNumber}
          &pageSize=${queryParams.pageSize}
          &sortField=${queryParams.sortField}
          &sortOrder=${queryParams.sortOrder}
          &searchField=${filters.searchField}
          &searchText=${filters.searchText}
          &branch_id=${filters.branch_id}`;
      }
      
      let { data } = await global.axios({
        method: "GET",
        url: `/api/award-memos${params}`
      });

      store.setState({
        loading: false,
        listData: data.data,
        listCount: data.meta.total,
        lastParams: params
      });
    }
    catch (error) {
      store.setState({ loading: false });
      console.log(error);
    }    
  },
  show: async (store, id) => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/award-memos/${id}`
      });

      store.actions.setState({ data: data.data });

      return data.data
    }
    catch (error) {
      console.log(error);
    }
  },
  save: async (store, history) => {
    store.setState({
      loading: true,
      isSaving: true,
      errors: null,
      success: false
    });

    try {
      let payload = JSON.parse(JSON.stringify(store.state.data));

      let url = `/api/award-memos`;

      if(payload.id) {
        url += `/${payload.id}`;
        payload['_method'] = 'PUT';
      }

      let { data } = await global.axios.post(url, payload);

      store.setState({
        data: data.data,
        loading: false,
        isSaving: false,
        success: true
      })

      if(!payload.id) {
        history.push(`/services/award-memos/edit/${data.data.id}`);
      }

      return data.data;
    }
    catch ({ response }) {
      if(response.status === 422) {
        store.setState({
          loading: false,
          isSaving: false,
          errors: response.data.errors
        });
        return false;
      }
    }
  },
  delete: async (store, id) => {
    try {
      let { data: { data } } = await global.axios({
        method: "DELETE",
        url: `/api/award-memos/${id}`
      });

      store.actions.fetchList();

      return data
    }
    catch ({ response }) {
      return response.data
    }
  },
};

const useAwardMemo = globalHook(React, state, actions);

export default useAwardMemo;
