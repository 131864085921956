import React from "react";
import {formatNumber} from "~/app/helpers/format_functions";

const BalanceColumnFormatter = (
  cellContent,
  row
) => {
  return (
    <span className={`${row.balance < 0 ? "text-success" : "text-danger"}`}>
      {row.balance !== 0 && (
          <>{formatNumber(row.balance)}</>
      )}
    </span>
  )
}

export default BalanceColumnFormatter;
