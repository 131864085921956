import React from "react";
import {green} from "@material-ui/core/colors";

const StatusColumnFormatter = (
  cellContent,
  row
) => {
  return (
    <>
      {row.status && (
        <span style={{ color: green[600], border: `1px solid ${green[600]}` }} className={"px-2 py-1 rounded-sm"}>
          {row.status}
        </span>
      )}
    </>
  )
}

export default StatusColumnFormatter;
