import React from 'react';
import globalHook from 'use-global-hook';

const initialState = {
  loading: false,
  isSaving: false,
  errors: null,
  listCount: 0,
  lastParams: "",
  listData: [],
  listAll: [],
  data: {
    id: "",
    name: ""
  },
};

const state = JSON.parse(JSON.stringify(initialState));

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  resetData: (store) => {
    store.setState({
      loading: false,
      isSaving: false,
      errors: null,
      data: JSON.parse(JSON.stringify(initialState.data))
    });
  },
  fetchAll: async (store) => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/fetch-branches`
      });
      store.actions.setState({ listAll: data.data });
    }
    catch (error) {
      console.log(error);
    }
  },
  fetchList: async (store, queryParams = null, filters = null) => {
    store.setState({ loading: true });

    try {
      let params = "";

      if(!queryParams) {
        params = store.state.lastParams;
      } else {
        params = `?page=${queryParams.pageNumber}
          &pageSize=${queryParams.pageSize}
          &sortField=${queryParams.sortField}
          &sortOrder=${queryParams.sortOrder}
          &searchText=${filters.searchText}`;
      }

      let { data } = await global.axios({
        method: "GET",
        url: `/api/branches${params}`
      });

      store.setState({
        loading: false,
        listData: data.data,
        listCount: data.meta.total,
        lastParams: params
      });
    }
    catch (error) {
      store.setState({ loading: false });
      console.log(error);
    }
  },
  show: async (store, id) => {
    store.setState({
      loading: true,
      errors: null
    });
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/branches/${id}`
      });
      store.actions.setState({
        data: data.data,
        loading: false
      });
    }
    catch (error) {
      console.log(error);
      store.actions.setState({ loading: false });
    }
  },
  save: async (store) => {
    store.setState({
      isSaving: true,
      errors: null
    });

    try {
      let payload = store.state.data;
      let url = `/api/branches`;

      if(payload.id) {
        url += `/${payload.id}`;
        payload['_method'] = 'PUT';
      }

      let { data } = await global.axios.post(url, payload);
      store.actions.setState({ isSaving: false, data: data.data });

      store.actions.fetchList()

      return data.data;
    }
    catch ({ response }) {
      if(response.status === 422) {
        store.setState({
          isSaving: false,
          errors: response.data.errors
        });
        return false;
      }
    }
  },
  delete: async (store, id) => {
    try {
      await global.axios({
        method: "DELETE",
        url: `/api/branches/${id}`
      });

      store.actions.fetchList();

    }
    catch (error) {
      console.log(error);
    }
  },
};

const useBranch = globalHook(React, state, actions);

export default useBranch;
