import React, {useState} from "react";
import { NavLink } from "react-router-dom";
import {Button, Tooltip} from "@material-ui/core";
import {BorderColorRounded, DeleteOutlineRounded} from "@material-ui/icons";
import { CustomDialog } from "~components";
import DetailDialog from "../DetailDialog"
import { usePurchase, useAuth } from "~store";

const ActionsColumnFormatter = (cellContent, row) => {
  return <Actions row={row} />
}

const Actions = ({ row }) => {
  const [, authActions] = useAuth()
  return(
    <div>
      {authActions.hasPermission(['view_pullouts']) && <DetailDialog row={row} />}
      {authActions.hasPermission(['edit_pullouts']) && (
        <Tooltip arrow title={"Edit"}>
          <NavLink to={`/pullouts/edit/${row.id}`}>
            <Button size={"small"} color={"primary"} className={"min-w-auto p-1"}>
              <BorderColorRounded fontSize={"small"} />
            </Button>
          </NavLink>
        </Tooltip>
      )}
      {authActions.hasPermission(['delete_pullouts']) && <DeleteButton row={row} />}
    </div>
  )
}

const DeleteButton = ({ row }) => {
  const [, actions] = usePurchase();
  const [triggerClose, setTriggerClose] = useState(false);
  const [message, setMessage] = useState("");

  const handleDelete = async (id) => {
    setMessage("Deleting ...");
    await actions.delete(id);
    setMessage("Successfully deleted.");

    setTimeout(
      function() {
        setTriggerClose(true);
      }, 3000
    );
    setTimeout(
    function() {
      setTriggerClose(false);
      }, 3500
    );
  }

  return(
    <CustomDialog
      title={""}
      dividers={false}
      maxWidth={"sm"}
      triggerCloseButton={triggerClose}
      triggerButton={
        <Tooltip arrow title={"Delete"}>
          <Button size={"small"} color={"secondary"} className={"min-w-auto p-1"}>
            <DeleteOutlineRounded />
          </Button>
        </Tooltip>
      }
      actionButtons={!message ?
        <Button  className={"btn btn-danger btn-elevate"}
          onClick={() => handleDelete(row.id)}>
          Yes
        </Button> : ""
      }
    >
      <div className={"font-size-h3 w-100 text-center py-10"}>
        {!message && <>
            Are you sure you want to delete this purchase (<span className={"font-weight-bold"}> { row.reference_no } </span>)?
          </>
        }
        {message && message}
      </div>
    </CustomDialog>
  )
}

export default ActionsColumnFormatter;
