/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {Fragment} from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { checkIsActive } from "~helpers";
import {useMenu, useAuth} from "~store";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const [state,] = useMenu()
  const [, authActions] = useAuth()
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  }
  return (
    <ul className={`menu-nav flex-grow-1 p-0 ${layoutProps.ulClasses}`}>
      {state.menuItems.map((item, i) => {
        if(item.permissions.length > 0) {
          if(!authActions.hasPermission(item.permissions))
            return <Fragment key={i} />
        }
        if(item.hasOwnProperty('subItems')) {
          return(
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(item.path,true)}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
              key={i}
            >
              <NavLink className="menu-link menu-toggle d-flex flex-row align-items-center" to={item.path}>
                <span className="svg-icon menu-icon">`
                  <SVG src={`/media/svg/icons${item.iconPath}`} />
                </span>
                <span className="menu-text">{item.text}</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <ul className="menu-subnav">
                  <ul className="menu-subnav">
                    <li
                      className="menu-item  menu-item-parent"
                      aria-haspopup="true"
                    >
                    <span className="menu-link">
                      <span className="menu-text">{item.text}</span>
                    </span>
                    </li>
                    {item.subItems.map((subItem) => {
                      if(subItem.permissions.length > 0) {
                        if(!authActions.hasPermission(subItem.permissions))
                          return <Fragment key={i} />
                      }
                      return(
                        <li
                          className={`menu-item ${getMenuItemActive(subItem.path, false)}`}
                          aria-haspopup="true"
                          key={subItem.path}
                        >
                          <NavLink className="menu-link" to={subItem.path}>
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">{subItem.text}</span>
                          </NavLink>
                        </li>
                      )
                    })}
                  </ul>
                </ul>
              </div>
            </li>
          )
        }
        return(
          <li
            key={i}
            className={`menu-item ${getMenuItemActive(item.path, false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to={item.path}>
              <span className="svg-icon menu-icon">
                <SVG src={`/media/svg/icons${item.iconPath}`} />
              </span>
              <span className="menu-text">{item.text}</span>
            </NavLink>
          </li>
        )
      })}
    </ul>
  );
}
