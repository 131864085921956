import React, {useEffect} from "react"
import {makeStyles} from "@material-ui/core/styles";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import {formatNumber} from "~/app/helpers/format_functions";
import {CustomDatePicker} from "~components";
import BySupplierFilters from "./by-supplier-filters";
import {useReportExpenses} from "~store"

const useStyles = makeStyles({
  root: {
    marginTop: 15,
  },
  table: {
    minWidth: "100%",
    marginTop: 20,

    '& th': {
      fontWeight: 600,
      border: "1px solid #b3b3b3",
      paddingLeft: "2px"
    },
    '& td': {
      verticalAlign: "middle",
      border: "1px solid #b3b3b3",
    },
    '& .MuiTableCell-root': {
      padding: '4px 6px'
    },
  },
});


const BySupplierReport = () => {

  const classes = useStyles()
  const [state, actions] = useReportExpenses()

  useEffect(() => {
    actions.fetchBySupplier();
  }, [
    state.by_supplier_date_start,
    state.by_supplier_date_end
  ])

  const handleChange = (field, value) => {
    actions.setState({ [field]: value })
  }

  return(
    <div className={`${classes.root}`}>
      <div className={"w-100 d-flex justify-content-between"}>
        <div className={"flex-grow-1 d-flex"}>
          <div className={"w-160px pr-2"}>
            <CustomDatePicker
              label={"Date Start (yyyy-mm-dd)"}
              date={state.by_supplier_date_start}
              handleChange={(newDate) => handleChange("by_supplier_date_start", newDate)}
            />
          </div>
          <div className={"w-160px pr-2"}>
            <CustomDatePicker
              label={"Date End (yyyy-mm-dd)"}
              date={state.by_supplier_date_end}
              handleChange={(newDate) => handleChange("by_supplier_date_end", newDate)}
            />
          </div>
        </div>
        <div>
          <BySupplierFilters />
        </div>
      </div>
      <TableContainer className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={state.by_supplier_columns.length - 1} className={"border-0"}>
                {state.isLoading && (
                  <div className={"d-flex font-weight-bolder text-dark-50 font-size-sm"}>
                    <CircularProgress thickness={8} size={14} className={"mr-2"} />
                    Fetching data ...
                  </div>
                )}
              </TableCell>
              <TableCell className={"text-right border-0 p-0 pb-5"} colSpan={2}>
                <div className={"font-size-xs font-weight-bolder text-dark-65 mb-1"}>
                  TOTAL AMOUNT
                </div>
                <div className={"font-size-h3 font-weight-boldest"}>
                  {formatNumber(state.by_supplier_vat_ex_total + state.by_supplier_vat_in_total)}
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={"w-35px"} />
              {state.by_supplier_columns.map(col => (
                <TableCell key={col.id} className={col.class}>
                  {col.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {state.by_supplier_data.map((item, index) => {
              return(
                <TableRow key={index}>
                  <TableCell className={"text-center"}>{index + 1}</TableCell>
                  {state.by_supplier_columns.map(col => {
                    let value = (['vat_in', 'vat_ex'].includes(col.id) && item[col.id] === 0) ? "" : item[col.id];
                    return (
                      <TableCell key={col.id} className={col.class}>
                        {col.hasOwnProperty('decimal') && value ? formatNumber(value, col.decimal) : value}
                      </TableCell>
                    )})}
                </TableRow>
              )
            })}
            <TableRow>
              <TableCell colSpan={state.by_supplier_columns.length - 1} className={"border-0"} />
              <TableCell className={`text-right border-0 pt-5 font-weight-bolder
                ${state.by_supplier_vat_in_total ? "border-bottom-xl border-bottom-dark" : ""}`}>
                {state.by_supplier_vat_in_total ? formatNumber(state.by_supplier_vat_in_total) : ""}
              </TableCell>
              <TableCell className={`text-right border-0 pt-5 font-weight-bolder
                ${state.by_supplier_vat_ex_total ? "border-bottom-xl border-bottom-dark" : ""}`}>
                {state.by_supplier_vat_ex_total ? formatNumber(state.by_supplier_vat_ex_total) : ""}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {!state.isLoading && state.by_supplier_data.length === 0 && (
        <div className={"text-center mt-10 font-weight-bold font-italic text-dark-50 font-size-sm"}>
          -- No data to display --
        </div>
      )}
    </div>
  )
}


export default BySupplierReport
